import { LoadingButton } from "@mui/lab";
import { FormControl, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import { useHistory } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import EntityAffiliationInput from "@/common/components/Entity/EntityAffiliation/EntityAffiliationInput";
import TenantRolesSelector from "@/common/components/Entity/Role/TenantRolesSelector";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import FormActions from "@/common/components/Form/FormActions";
import DurationInput from "@/common/components/Form/Input/DurationInput";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useUserAffiliation } from "@/common/hooks/useUserAffiliation";
import { clipboardService } from "@/common/services/clipboard";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { BaseFormikValues } from "@/common/ts/error";
import { TimeSpan } from "@/common/ts/timespan";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { InviteAnonymousUserDto, UnitOfTime } from "@/core/api/generated";
import * as tenantInvitesSlice from "@/store/management/tenantInvites/slice";

export interface CreateNewAnonymousInvitePageQueryParams extends GeneralQueryParams {
  sourceInviteId?: string | null;
}

export default function CreateNewAnonymousInvite() {
  const queryParams = useQueryParams<CreateNewAnonymousInvitePageQueryParams>();

  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const history = useHistory();
  const thunkDispatch = useAppThunkDispatch();

  const sourceInviteRequest = useApiRequest(
    apiClient.tenantInvitesApi.apiV1TenantInvitesInviteIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      inviteId: queryParams?.sourceInviteId || "",
    },
    {
      skip: !queryParams?.sourceInviteId,
    },
  );
  const sourceInvite = sourceInviteRequest?.data;

  const { departments, locations } = useUserAffiliation();

  if (sourceInviteRequest.isLoading && !sourceInviteRequest.isFirstEnded) {
    return <LinearProgress />;
  }

  return (
    <Formik<InviteAnonymousUserDto & BaseFormikValues>
      enableReinitialize
      initialValues={{
        lifetime: new TimeSpan({ day: 7 }).toString(),
        roleIds: sourceInvite?.roleIds || undefined,
        departmentId:
          sourceInvite?.departmentId ||
          (departments && departments[0] && departments[0].id) ||
          undefined,
        locationId:
          sourceInvite?.locationId || (locations && locations[0] && locations[0].id) || undefined,

        submit: "",
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          const result = await thunkDispatch(
            tenantInvitesSlice.createAnonymousInvite({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              inviteAnonymousUserDto: {
                lifetime: values.lifetime,
                roleIds: values.roleIds,
                departmentId: values.departmentId,
              },
            }),
          );
          enqueueSnackbar("Anonymous invite created successfully.", {
            variant: "success",
          });
          try {
            await clipboardService.writeText(result.inviteUrl as string);
            enqueueSnackbar("Link copied to clipboard.", {
              variant: "success",
            });
          } catch (error) {
            console.error("Clipboard copy error", error);
          }

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
          history.goBack();
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <CreateUpdatePageLayout header={<SimpleViewPageHeader title={"New anonymous invite"} />}>
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <DurationInput
                  error={Boolean(errors.lifetime)}
                  helperText={errors.lifetime}
                  fullWidth
                  margin='dense'
                  label='Invite lifetime'
                  variant='outlined'
                  defaultUnitOfTime={UnitOfTime.Day}
                  value={values.lifetime}
                  onChange={(newValue) => {
                    setFieldValue("lifetime", newValue);
                  }}
                  onBlur={handleBlur}
                />

                <FormControl margin='none' fullWidth>
                  <TenantRolesSelector
                    roleIds={values.roleIds}
                    onChange={(newValue) =>
                      setFieldValue("roleIds", newValue?.map((x) => x.id) || [])
                    }
                    textFieldProps={{
                      error: Boolean(touched.roleIds && errors.roleIds),
                      helperText: touched.roleIds && errors.roleIds,
                    }}
                  />
                </FormControl>
                {/* Entity affiliation */}
                <EntityAffiliationInput
                  department={{
                    label: "Department (optional)",
                    fullWidth: true,
                    departmentId: values.departmentId,
                    onChange: (d) => {
                      setFieldValue("departmentId", d?.id);
                      setFieldValue("locationId", undefined);
                    },
                    error: errors.departmentId,
                  }}
                  location={{
                    label: "Location (optional)",
                    fullWidth: true,
                    locationId: values.locationId,
                    onChange: (l) => {
                      setFieldValue("locationId", l?.id);
                    },
                    searchFilters: { departmentId: values.departmentId },
                    createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                    error: errors.locationId,
                    disabled: !values.departmentId,
                  }}
                />
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <LoadingButton
                  color='primary'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Create
                </LoadingButton>
              </FormActions>
            </form>
          </CreateUpdatePageLayout>
        );
      }}
    </Formik>
  );
}
