import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import ViewContentBlockHeader from "@/App/Layouts/ViewContentBlockHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import { ROUTE_PATH } from "@/common/constants/routing";
import { usePropagatedDeps } from "@/common/hooks/render/usePropagatedDeps";
import { AppPermission, AssessmentFlowDto } from "@/core/api/generated";

import AccessoryCheckView from "../../../AccessoryCheck/View/AccessoryCheckView";
import ContractBlock from "../../../Contract/ContractBlock";
import ContractCustomerCommunicationInfo from "../../../Contract/ContractCustomerCommunicationInfo";
import ContractReminderSettingsDisplay, {
  ContractReminderSettingsDisplayProps,
} from "../../../Contract/ContractReminderSettingsDisplay";
import CreateContractCustomerCommunicationEntryModal from "../../../Contract/CreateContractCustomerCommunicationEntryModal";
import CustomerBlock from "../../../Customer/CustomerBlock";
import DamageCostEvaluationAggregateView from "../../../DamageCostEvaluationAggregate/View/DamageCostEvaluationAggregateView";
import DamageDetectionAggregateView from "../../../DamageDetectionAggregate/View/DamageDetectionAggregateView";
import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import NegotiationProposalComputedStatusIconsStrip from "../../../Negotiation/NegotiationProposal/NegotiationProposalComputedStatusIconsStrip";
import VehicleBlock from "../../../Vehicle/VehicleBlock";
import { AssessmentFlowRelatedTenantRequestsPropagatedDepsMap } from "../../AssessmentFlowRelatedTenantRequests";
import AssessmentFlowRelatedTenantRequestsForDamageCostEvaluation from "../../AssessmentFlowRelatedTenantRequestsForDamageCostEvaluation";
import SendDamagesToAssessmentModal from "../../SendDamagesToAssessmentModal";

interface Props {
  assessmentFlow: AssessmentFlowDto;
  setIsCommunicationInfoModalOpen: (open: boolean) => void;
  onContractReminderSettingsUpdated?: ContractReminderSettingsDisplayProps["onUpdate"];
}

export default function OverviewTabContent({
  assessmentFlow,
  setIsCommunicationInfoModalOpen,
  onContractReminderSettingsUpdated,
}: Props) {
  const tenantRequestsForDamageCostEvaluationPropagatedDeps =
    usePropagatedDeps<AssessmentFlowRelatedTenantRequestsPropagatedDepsMap>();
  const [isCreateCommunicationEntryModalOpen, setIsCreateCommunicationEntryModalOpen] =
    useState(false);
  const [isSendToAssessmentModalOpen, setIsSendToAssessmentModalOpen] = useState(false);

  return (
    <PageTabContent>
      <Stack direction='column' spacing={2}>
        {assessmentFlow && (
          <>
            {/* Contract and vehicle data */}
            <ViewContentBlock>
              <Stack direction={{ xxs: "column", md: "row" }} spacing={{ xxs: 2, md: 2 }}>
                <VehicleBlock
                  sx={{
                    flex: 1,
                  }}
                  variant='full'
                  vehicle={assessmentFlow?.vehicle}
                  withDetailsToggle={true}
                  withLink
                />
                <ContractBlock
                  sx={{
                    flex: 1,
                  }}
                  variant='full'
                  contract={assessmentFlow?.contract}
                  briefDataProps={{
                    vehicle: false,
                    customer: false,
                  }}
                  detailedDataProps={{
                    vehicle: false,
                    customer: false,
                  }}
                  withDetailsToggle={true}
                  withLink
                />
                <CustomerBlock
                  sx={{
                    flex: 1,
                  }}
                  variant='full'
                  customer={assessmentFlow?.customer}
                  briefDataProps={{}}
                  detailedDataProps={{}}
                  withDetailsToggle={true}
                  withLink
                />
              </Stack>

              {/* Statuses */}
              <Stack direction={{ xxs: "column", md: "row" }} spacing={{ xxs: 1, md: 2 }}>
                <Stack direction='column' spacing={1}>
                  <FieldValue label='Damage status'>
                    <InlineApiEnumValue
                      type='DamageDetectionStatusInContract'
                      value={assessmentFlow?.state?.damageDetectionStatus}
                      withDescription
                    />
                  </FieldValue>
                  <FieldValue
                    label='Damage negotiations status'
                    helperTooltip='Statuses of negotiations on the damage'
                  >
                    {assessmentFlow && assessmentFlow.negotiationRefs && (
                      <Stack direction='row' sx={{ alignItems: "center" }}>
                        &nbsp;
                        <NegotiationProposalComputedStatusIconsStrip
                          negotiationRefs={assessmentFlow.negotiationRefs.filter(
                            (x) => assessmentFlow!.damageNegotiationIdsMap![x.id!],
                          )}
                          to={ROUTE_PATH.ASSESSMENT_FLOW_CHAT_VIEW(assessmentFlow.id)}
                        />
                      </Stack>
                    )}
                  </FieldValue>
                </Stack>

                <Stack direction='column' spacing={1}>
                  <FieldValue label='Damage cost status'>
                    <InlineApiEnumValue
                      type='DamageCostEvaluationStatusInContract'
                      value={assessmentFlow?.state?.damageCostEvaluationStatus}
                      withDescription
                    />
                  </FieldValue>
                  <FieldValue
                    label='Damage cost negotiations status'
                    helperTooltip='Statuses of negotiations on the damage cost'
                  >
                    {assessmentFlow && assessmentFlow?.negotiationRefs && (
                      <Stack direction='row' sx={{ alignItems: "center" }}>
                        &nbsp;
                        <NegotiationProposalComputedStatusIconsStrip
                          negotiationRefs={assessmentFlow.negotiationRefs.filter(
                            (x) => assessmentFlow!.damageCostNegotiationIdsMap![x.id!],
                          )}
                          to={ROUTE_PATH.ASSESSMENT_FLOW_CHAT_VIEW(assessmentFlow.id)}
                        />
                      </Stack>
                    )}
                  </FieldValue>
                </Stack>
              </Stack>
            </ViewContentBlock>

            {/* Affiliation */}
            <ViewContentBlock>
              <ViewContentBlockHeader>Affiliation</ViewContentBlockHeader>
              <AffiliationInfoDisplay
                tenantId={assessmentFlow.tenantId}
                departmentIds={assessmentFlow.departmentIds}
                locationIds={assessmentFlow.locationIds}
                sx={{ mt: 1 }}
              />
            </ViewContentBlock>

            {/* Customer communication */}
            <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
              <ViewContentBlock>
                <Grid sx={{ mb: 2 }} container rowSpacing={1}>
                  <Grid item xxs={12} md={12}>
                    <Stack direction='row' justifyContent='space-between'>
                      <ViewContentBlockHeader>Customer communication</ViewContentBlockHeader>

                      <Stack direction='row' spacing={1} alignItems='center'>
                        <Button
                          variant='outlined'
                          color='text'
                          size='small'
                          onClick={() => setIsCreateCommunicationEntryModalOpen(true)}
                        >
                          Customer contacted
                        </Button>

                        <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                          <MenuWithTrigger
                            withAuthCloseOnClick
                            trigger={
                              <IconButton sx={{ ml: "auto" }}>
                                <AppIcon of='moreVert' />
                              </IconButton>
                            }
                          >
                            <MenuItem onClick={() => setIsCommunicationInfoModalOpen(true)}>
                              <ListItemIcon>
                                <AppIcon of='history' fontSize='small' />
                              </ListItemIcon>
                              <ListItemText>View communication entries</ListItemText>
                            </MenuItem>
                          </MenuWithTrigger>
                        </AuthorizedElement>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xxs={12} md={12}>
                    {assessmentFlow?.contract && (
                      <ContractCustomerCommunicationInfo
                        contract={assessmentFlow.contract}
                        displayProps={{ overview: true, createNewEntry: false, entries: false }}
                      />
                    )}
                  </Grid>
                </Grid>
              </ViewContentBlock>
            </AuthorizedElement>

            {/* Reminders */}
            <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
              <ViewContentBlock>
                <Grid sx={{ mb: 2 }} container rowSpacing={1}>
                  <Grid item xxs={12} md={12}>
                    <ViewContentBlockHeader>Reminders</ViewContentBlockHeader>
                  </Grid>
                  <Grid item xxs={12} md={12}>
                    {assessmentFlow?.contract && (
                      <ContractReminderSettingsDisplay
                        contract={assessmentFlow.contract}
                        onUpdate={onContractReminderSettingsUpdated}
                      />
                    )}
                  </Grid>
                </Grid>
              </ViewContentBlock>
            </AuthorizedElement>

            <Stack direction='column' spacing={2}>
              {/* DDA */}
              <ViewContentBlock>
                {assessmentFlow && !assessmentFlow?.damageDetectionAggregateId && (
                  <>
                    <ViewContentBlockHeader>Damage detections</ViewContentBlockHeader>
                    <EntityNotFoundAlert
                      title='No damage detections yet'
                      icon={<AppIcon of='damageDetection' fontSize='medium' />}
                    />
                  </>
                )}
                {assessmentFlow?.damageDetectionAggregateId && (
                  <DamageDetectionAggregateView
                    aggregateId={assessmentFlow?.damageDetectionAggregateId}
                    withRealtimeDataUpdates
                    headerProps={{
                      withLink: true,
                      typographyProps: {
                        variant: "h3",
                      },
                      primaryActions: (aggregate) => (
                        <>
                          {!_.isEmpty(aggregate?.items) && (
                            <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                              <Button
                                variant='outlined'
                                color='text'
                                size='medium'
                                startIcon={<AppIcon of='send' />}
                                onClick={() => {
                                  setIsSendToAssessmentModalOpen(true);
                                }}
                              >
                                Send to assessment
                              </Button>
                            </AuthorizedElement>
                          )}
                        </>
                      ),
                    }}
                    displayProps={{
                      vehicle: false,
                      contract: false,
                      viewVariant: ViewLayoutVariant.Tab,
                    }}
                    customSections={{
                      afterHeader: assessmentFlow?.state?.isProcessingDamages && (
                        <Alert severity='info'>
                          <AlertTitle>
                            Damages are being processed at the moment{" "}
                            <CircularProgress color='secondary' size={14} />
                          </AlertTitle>
                          <Box>
                            <Typography component='div' variant='body1'>
                              The page will update automatically in short time. Please wait for a
                              moment.
                            </Typography>
                          </Box>
                        </Alert>
                      ),
                    }}
                  />
                )}
              </ViewContentBlock>

              {/* DCEA */}
              <ViewContentBlock>
                <Stack spacing={2}>
                  {assessmentFlow && (
                    <AssessmentFlowRelatedTenantRequestsForDamageCostEvaluation
                      assessmentFlow={assessmentFlow}
                      propagatedDeps={tenantRequestsForDamageCostEvaluationPropagatedDeps}
                    />
                  )}

                  {assessmentFlow && !assessmentFlow?.damageCostEvaluationAggregateId && (
                    <>
                      <ViewContentBlockHeader>Damage cost evaluations</ViewContentBlockHeader>
                      <EntityNotFoundAlert
                        title='No damage cost evaluations yet'
                        icon={<AppIcon of='damageCostEvaluation' fontSize='medium' />}
                      />
                    </>
                  )}
                  {assessmentFlow?.damageCostEvaluationAggregateId && (
                    <DamageCostEvaluationAggregateView
                      aggregateId={assessmentFlow?.damageCostEvaluationAggregateId}
                      withRealtimeDataUpdates
                      headerProps={{
                        withLink: true,
                        typographyProps: {
                          variant: "h3",
                        },
                      }}
                      displayProps={{
                        vehicle: false,
                        contract: false,
                        viewVariant: ViewLayoutVariant.Tab,
                      }}
                      customSections={{
                        afterHeader: assessmentFlow?.state?.isDamageCostEvaluating && (
                          <Alert severity='info'>
                            <AlertTitle>Damage costs are being evaluated at the moment.</AlertTitle>
                            <Box>
                              <Typography component='div' variant='body1'>
                                As soon as Damage Cost Evaluations will be completed the info will
                                appear here.
                              </Typography>
                            </Box>
                          </Alert>
                        ),
                      }}
                    />
                  )}
                </Stack>
              </ViewContentBlock>

              {/* AC */}
              {assessmentFlow &&
                (!assessmentFlow?.accessoryCheckIds ||
                  assessmentFlow?.accessoryCheckIds.length === 0) && (
                  <ViewContentBlock>
                    <ViewContentBlockHeader>Accessory checks</ViewContentBlockHeader>
                    <EntityNotFoundAlert
                      title='No accessory checks yet'
                      icon={<AppIcon of='accessoryCheck' fontSize='medium' />}
                    />
                  </ViewContentBlock>
                )}
              {assessmentFlow?.accessoryCheckIds?.map((accessoryCheckId) => (
                <ViewContentBlock key={accessoryCheckId}>
                  <AccessoryCheckView
                    accessoryCheckId={accessoryCheckId}
                    withRealtimeDataUpdates
                    headerProps={{
                      withLink: true,
                      titleTypographyProps: {
                        variant: "h3",
                      },
                    }}
                    displayProps={{
                      breadcrumbs: false,
                      vehicle: false,
                      contract: false,
                      viewVariant: ViewLayoutVariant.Tab,
                    }}
                  />
                </ViewContentBlock>
              ))}
            </Stack>
          </>
        )}

        {/* Customer contacted */}
        {assessmentFlow?.contract && (
          <CreateContractCustomerCommunicationEntryModal
            createEntryProps={{
              contractId: assessmentFlow.contract.id!,
              onSave: () => setIsCreateCommunicationEntryModalOpen(false),
            }}
            open={isCreateCommunicationEntryModalOpen}
            onClose={() => setIsCreateCommunicationEntryModalOpen(false)}
          />
        )}

        {/* Send to assessment (render only if open to ensure fresh data is loaded) */}
        {assessmentFlow && isSendToAssessmentModalOpen && (
          <SendDamagesToAssessmentModal
            open={isSendToAssessmentModalOpen}
            onClose={() => setIsSendToAssessmentModalOpen(false)}
            assessmentFlow={assessmentFlow}
            onSent={(newValue) => {
              tenantRequestsForDamageCostEvaluationPropagatedDeps.trigger("refetch");
            }}
          />
        )}
      </Stack>
    </PageTabContent>
  );
}
