import { Box, FormHelperText, Stack, SxProps, Theme } from "@mui/material";
import { AxiosRequestConfig } from "axios";
import _ from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { FileItem } from "@/common/fileItem";
import { ArrayHelper } from "@/common/helpers/array";
import { ComparisonHelper } from "@/common/helpers/comparison";
import { MimeTypeHelper } from "@/common/helpers/mimeType";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useTriggerRender } from "@/common/hooks/render/useTriggerRender";
import { ValidationHelper, ValidationInfo } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { FileApiApiV1FilesUploadPostRequest, UploadFilesResultDto } from "@/core/api/generated";

import AppIconButton from "../Button/AppIconButton";
import ValidationInfoModal from "../Error/ValidationInfoModal";
import FieldValue from "../Form/Display/FieldValue";
import AppIcon from "../Icons/AppIcon";
import FullScreenFileViewerV2, { FullScreenFileActions } from "../Images/FullScreenFileViewerV2";
import FileUploadArea, { FileUploadAreaProps } from "./FileUploadArea";
import FileUploadList, { FileUploadListProps } from "./FileUploadList";
import FileUploadSpecDisplayModal from "./FileUploadSpecDisplayModal";
import { ImageSimpleEditorProps } from "./ImageSimpleEditor";
import ImageSimpleEditorModal from "./ImageSimpleEditorModal";

export const defaultMaxFiles = 50;

export type UploadFilesFuncRequestParameters = FileApiApiV1FilesUploadPostRequest;

export type UploadFilesFunc = (
  requestParameters: UploadFilesFuncRequestParameters,
  options?: AxiosRequestConfig<any> | undefined,
) => Promise<UploadFilesResultDto>;

export interface FileUploaderProps {
  multiple?: boolean;
  /** Comma-separated list of unique file type specifiers.
   *  A unique file type specifier is a string that describes a type of file that may be selected by the user in an <input> element of type file.
   *  E.g. .pdf,.docx,image/png,image/jpeg,image/*.
   *  https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   */
  accept?: string;
  acceptText?: string;
  defaultFiles?: FileItem[];
  maxFiles?: number;
  disabled?: boolean;
  /** Upload area (drop zone) is shown only if user can upload more files. */
  hideUploadAreaIfCantUploadMore?: boolean;
  fileUploadAreaProps?: Pick<FileUploadAreaProps, "title">;
  fileUploadListProps?: Pick<FileUploadListProps, "itemActions">;
  withImageEditor?: boolean;
  imageEditorProps?: Partial<Pick<ImageSimpleEditorProps, "cropProps">>;
  sx?: SxProps<Theme>;
  onChange?: (newFileItems: FileItem[]) => void;
  onValidationStatusChange?: (filesValidationStatus?: Record<string, boolean>) => void;
  uploadFilesFunc?: UploadFilesFunc;
  onUploadStarted?: (newFiles: File[]) => void;
  onUploadFinished?: (newFiles: File[]) => void;
}

export default function FileUploader({
  multiple,
  accept,
  acceptText,
  defaultFiles = [],
  maxFiles,
  disabled,
  hideUploadAreaIfCantUploadMore,
  fileUploadAreaProps,
  fileUploadListProps,
  withImageEditor,
  imageEditorProps,
  sx,
  onChange,
  onValidationStatusChange,
  uploadFilesFunc,
  onUploadStarted,
  onUploadFinished,
}: FileUploaderProps) {
  maxFiles = !multiple ? 1 : Math.min(maxFiles || 999, defaultMaxFiles);

  const { triggerRender } = useTriggerRender();

  const { data: fileUploadSpec, isLoading: isSpecLoading } = useApiRequest(
    apiClient.filesApi.apiV1FilesUploadSpecGet,
    {},
  );

  const allowedAccepts =
    accept && fileUploadSpec
      ? MimeTypeHelper.buildFileInputAcceptFromUploadSpec(accept, fileUploadSpec)
      : undefined;

  const [uploadValidation] = useState<ValidationInfo | undefined>(undefined);
  const [fileItemToEdit, setFileItemToEdit] = useState<FileItem | undefined>(undefined);
  const [isImageEditorModalOpen, setIsImageEditorModalOpen] = useState(false);
  const [isErrorDetailsModalOpen, setIsErrorDetailsModalOpen] = useState<boolean>(false);
  const [isFileUploadSpecDisplayModalOpen, setIsFileUploadSpecDisplayModalOpen] =
    useState<boolean>(false);
  const [fileValidationInfo, setFileValidationInfo] = useState<ValidationInfo | undefined>(
    undefined,
  );
  const [filesValidationStatus, setFilesValidationStatus] = useState<Record<string, boolean>>({});
  // FullScreen
  const [fsFiles, setFsFiles] = useState<FileItem[]>([]);
  const [fsFileId, setFsFileId] = useState<string | undefined>(undefined);
  const memoizedDefaultFiles = useMemo(() => defaultFiles, [defaultFiles]);

  const fileItemsRef = useRef<FileItem[]>(defaultFiles || []);

  const uniqNameByFileItem = useCallback(
    (file: FileItem) => `${file.fileName}-${file.fileSize}`, // ensure key is unique
    [],
  );
  const uploadFilesFuncFinal: UploadFilesFunc =
    uploadFilesFunc ||
    (async (
      requestParameters: UploadFilesFuncRequestParameters,
      axiosParams?: AxiosRequestConfig<any> | undefined,
    ) => {
      const response = await apiClient.filesApi.apiV1FilesUploadPost(
        { ...requestParameters },
        axiosParams,
      );
      if (response.request.status !== 200) {
        throw response;
      }
      return response.data;
    });

  const canUploadCount = Math.abs(maxFiles - fileItemsRef.current.length);
  const canUploadMore = fileItemsRef.current.length < maxFiles;

  // handle changed defaultFiles (for some edge cases)
  useEffect(() => {
    if (memoizedDefaultFiles.length) {
      const defaultFileIds = new Set(memoizedDefaultFiles.map((file) => file.id));

      const addedFromDefault = memoizedDefaultFiles.filter(
        (file) => !fileItemsRef.current.some((currentFile) => currentFile.id === file.id),
      );

      const remainingFiles = fileItemsRef.current.filter((file) => defaultFileIds.has(file.id));

      fileItemsRef.current = [...remainingFiles, ...addedFromDefault];
    }
  }, [memoizedDefaultFiles.length]);

  // trigger onChange
  useEffect(() => {
    const defaultIds = _.orderBy(
      defaultFiles.map((x) => x.id),
      (x) => x,
      "asc",
    );
    const currentIds = _.orderBy(
      fileItemsRef.current.map((x) => x.id),
      (x) => x,
      "asc",
    );

    const isChanged =
      defaultIds.length !== currentIds.length ||
      !ComparisonHelper.isDeepEqual(defaultIds, currentIds);
    if (isChanged) {
      onChange && onChange(fileItemsRef.current.filter((x) => !!x.file));
    }
  }, [fileItemsRef.current]);

  useEffect(() => {
    onValidationStatusChange && onValidationStatusChange(filesValidationStatus);
  }, [filesValidationStatus]);

  const uploadFile = async (fileToUpload: File, filesToUpload: File[]) => {
    try {
      const fileItem = fileItemsRef.current.find((f) => f.fileName === fileToUpload.name);
      const uploadResult = await uploadFilesFuncFinal(
        { files: [fileToUpload] },
        { signal: fileItem?.abortController?.signal },
      );
      const uploadedFile = uploadResult.files!.find(
        (f) => f.originalFileName === fileToUpload.name,
      );
      if (fileItem && uploadedFile) {
        fileItem.setUploadedFile(uploadedFile);
        fileItemsRef.current = [...fileItemsRef.current];
        setFilesValidationStatus((prev) => ({
          ...prev,
          [uniqNameByFileItem(fileItem)]: true,
        }));
        triggerRender();
      }
    } catch (err) {
      const validation = ValidationHelper.handleApiErrorResponse(err);
      console.error("File upload error:", err, validation);

      // add ability to retry upload
      const fileItem = fileItemsRef.current.find((f) => f.fileName === fileToUpload.name);
      if (fileItem) {
        fileItem.isUploading = false;
        fileItem.validation = validation;
        // retry upload
        fileItem.retryUpload = async () => {
          fileItem.isUploading = true;
          fileItem.validation = undefined;
          fileItemsRef.current = [...fileItemsRef.current];
          triggerRender();
          onUploadStarted && onUploadStarted(filesToUpload);
          await uploadFile(fileToUpload, filesToUpload);
          onUploadFinished && onUploadFinished(filesToUpload);
        };
        fileItem.showErrorDetails = (validationInfo) => {
          setFileValidationInfo(validationInfo);
          setIsErrorDetailsModalOpen(true);
        };
        fileItemsRef.current = [...fileItemsRef.current];
        setFilesValidationStatus((prev) => ({
          ...prev,
          [uniqNameByFileItem(fileItem)]: false,
        }));
        triggerRender();
      }
    }
  };

  const handleFileUpload = async (newFiles: File[]) => {
    const filesToUpload = newFiles
      .filter((x) => !fileItemsRef.current.some((y) => y.fileName === x.name))
      .slice(0, canUploadCount);
    if (filesToUpload.length == 0) {
      return;
    }

    onUploadStarted && onUploadStarted(filesToUpload);

    // add new files to existing ones so the user can see preview of files that are being uploaded.
    const newFileItems = [...fileItemsRef.current, ...FileItem.createManyFrom(filesToUpload, true)];

    // add ability to abort upload
    const newFileItemsWithAbortController = newFileItems.map((f) => {
      const controller = new AbortController();
      f.abortController = controller;
      f.setUploadAbortFn(() => {
        f.abortController?.abort();
        f.isUploading = false;
        const newValues = fileItemsRef.current.filter((fl) => f.id !== fl.id); // remove file
        fileItemsRef.current = newValues;
        triggerRender();
      });
      return f;
    });

    fileItemsRef.current = newFileItemsWithAbortController;
    triggerRender();

    // upload each separately (one by one)
    for (let i = 0; i < filesToUpload.length; ++i) {
      await uploadFile(filesToUpload[i], filesToUpload);
    }

    onUploadFinished && onUploadFinished(filesToUpload);
  };

  const handleRemove = (file: FileItem) => {
    fileItemsRef.current = fileItemsRef.current.filter((f) => f.id !== file.id);
    setFilesValidationStatus((prev) => _.omit(prev, uniqNameByFileItem(file)));
    triggerRender();
  };

  const handleSetCaption = useCallback(
    (file: FileItem, caption: string | undefined) => {
      (fileItemsRef.current || []).forEach((x) => {
        if (x.id === file.id) {
          x.setAttachmentCaption(caption);
        }
      });
      onChange && onChange(fileItemsRef.current.filter((x) => !!x.file));
      triggerRender();
    },
    [fileItemsRef.current],
  );

  const isUploadAreaVisible = canUploadMore || (!canUploadMore && !hideUploadAreaIfCantUploadMore);

  return (
    <Stack sx={sx}>
      {/* Upload area */}
      {isUploadAreaVisible && (
        <FileUploadArea
          accept={allowedAccepts}
          acceptText={acceptText}
          disabled={isSpecLoading || !canUploadMore || disabled}
          multiple={multiple}
          maxFiles={maxFiles}
          fileUploadSpec={fileUploadSpec}
          onChange={handleFileUpload}
          {...fileUploadAreaProps}
        />
      )}

      {uploadValidation && uploadValidation.hasErrors && (
        <Box sx={{ p: 1 }}>
          <FormHelperText sx={{ m: 0, p: 0 }} error>
            {ValidationHelper.getErrorsAsString(uploadValidation)}
          </FormHelperText>
        </Box>
      )}

      {/* Uploaded files */}
      <FileUploadList
        {...fileUploadListProps}
        files={fileItemsRef.current}
        itemActions={(item) => {
          const actions = fileUploadListProps?.itemActions?.(item);
          return {
            enabled: true,
            click: true,
            edit: withImageEditor && MimeTypeHelper.isImage(item.mimeType),
            remove: true,
            download: true,
            setCaption: actions?.setCaption,
            onSetCaption: (caption) => {
              handleSetCaption(item, caption);
              actions?.onSetCaption?.(caption);
            },
            onClick: () => {
              setFsFiles(fileItemsRef.current);
              setFsFileId(item.id);
            },
            onEdit: () => {
              setFileItemToEdit(item);
              setIsImageEditorModalOpen(true);
            },
            onRemove: () => {
              handleRemove(item);
              actions?.onRemove?.();
            },
          };
        }}
      />

      {/* Image editor */}
      {withImageEditor && fileItemToEdit && isImageEditorModalOpen && (
        <ImageSimpleEditorModal
          open={isImageEditorModalOpen}
          onClose={() => setIsImageEditorModalOpen(false)}
          editorProps={{
            ...imageEditorProps,
            fileItem: fileItemToEdit,
            onCancel: () => {
              setIsImageEditorModalOpen(false);
            },
            onSave: (newFileItem) => {
              setIsImageEditorModalOpen(false);
              const newFileItems =
                ArrayHelper.replaceByPredicate(
                  [...fileItemsRef.current],
                  (x) => x.id === fileItemToEdit.id,
                  newFileItem,
                ) || [];

              fileItemsRef.current = [...newFileItems];
            },
          }}
        />
      )}
      {fileValidationInfo && (
        <ValidationInfoModal
          customContent={
            <FieldValue label='File upload spec'>
              <AppIconButton
                color='text'
                size='small'
                tooltipProps={{
                  title: "View file upload spec",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsFileUploadSpecDisplayModalOpen(true);
                }}
              >
                <AppIcon of='info' />
              </AppIconButton>
            </FieldValue>
          }
          open={isErrorDetailsModalOpen}
          onClose={() => {
            setIsErrorDetailsModalOpen(false);
          }}
          problemDetails={undefined}
          validationInfo={fileValidationInfo}
        />
      )}
      <FileUploadSpecDisplayModal
        displayProps={{
          spec: fileUploadSpec,
        }}
        open={isFileUploadSpecDisplayModalOpen}
        onClose={() => setIsFileUploadSpecDisplayModalOpen(false)}
      />
      {/* Fullscreen file viewer */}
      <FullScreenFileViewerV2
        files={fsFiles}
        selectedFileId={fsFileId}
        actions={(item) => {
          const actions = fileUploadListProps?.itemActions?.(item);
          return {
            setCaption: actions?.setCaption,
            onSetCaption: (caption) => {
              handleSetCaption(item, caption);
              actions?.onSetCaption?.(caption);
            },
            download: true,
          } as FullScreenFileActions;
        }}
        onSelectFile={(file) => {
          setFsFileId(file.id);
        }}
        onClose={() => {
          setFsFiles([]);
          setFsFileId(undefined);
        }}
      />
    </Stack>
  );
}
