import { useMediaQuery, useTheme } from "@mui/material";
import { useCallback } from "react";

import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedGuard from "@/common/components/Auth/AuthorizedGuard";
import AppNavIcon from "@/common/components/Icons/AppNavIcon";
import AppNav from "@/common/components/Nav/AppNav";
import AppNavItem from "@/common/components/Nav/AppNavItem";
import { ROUTE_PATH } from "@/common/constants/routing";
import { EnvHelper } from "@/common/helpers/env";
import { UrlHelper } from "@/common/helpers/url";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import { tenantService } from "@/common/services/tenant";
import { AppPermission } from "@/core/api/generated";
import * as appCommonSlice from "@/store/appCommon/slice";

function Nav() {
  const theme = useTheme();
  const thunkDispatch = useAppThunkDispatch();

  // const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const isNavOpened = useAppSelector((x) => x.app.isNavOpened);

  const handleNavItemClick = useCallback(
    (props: { to?: string }) => {
      // scroll top top
      // topAnchorRef.current?.scrollIntoView({ behavior: "smooth" });

      // on mobile auto-close nav after clicking link
      if (props.to && isMobile && isNavOpened) {
        thunkDispatch(appCommonSlice.toggleNav());
      }
    },
    [isMobile, isNavOpened],
  );

  const fleetAppUrl = UrlHelper.addUrlPathname(
    tenantService.getDefaultTenantUrl(false),
    ROUTE_PATH.APP_AREA_FLEET_APP,
  );
  const fleetCustomerAppUrl = UrlHelper.addUrlPathname(
    tenantService.getDefaultTenantUrl(false),
    ROUTE_PATH.APP_AREA_FLEET_CUSTOMER,
  );

  return (
    <AppNav
      isNavOpened={isNavOpened}
      toggleNav={() => thunkDispatch(appCommonSlice.toggleNav())}
      titleTo='/'
      items={
        <>
          {EnvHelper.isLocalhostAny && (
            <AppNavItem
              type='item'
              item={{
                startIcon: <AppNavIcon of='code' />,
                content: "Styleguide (dev only)",
                to: ROUTE_PATH.DEV_STYLEGUIDE,
                onClick: handleNavItemClick,
              }}
            />
          )}

          <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
            <AppNavItem
              type='item'
              item={{
                startIcon: <AppNavIcon of='webApp' />,
                content: "Fleet app",
                to: fleetAppUrl,
                onClick: handleNavItemClick,
              }}
            />
          </AuthorizedElement>
          <AuthorizedElement permissions={[AppPermission.FleetCustomerAppAccess]}>
            <AppNavItem
              type='item'
              item={{
                startIcon: <AppNavIcon of='webApp' />,
                content: "Fleet customer app",
                to: fleetCustomerAppUrl,
                onClick: handleNavItemClick,
              }}
            />
          </AuthorizedElement>

          <AuthorizedGuard permissions={[AppPermission.AdminAppAccess]}>
            <>
              <AppNavItem
                type='item'
                item={{
                  startIcon: <AppNavIcon of='dashboard' />,
                  content: "Dashboard",
                  to: ROUTE_PATH.ADMIN_DASHBOARD,
                  onClick: handleNavItemClick,
                }}
              />
              <AppNavItem
                type='item'
                item={{
                  startIcon: <AppNavIcon of='dashboard' />,
                  content: "Company dashboard",
                  to: ROUTE_PATH.ADMIN_COMPANY_DASHBOARD,
                  onClick: handleNavItemClick,
                }}
              />
              <AppNavItem
                type='item'
                item={{
                  startIcon: <AppNavIcon of='tenants' />,
                  content: "Tenants",
                  to: ROUTE_PATH.ADMIN_TENANTS,
                  onClick: handleNavItemClick,
                }}
              />
              <AppNavItem
                type='item'
                item={{
                  startIcon: <AppNavIcon of='users' />,
                  content: "Users",
                  to: ROUTE_PATH.ADMIN_USERS,
                  onClick: handleNavItemClick,
                }}
              />
              <AppNavItem
                type='item'
                item={{
                  startIcon: <AppNavIcon of='roles' />,
                  content: "Roles",
                  to: ROUTE_PATH.ADMIN_ROLES,
                  onClick: handleNavItemClick,
                }}
              />
              <AppNavItem
                type='item'
                item={{
                  startIcon: <AppNavIcon of='invites' />,
                  content: "Invites",
                  to: ROUTE_PATH.ADMIN_INVITES,
                  onClick: handleNavItemClick,
                }}
              />
              <AppNavItem
                type='item'
                item={{
                  startIcon: <AppNavIcon of='session' />,
                  content: "Custom sessions",
                  to: ROUTE_PATH.ADMIN_CUSTOM_SESSIONS,
                  onClick: handleNavItemClick,
                }}
              />
              <AuthorizedElement permissions={[AppPermission.AdminNotificationsManage]}>
                <AppNavItem
                  type='item'
                  item={{
                    startIcon: <AppNavIcon of='mail' />,
                    content: "Notifications",
                    to: ROUTE_PATH.ADMIN_NOTIFICATIONS,
                    onClick: handleNavItemClick,
                  }}
                />
              </AuthorizedElement>
              <AppNavItem
                type='item'
                item={{
                  startIcon: <AppNavIcon of='subscription' />,
                  content: "Subscription plans",
                  to: ROUTE_PATH.ADMIN_SUBSCRIPTION_PLANS,
                  onClick: handleNavItemClick,
                }}
              />
              <AppNavItem
                type='item'
                item={{
                  startIcon: <AppNavIcon of='invoices' />,
                  content: "Invoices",
                  to: ROUTE_PATH.ADMIN_INVOICES,
                  onClick: handleNavItemClick,
                }}
              />

              <AppNavItem
                type='item'
                item={{
                  startIcon: <AppNavIcon of='tenantRequest' />,
                  content: "Company requests",
                  to: ROUTE_PATH.ADMIN_TENANT_REQUESTS,
                  onClick: handleNavItemClick,
                }}
              />

              <AuthorizedElement permissionsAny={[AppPermission.AdminFileManage]}>
                <AppNavItem
                  type='item'
                  item={{
                    startIcon: <AppNavIcon of='files' />,
                    content: "Files",
                    to: ROUTE_PATH.ADMIN_FILES,
                    onClick: handleNavItemClick,
                  }}
                />
              </AuthorizedElement>

              {/* Reference data */}
              <AuthorizedElement
                permissionsAny={[
                  AppPermission.AdminReferenceDataRead,
                  AppPermission.AdminReferenceDataManage,
                ]}
              >
                <AppNavItem
                  type='itemGroup'
                  itemGroup={{
                    startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='referenceData' />,
                    content: "Reference data",
                    to: undefined,
                    onClick: handleNavItemClick,
                    items: (
                      <>
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Countries",
                            to: ROUTE_PATH.ADMIN_COUNTRIES,
                            onClick: handleNavItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Currencies",
                            to: ROUTE_PATH.ADMIN_CURRENCIES,
                            onClick: handleNavItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Vehicle types",
                            to: ROUTE_PATH.ADMIN_VEHICLE_TYPES,
                            onClick: handleNavItemClick,
                          }}
                        />

                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Vehicle makes",
                            to: ROUTE_PATH.ADMIN_VEHICLE_MAKES,
                            onClick: handleNavItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Vehicle models",
                            to: ROUTE_PATH.ADMIN_VEHICLE_MODELS,
                            onClick: handleNavItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Vehicle generations",
                            to: ROUTE_PATH.ADMIN_VEHICLE_GENERATIONS,
                            onClick: handleNavItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Vehicle modifications",
                            to: ROUTE_PATH.ADMIN_VEHICLE_MODIFICATIONS,
                            onClick: handleNavItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Vehicle  body types",
                            to: ROUTE_PATH.ADMIN_VEHICLE_BODY_TYPES,
                            onClick: handleNavItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Vehicle fuel types",
                            to: ROUTE_PATH.ADMIN_VEHICLE_FUEL_TYPES,
                            onClick: handleNavItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Damage types",
                            to: ROUTE_PATH.ADMIN_DAMAGE_TYPES,
                            onClick: handleNavItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Vehicle part types",
                            to: ROUTE_PATH.ADMIN_VEHICLE_PART_TYPES,
                            onClick: handleNavItemClick,
                          }}
                        />
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "Vehicle visual models",
                            to: ROUTE_PATH.ADMIN_VEHICLE_VISUAL_MODELS,
                            onClick: handleNavItemClick,
                          }}
                        />
                      </>
                    ),
                  }}
                />
              </AuthorizedElement>

              {/* Repair */}
              <AppNavItem
                type='itemGroup'
                itemGroup={{
                  startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='repair' />,
                  content: "Repair",
                  to: undefined,
                  onClick: handleNavItemClick,
                  items: (
                    <>
                      <AppNavItem
                        type='item'
                        item={{
                          isFromGroup: true,
                          startIcon: <AppNavIcon of='repairMaterial' />,
                          content: "Materials",
                          to: ROUTE_PATH.ADMIN_REPAIR_MATERIALS,
                          onClick: handleNavItemClick,
                        }}
                      />
                      <AppNavItem
                        type='item'
                        item={{
                          isFromGroup: true,
                          startIcon: <AppNavIcon of='repairWork' />,
                          content: "Work",
                          to: ROUTE_PATH.ADMIN_REPAIR_WORK_LIST,
                          onClick: handleNavItemClick,
                        }}
                      />
                      <AppNavItem
                        type='item'
                        item={{
                          isFromGroup: true,
                          startIcon: <AppNavIcon of='repairSparePart' />,
                          content: "Spare parts",
                          to: ROUTE_PATH.ADMIN_REPAIR_SPARE_PART_LIST,
                          onClick: handleNavItemClick,
                        }}
                      />
                      <AppNavItem
                        type='item'
                        item={{
                          startIcon: <AppNavIcon of='repairSpec' />,
                          content: "Spec",
                          to: ROUTE_PATH.ADMIN_REPAIR_SPECS,
                          onClick: handleNavItemClick,
                        }}
                      />
                    </>
                  ),
                }}
              />

              {/* Integration */}
              <AuthorizedElement permissionsAny={[AppPermission.IntegrationApiClientRead]}>
                <AppNavItem
                  type='itemGroup'
                  itemGroup={{
                    startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='integration' />,
                    content: "Integration",
                    to: undefined,
                    onClick: handleNavItemClick,
                    items: (
                      <>
                        <AuthorizedElement permissions={[AppPermission.IntegrationApiClientRead]}>
                          <AppNavItem
                            type='item'
                            item={{
                              isFromGroup: true,
                              startIcon: <AppNavIcon of='apiClient' />,
                              content: "API clients",
                              to: ROUTE_PATH.ADMIN_INTEGRATION_API_CLIENTS,
                              onClick: handleNavItemClick,
                            }}
                          />
                        </AuthorizedElement>
                      </>
                    ),
                  }}
                />
              </AuthorizedElement>

              {/* Other */}
              <AppNavItem
                type='itemGroup'
                itemGroup={{
                  startIcon: (fill) => <AppNavIcon sx={{ fill: fill }} of='other' />,
                  content: "Other",
                  to: undefined,
                  onClick: handleNavItemClick,
                  items: (
                    <>
                      <AuthorizedElement permissions={[AppPermission.AccessoryRead]}>
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "App enums",
                            to: ROUTE_PATH.ADMIN_SYSTEM_APPLICATION_ENUMS,
                            onClick: handleNavItemClick,
                          }}
                        />
                      </AuthorizedElement>
                      <AuthorizedElement permissions={[AppPermission.AccessoryRead]}>
                        <AppNavItem
                          type='item'
                          item={{
                            isFromGroup: true,
                            startIcon: <AppNavIcon of='data' />,
                            content: "System cache",
                            to: ROUTE_PATH.ADMIN_SYSTEM_CACHE,
                            onClick: handleNavItemClick,
                          }}
                        />
                      </AuthorizedElement>
                      <AuthorizedElement permissions={[AppPermission.AdminDomainEventsManage]}>
                        <AppNavItem
                          type='item'
                          item={{
                            startIcon: <AppNavIcon of='data' />,
                            content: "Domain events",
                            to: ROUTE_PATH.ADMIN_DOMAIN_EVENTS,
                            onClick: handleNavItemClick,
                          }}
                        />
                      </AuthorizedElement>
                    </>
                  ),
                }}
              />
            </>
          </AuthorizedGuard>
        </>
      }
    />
  );
}

export default Nav;
