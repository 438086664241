import { Stack, StackProps } from "@mui/material";

interface Props extends StackProps {
  variant?: "standart" | "one-sided";
}

const ViewContentBlock = ({ variant = "standart", children, ...otherProps }: Props) => {
  const isOneSided = variant === "one-sided";

  return (
    <Stack
      spacing={1}
      {...otherProps}
      className='ViewContentBlock'
      sx={{
        p: 1.5,
        backgroundColor: (t) =>
          isOneSided ? t.palette.background.default : t.palette.background.paper,
        borderRadius: (t) => t.shapeCustom.borderRadiusCard,
        borderTopLeftRadius: (t) => (isOneSided ? "2px" : t.shapeCustom.borderRadiusCard),
        borderBottomLeftRadius: (t) => (isOneSided ? "2px" : t.shapeCustom.borderRadiusCard),
        borderLeft: (t) => (isOneSided ? `2px solid ${t.palette.divider}` : "none"),
        ...otherProps?.sx,
      }}
    >
      {children}
    </Stack>
  );
};

export default ViewContentBlock;
