import { Alert, FormControl, FormHelperText, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import _ from "lodash";
import { ReactNode, useState } from "react";
import * as Yup from "yup";

import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FileItem } from "@/common/fileItem";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractStage,
  DataGrantPermission,
  EntityType,
  GeneralAttachmentDto,
  GeneralStageUpdateDtoOfTenantRequestStage,
  TenantRequestContentInputDto,
  TenantRequestCreateDto,
  TenantRequestDto,
  TenantRequestOperationType,
  TenantRequestStage,
  TenantRequestType,
  TenantRequestUpdateDto,
} from "@/core/api/generated";

import SplitDropdownButton from "../../Button/SplitDropdownButton";
import FoldableBlock from "../../Display/FoldableBlock";
import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import ApiEnumMultiselect from "../../Enum/ApiEnumMultiselect";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FileUploader from "../../Files/FileUploader";
import AppTextArea from "../../Form/Input/AppTextArea";
import AppLink from "../../Link/AppLink";
import ContractAutocompleteOrCreate from "../Contract/ContractAutocompleteOrCreate";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import CurrencyAutocomplete from "../ReferenceData/CurrencyAutocomplete";
import TenantAutocomplete from "../Tenant/TenantAutocomplete";
import TenantInline from "../Tenant/TenantInline";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import VehicleDamagesAutocompleteOrByRelatedEntities from "../VehicleDamage/VehicleDamagesAutocompleteOrByRelatedEntities";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  name?: TenantRequestCreateDto["name"];
  receiverTenantId?: TenantRequestCreateDto["receiverTenantId"];
  content?: TenantRequestCreateDto["content"];
  tags?: TenantRequestCreateDto["tags"];
  contractId?: TenantRequestCreateDto["contractId"];
  assessmentFlowId?: TenantRequestCreateDto["assessmentFlowId"];
  newStage?: TenantRequestCreateDto["newStage"];
};

export interface TenantRequestCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<TenantRequestDto, DefaultValues> {
  tenantRequestId?: string;
  tenantRequest?: TenantRequestDto | null;
  customization?: {
    secondaryActions?: ReactNode;
  };
}

export type TenantRequestCreateUpdateProps = TenantRequestCreateUpdateOwnProps;

export default function TenantRequestCreateUpdate({
  tenantRequestId,
  tenantRequest,
  defaultValues,
  customization,
  onCreate,
  onUpdate,
  onSave,
}: TenantRequestCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !tenantRequestId;
  const isEdit = !!tenantRequestId;

  const [isUploadingFiles, setIsUploadingFiles] = useState(false);

  const tenantRequestRequest = useApiRequest(
    apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      tenantRequestId: tenantRequestId!,
    },
    {
      skip: !!tenantRequest || !tenantRequestId,
    },
  );
  tenantRequest = tenantRequest || tenantRequestRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: tenantRequest && {
      idValue: tenantRequest.id!,
      newTitle: tenantRequest.globalNumber || "",
    },
  });

  const isCanEdit =
    isCreate || (isEdit && tenantRequest?.stageHistory?.stage === TenantRequestStage.Draft);

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.TenantRequest}
      entityId={tenantRequestId}
      entity={tenantRequest}
      entityRequest={tenantRequestRequest}
    >
      <Stack spacing={1}>
        {isEdit && (
          <Alert severity='info'>Edit allowed only on {TenantRequestStage.Draft} stage.</Alert>
        )}

        <Formik<
          BaseFormikValues &
            TenantRequestCreateDto &
            TenantRequestUpdateDto & {
              content: TenantRequestContentInputDto & {
                isAttachmentFilesHaveErrors?: boolean;
                initialAttachments?: GeneralAttachmentDto[] | null;
                uploadedAttachments?: FileItem[];
              };
            }
        >
          enableReinitialize
          initialValues={{
            receiverTenantId:
              tenantRequest?.receiverTenant?.id || defaultValues?.receiverTenantId || undefined,
            name: tenantRequest?.name || defaultValues?.name || undefined,
            description: tenantRequest?.description || undefined,
            content: tenantRequest?.content ||
              defaultValues?.content || {
                type: TenantRequestType.Operation,
                arbitrary: {},
                operation: {},
                attachments: tenantRequest?.content?.attachments || undefined,

                initialAttachments: tenantRequest?.content?.attachments || undefined,
                isAttachmentFilesHaveErrors: false,
              },
            dataGrantSettings: tenantRequest?.dataGrantSettings || {
              permissions: [DataGrantPermission.Read],
            },
            tags: tenantRequest?.tags || defaultValues?.tags || undefined,
            contractId: tenantRequest?.contract?.id || defaultValues?.contractId || undefined,
            assessmentFlowId:
              tenantRequest?.assessmentFlow?.id || defaultValues?.assessmentFlowId || undefined,
            newStage: defaultValues?.newStage || undefined,

            submit: "",
          }}
          validationSchema={Yup.object().shape({
            // vehicleId: Yup.string().required("Vehicle is required"),
          })}
          onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
            try {
              if (isCreate) {
                const response =
                  await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsPost({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    tenantRequestCreateDto: {
                      ...values,
                    },
                  });
                enqueueSnackbar("Company request created", {
                  variant: "success",
                });
                onCreate && onCreate(response.data);
                onSave && onSave(response.data);
              } else {
                const response =
                  await apiClient.tenantRequestsApi.apiV1TenantToTenantTenantRequestsTenantRequestIdPut(
                    {
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      tenantRequestId,
                      tenantRequestUpdateDto: {
                        ...values,
                      },
                    },
                  );
                enqueueSnackbar("Company request updated.", { variant: "success" });
                onUpdate && onUpdate(response.data);
                onSave && onSave(response.data);
              }

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err: any) {
              if (mounted.current) {
                ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
            submitForm,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <Box>
                    {/* Tags */}
                    <FormControl margin='dense' fullWidth>
                      <GeneralAttachedTagsInput
                        value={values.tags}
                        onChange={(newValue) => {
                          setFieldValue("tags", newValue);
                        }}
                      />
                      <FormHelperText error>
                        {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                          isIncludeNested: false,
                        })}
                      </FormHelperText>
                    </FormControl>

                    <FormControl margin='dense' fullWidth>
                      <TenantAutocomplete
                        entityId={values.receiverTenantId}
                        searchFilters={{
                          isInMyConnections: true,
                        }}
                        required
                        disabled={isEdit || !isCanEdit}
                        label='Receiver company'
                        onChange={(newValue) => setFieldValue("receiverTenantId", newValue?.id)}
                        textFieldProps={{
                          error: Boolean(errors.receiverTenantId),
                          helperText: errors.receiverTenantId,
                        }}
                      />
                      <FormHelperText>
                        Company from your{" "}
                        <AppLink to={ROUTE_PATH.TENANT_CONNECTIONS_INDEX}>connections</AppLink>{" "}
                        which will receive this request.
                      </FormHelperText>
                    </FormControl>

                    <TextField
                      error={Boolean(touched.name && errors.name)}
                      required
                      disabled={!isCanEdit}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label='Name'
                      margin='dense'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.name || ""}
                      variant='outlined'
                    />

                    <AppTextArea
                      error={Boolean(touched.description && errors.description)}
                      disabled={!isCanEdit}
                      fullWidth
                      helperText={touched.description && errors.description}
                      mode='description'
                      margin='dense'
                      name='description'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description || ""}
                      variant='outlined'
                    />
                  </Box>

                  {/* Content */}
                  <Stack spacing={2}>
                    <Typography variant='h4'>Request content</Typography>

                    <Alert severity='info'>
                      Here you specify data needed for receiver company to complete the request.
                      Your data will be temporary shared via{" "}
                      <AppLink to={ROUTE_PATH.DATA_GRANTS()}>data grants</AppLink> with the receiver
                      company and revoked once the request is closed.
                    </Alert>

                    <FormControl margin='dense' fullWidth>
                      <ApiEnumAutocomplete
                        type='TenantRequestType'
                        value={values.content?.type}
                        onChange={(newValue) => {
                          setFieldValue("content", undefined);
                          setFieldValue("content.type", newValue);
                          setFieldValue(
                            "content.arbitrary",
                            newValue === TenantRequestType.Arbitrary ? {} : undefined,
                          );
                          setFieldValue(
                            "content.operation",
                            newValue === TenantRequestType.Operation ? {} : undefined,
                          );
                        }}
                        label='Type'
                        required
                        disabled={isEdit || !isCanEdit}
                        textFieldProps={{
                          error: Boolean(getIn(errors, "content.type")),
                          helperText: ValidationHelper.getErrorsAsString(
                            getIn(errors, "content.type"),
                          ),
                        }}
                      />
                    </FormControl>

                    {values.content?.type &&
                      values.content?.type !== TenantRequestType.Arbitrary && (
                        <FoldableBlock
                          defaultIsFolded={false}
                          trigger={{
                            label: (
                              <Typography component='span' variant='subtitle1'>
                                Data for &apos;
                                <InlineApiEnumValue
                                  type='TenantRequestType'
                                  value={values.content?.type}
                                />
                                &apos; *
                              </Typography>
                            ),
                          }}
                        >
                          <Stack spacing={1}>
                            {/* Arbitrary type inputs */}
                            {/* {values.content?.type === TenantRequestType.Arbitrary && <Box></Box>} */}

                            {/* Operation type inputs */}
                            {values.content?.type === TenantRequestType.Operation && (
                              <Box>
                                <FormControl margin='dense' fullWidth>
                                  <ApiEnumAutocomplete
                                    type='TenantRequestOperationType'
                                    value={values.content?.operation?.operationType}
                                    onChange={(newValue) => {
                                      setFieldValue("content.operation", undefined);
                                      setFieldValue("content.operation.operationType", newValue);

                                      // Initialize empty objects for each operation type to ensure the form fields are properly defined.
                                      // This prevents general null errors (e.g., "content.operation.accessoryCheck is null") and ensures
                                      // that each field can produce its own specific error message.
                                      switch (newValue) {
                                        case TenantRequestOperationType.DamageDetection:
                                          setFieldValue("content.operation.damageDetection", {});
                                          break;
                                        case TenantRequestOperationType.VisualInspection:
                                          setFieldValue("content.operation.visualInspection", {});
                                          break;
                                        case TenantRequestOperationType.DamageCostEvaluation:
                                          setFieldValue(
                                            "content.operation.damageCostEvaluation",
                                            {},
                                          );
                                          break;
                                        case TenantRequestOperationType.AccessoryCheck:
                                          setFieldValue("content.operation.accessoryCheck", {});
                                          break;
                                        case TenantRequestOperationType.RepairOperation:
                                          setFieldValue("content.operation.repairOperation", {});
                                          break;
                                        case TenantRequestOperationType.WheelOperation:
                                          setFieldValue("content.operation.wheelOperation", {});
                                          break;
                                        case TenantRequestOperationType.Wash:
                                          setFieldValue("content.operation.wash", {});
                                          break;
                                        case TenantRequestOperationType.Maintenance:
                                          setFieldValue("content.operation.maintenance", {});
                                          break;
                                        case TenantRequestOperationType.None:
                                        default:
                                          setFieldValue("content.operation", undefined);
                                          break;
                                      }
                                    }}
                                    label='Operation type'
                                    required
                                    disabled={isEdit || !isCanEdit}
                                    textFieldProps={{
                                      error: Boolean(
                                        getIn(errors, "content.operation.operationType"),
                                      ),
                                      helperText: ValidationHelper.getErrorsAsString(
                                        getIn(errors, "content.operation.operationType"),
                                      ),
                                    }}
                                  />
                                </FormControl>

                                {/* Specific operation inputs */}
                                {values.content?.operation?.operationType && (
                                  <Box>
                                    {renderIf()
                                      .if(
                                        values.content?.operation?.operationType ===
                                          TenantRequestOperationType.VisualInspection,
                                      )
                                      .then(
                                        <Box>
                                          <FormControl margin='dense' fullWidth>
                                            <VehicleAutocompleteOrCreate
                                              autocompleteProps={{
                                                required: true,
                                                disabled: !isCreate || !isCanEdit,
                                                entityId:
                                                  values.content?.operation?.visualInspection
                                                    ?.vehicleId,
                                                isPreload: isCreate,
                                                textFieldProps: {
                                                  error: Boolean(
                                                    getIn(
                                                      errors,
                                                      "content.operation.visualInspection.vehicleId",
                                                    ),
                                                  ),
                                                  helperText: ValidationHelper.getErrorsAsString(
                                                    getIn(
                                                      errors,
                                                      "content.operation.visualInspection.vehicleId",
                                                    ),
                                                  ),
                                                },
                                                onChange: (newValue) => {
                                                  setFieldValue(
                                                    "content.operation.visualInspection.vehicleId",
                                                    newValue?.id,
                                                  );
                                                },
                                              }}
                                              createFormPlacement='modal'
                                              onCreate={(newValue) => {
                                                setFieldValue(
                                                  "content.operation.visualInspection.vehicleId",
                                                  newValue?.id,
                                                );
                                              }}
                                            />
                                          </FormControl>

                                          {values.content?.operation?.visualInspection
                                            ?.vehicleId && (
                                            <FormControl margin='dense' fullWidth>
                                              <ContractAutocompleteOrCreate
                                                autocompleteProps={{
                                                  disabled: defaultValues?.contractId != null,
                                                  entityId: values.contractId,
                                                  isPreload: isCreate,
                                                  searchFilters: {
                                                    vehicleId:
                                                      values.content?.operation?.visualInspection
                                                        ?.vehicleId || undefined,
                                                    excludeStage: ContractStage.Draft,
                                                  },
                                                  onChange: (newValue) => {
                                                    setFieldValue(`contractId`, newValue?.id);
                                                  },
                                                }}
                                                createFormPlacement='modal'
                                                onCreate={(newValue) => {
                                                  setFieldValue(`contractId`, newValue?.id);
                                                }}
                                              />
                                            </FormControl>
                                          )}

                                          {_.isString(
                                            getIn(errors, "content.operation.visualInspection"),
                                          ) && (
                                            <FormHelperText error>
                                              {getIn(errors, "content.operation.visualInspection")}
                                            </FormHelperText>
                                          )}
                                        </Box>,
                                      )
                                      .elseif(
                                        values.content?.operation?.operationType ===
                                          TenantRequestOperationType.DamageDetection,
                                      )
                                      .then(
                                        <Box>
                                          <FormControl margin='dense' fullWidth>
                                            <VehicleAutocompleteOrCreate
                                              autocompleteProps={{
                                                required: true,
                                                disabled: !isCreate || !isCanEdit,
                                                entityId:
                                                  values.content?.operation?.damageDetection
                                                    ?.vehicleId,
                                                isPreload: isCreate,
                                                textFieldProps: {
                                                  error: Boolean(
                                                    getIn(
                                                      errors,
                                                      "content.operation.damageDetection.vehicleId",
                                                    ),
                                                  ),
                                                  helperText: ValidationHelper.getErrorsAsString(
                                                    getIn(
                                                      errors,
                                                      "content.operation.damageDetection.vehicleId",
                                                    ),
                                                  ),
                                                },
                                                onChange: (newValue) => {
                                                  setFieldValue(
                                                    "content.operation.damageDetection.vehicleId",
                                                    newValue?.id,
                                                  );
                                                },
                                              }}
                                              createFormPlacement='modal'
                                              onCreate={(newValue) => {
                                                setFieldValue(
                                                  "content.operation.damageDetection.vehicleId",
                                                  newValue?.id,
                                                );
                                              }}
                                            />
                                          </FormControl>

                                          {values.content?.operation?.damageDetection
                                            ?.vehicleId && (
                                            <FormControl margin='dense' fullWidth>
                                              <ContractAutocompleteOrCreate
                                                autocompleteProps={{
                                                  disabled: defaultValues?.contractId != null,
                                                  entityId: values.contractId,
                                                  isPreload: isCreate,
                                                  searchFilters: {
                                                    vehicleId:
                                                      values.content?.operation?.damageDetection
                                                        ?.vehicleId || undefined,
                                                    excludeStage: ContractStage.Draft,
                                                  },
                                                  onChange: (newValue) => {
                                                    setFieldValue(`contractId`, newValue?.id);
                                                  },
                                                }}
                                                createFormPlacement='modal'
                                                onCreate={(newValue) => {
                                                  setFieldValue(`contractId`, newValue?.id);
                                                }}
                                              />
                                            </FormControl>
                                          )}

                                          {_.isString(
                                            getIn(errors, "content.operation.damageDetection"),
                                          ) && (
                                            <FormHelperText error>
                                              {getIn(errors, "content.operation.damageDetection")}
                                            </FormHelperText>
                                          )}
                                        </Box>,
                                      )
                                      .elseif(
                                        values.content?.operation?.operationType ===
                                          TenantRequestOperationType.DamageCostEvaluation,
                                      )
                                      .then(
                                        <Box>
                                          <FormControl margin='dense' fullWidth>
                                            <VehicleAutocompleteOrCreate
                                              autocompleteProps={{
                                                required: true,
                                                disabled: !isCreate || !isCanEdit,
                                                entityId:
                                                  values.content?.operation?.damageCostEvaluation
                                                    ?.vehicleId,
                                                isPreload: isCreate,
                                                textFieldProps: {
                                                  error: Boolean(
                                                    getIn(
                                                      errors,
                                                      "content.operation.damageCostEvaluation.vehicleId",
                                                    ),
                                                  ),
                                                  helperText: ValidationHelper.getErrorsAsString(
                                                    getIn(
                                                      errors,
                                                      "content.operation.damageCostEvaluation.vehicleId",
                                                    ),
                                                  ),
                                                },
                                                onChange: (newValue) => {
                                                  setFieldValue(
                                                    "content.operation.damageCostEvaluation.vehicleId",
                                                    newValue?.id,
                                                  );
                                                  setFieldValue(
                                                    "content.operation.damageCostEvaluation.vehicleDamageIds",
                                                    [],
                                                  );
                                                },
                                              }}
                                              createFormPlacement='modal'
                                              onCreate={(newValue) => {
                                                setFieldValue(
                                                  "content.operation.damageCostEvaluation.vehicleId",
                                                  newValue?.id,
                                                );
                                              }}
                                            />
                                          </FormControl>

                                          {values.content?.operation?.damageCostEvaluation
                                            ?.vehicleId && (
                                            <FormControl margin='dense' fullWidth>
                                              <ContractAutocompleteOrCreate
                                                autocompleteProps={{
                                                  disabled: defaultValues?.contractId != null,
                                                  entityId: values.contractId,
                                                  isPreload: isCreate,
                                                  searchFilters: {
                                                    vehicleId:
                                                      values.content?.operation
                                                        ?.damageCostEvaluation?.vehicleId ||
                                                      undefined,
                                                    excludeStage: ContractStage.Draft,
                                                  },
                                                  onChange: (newValue) => {
                                                    setFieldValue(`contractId`, newValue?.id);
                                                  },
                                                }}
                                                createFormPlacement='modal'
                                                onCreate={(newValue) => {
                                                  setFieldValue(`contractId`, newValue?.id);
                                                }}
                                              />

                                              <VehicleDamagesAutocompleteOrByRelatedEntities
                                                entities={undefined}
                                                entityIds={
                                                  values.content?.operation?.damageCostEvaluation
                                                    ?.vehicleDamageIds
                                                }
                                                searchFilters={{
                                                  vehicleId:
                                                    values.content?.operation?.damageCostEvaluation
                                                      ?.vehicleId,
                                                }}
                                                autocompleteProps={{
                                                  required: true,
                                                  textFieldProps: {
                                                    error: Boolean(
                                                      getIn(
                                                        errors,
                                                        "content.operation.damageCostEvaluation.vehicleDamageIds",
                                                      ),
                                                    ),
                                                    helperText: ValidationHelper.getErrorsAsString(
                                                      getIn(
                                                        errors,
                                                        "content.operation.damageCostEvaluation.vehicleDamageIds",
                                                      ),
                                                    ),
                                                  },
                                                }}
                                                onChange={(newValues) => {
                                                  setFieldValue(
                                                    "content.operation.damageCostEvaluation.vehicleDamageIds",
                                                    newValues?.map((x) => x.id),
                                                  );
                                                }}
                                              />
                                            </FormControl>
                                          )}

                                          <FormControl margin='dense' fullWidth>
                                            <CurrencyAutocomplete
                                              entity={
                                                values.content?.operation?.damageCostEvaluation
                                                  ?.currency
                                              }
                                              onChange={(newValue) =>
                                                setFieldValue(
                                                  "content.operation.damageCostEvaluation.currency",
                                                  newValue,
                                                )
                                              }
                                              textFieldProps={{
                                                label: "Currency",
                                                error: Boolean(
                                                  getIn(
                                                    errors,
                                                    "content.operation.damageCostEvaluation.currency",
                                                  ),
                                                ),
                                                helperText: ValidationHelper.getErrorsAsString(
                                                  getIn(
                                                    errors,
                                                    "content.operation.damageCostEvaluation.currency",
                                                  ),
                                                ),
                                              }}
                                            />
                                          </FormControl>

                                          {_.isString(
                                            getIn(errors, "content.operation.damageCostEvaluation"),
                                          ) && (
                                            <FormHelperText error>
                                              {getIn(
                                                errors,
                                                "content.operation.damageCostEvaluation",
                                              )}
                                            </FormHelperText>
                                          )}
                                        </Box>,
                                      )
                                      .elseif(
                                        values.content?.operation?.operationType ===
                                          TenantRequestOperationType.AccessoryCheck,
                                      )
                                      .then(
                                        <Box>
                                          <FormControl margin='dense' fullWidth>
                                            <VehicleAutocompleteOrCreate
                                              autocompleteProps={{
                                                required: true,
                                                disabled: !isCreate || !isCanEdit,
                                                entityId:
                                                  values.content?.operation?.accessoryCheck
                                                    ?.vehicleId,
                                                isPreload: isCreate,
                                                textFieldProps: {
                                                  error: Boolean(
                                                    getIn(
                                                      errors,
                                                      "content.operation.accessoryCheck.vehicleId",
                                                    ),
                                                  ),
                                                  helperText: ValidationHelper.getErrorsAsString(
                                                    getIn(
                                                      errors,
                                                      "content.operation.accessoryCheck.vehicleId",
                                                    ),
                                                  ),
                                                },
                                                onChange: (newValue) => {
                                                  setFieldValue(
                                                    "content.operation.accessoryCheck.vehicleId",
                                                    newValue?.id,
                                                  );
                                                },
                                              }}
                                              createFormPlacement='modal'
                                              onCreate={(newValue) => {
                                                setFieldValue(
                                                  "content.operation.accessoryCheck.vehicleId",
                                                  newValue?.id,
                                                );
                                              }}
                                            />
                                          </FormControl>

                                          {values.content?.operation?.accessoryCheck?.vehicleId && (
                                            <FormControl margin='dense' fullWidth>
                                              <ContractAutocompleteOrCreate
                                                autocompleteProps={{
                                                  disabled: defaultValues?.contractId != null,
                                                  entityId: values.contractId,
                                                  isPreload: isCreate,
                                                  searchFilters: {
                                                    vehicleId:
                                                      values.content?.operation?.accessoryCheck
                                                        ?.vehicleId || undefined,
                                                    excludeStage: ContractStage.Draft,
                                                  },
                                                  onChange: (newValue) => {
                                                    setFieldValue(`contractId`, newValue?.id);
                                                  },
                                                }}
                                                createFormPlacement='modal'
                                                onCreate={(newValue) => {
                                                  setFieldValue(`contractId`, newValue?.id);
                                                }}
                                              />
                                            </FormControl>
                                          )}

                                          {_.isString(
                                            getIn(errors, "content.operation.accessoryCheck"),
                                          ) && (
                                            <FormHelperText error>
                                              {getIn(errors, "content.operation.accessoryCheck")}
                                            </FormHelperText>
                                          )}
                                        </Box>,
                                      )
                                      .elseif(
                                        values.content?.operation?.operationType ===
                                          TenantRequestOperationType.RepairOperation,
                                      )
                                      .then(
                                        <Box>
                                          <FormControl margin='dense' fullWidth>
                                            <VehicleAutocompleteOrCreate
                                              autocompleteProps={{
                                                required: true,
                                                disabled: !isCreate || !isCanEdit,
                                                entityId:
                                                  values.content?.operation?.repairOperation
                                                    ?.vehicleId,
                                                isPreload: isCreate,
                                                textFieldProps: {
                                                  error: Boolean(
                                                    getIn(
                                                      errors,
                                                      "content.operation.repairOperation.vehicleId",
                                                    ),
                                                  ),
                                                  helperText: ValidationHelper.getErrorsAsString(
                                                    getIn(
                                                      errors,
                                                      "content.operation.repairOperation.vehicleId",
                                                    ),
                                                  ),
                                                },
                                                onChange: (newValue) => {
                                                  setFieldValue(
                                                    "content.operation.repairOperation.vehicleId",
                                                    newValue?.id,
                                                  );
                                                },
                                              }}
                                              createFormPlacement='modal'
                                              onCreate={(newValue) => {
                                                setFieldValue(
                                                  "content.operation.repairOperation.vehicleId",
                                                  newValue?.id,
                                                );
                                              }}
                                            />
                                          </FormControl>

                                          {values.content?.operation?.repairOperation
                                            ?.vehicleId && (
                                            <FormControl margin='dense' fullWidth>
                                              <ContractAutocompleteOrCreate
                                                autocompleteProps={{
                                                  disabled: defaultValues?.contractId != null,
                                                  entityId: values.contractId,
                                                  isPreload: isCreate,
                                                  searchFilters: {
                                                    vehicleId:
                                                      values.content?.operation?.repairOperation
                                                        ?.vehicleId || undefined,
                                                    excludeStage: ContractStage.Draft,
                                                  },
                                                  onChange: (newValue) => {
                                                    setFieldValue(`contractId`, newValue?.id);
                                                  },
                                                }}
                                                createFormPlacement='modal'
                                                onCreate={(newValue) => {
                                                  setFieldValue(`contractId`, newValue?.id);
                                                }}
                                              />
                                              <VehicleDamagesAutocompleteOrByRelatedEntities
                                                entities={undefined}
                                                entityIds={
                                                  values.content?.operation?.repairOperation
                                                    ?.vehicleDamageIds
                                                }
                                                searchFilters={{
                                                  vehicleId:
                                                    values.content?.operation?.repairOperation
                                                      ?.vehicleId,
                                                }}
                                                autocompleteProps={{
                                                  required: true,
                                                }}
                                                onChange={(newValues) => {
                                                  setFieldValue(
                                                    "content.operation.repairOperation.vehicleDamageIds",
                                                    newValues?.map((x) => x.id),
                                                  );
                                                }}
                                              />
                                            </FormControl>
                                          )}

                                          <FormControl margin='dense' fullWidth>
                                            <CurrencyAutocomplete
                                              entity={
                                                values.content?.operation?.repairOperation?.currency
                                              }
                                              onChange={(newValue) =>
                                                setFieldValue(
                                                  "content.operation.repairOperation.currency",
                                                  newValue,
                                                )
                                              }
                                              textFieldProps={{
                                                label: "Currency",
                                                error: Boolean(
                                                  getIn(
                                                    errors,
                                                    "content.operation.repairOperation.currency",
                                                  ),
                                                ),
                                                helperText: ValidationHelper.getErrorsAsString(
                                                  getIn(
                                                    errors,
                                                    "content.operation.repairOperation.currency",
                                                  ),
                                                ),
                                              }}
                                            />
                                          </FormControl>

                                          {_.isString(
                                            getIn(errors, "content.operation.repairOperation"),
                                          ) && (
                                            <FormHelperText error>
                                              {getIn(errors, "content.operation.repairOperation")}
                                            </FormHelperText>
                                          )}
                                        </Box>,
                                      )
                                      .elseif(
                                        values.content?.operation?.operationType ===
                                          TenantRequestOperationType.WheelOperation,
                                      )
                                      .then(
                                        <Box>
                                          <FormControl margin='dense' fullWidth>
                                            <VehicleAutocompleteOrCreate
                                              autocompleteProps={{
                                                required: true,
                                                disabled: !isCreate || !isCanEdit,
                                                entityId:
                                                  values.content?.operation?.wheelOperation
                                                    ?.vehicleId,
                                                isPreload: isCreate,
                                                textFieldProps: {
                                                  error: Boolean(
                                                    getIn(
                                                      errors,
                                                      "content.operation.wheelOperation.vehicleId",
                                                    ),
                                                  ),
                                                  helperText: ValidationHelper.getErrorsAsString(
                                                    getIn(
                                                      errors,
                                                      "content.operation.wheelOperation.vehicleId",
                                                    ),
                                                  ),
                                                },
                                                onChange: (newValue) => {
                                                  setFieldValue(
                                                    "content.operation.wheelOperation.vehicleId",
                                                    newValue?.id,
                                                  );
                                                },
                                              }}
                                              createFormPlacement='modal'
                                              onCreate={(newValue) => {
                                                setFieldValue(
                                                  "content.operation.wheelOperation.vehicleId",
                                                  newValue?.id,
                                                );
                                              }}
                                            />
                                          </FormControl>

                                          <FormControl margin='dense' fullWidth>
                                            <CurrencyAutocomplete
                                              entity={
                                                values.content?.operation?.wheelOperation?.currency
                                              }
                                              onChange={(newValue) =>
                                                setFieldValue(
                                                  "content.operation.wheelOperation.currency",
                                                  newValue,
                                                )
                                              }
                                              textFieldProps={{
                                                label: "Currency",
                                                error: Boolean(
                                                  getIn(
                                                    errors,
                                                    "content.operation.wheelOperation.currency",
                                                  ),
                                                ),
                                                helperText: ValidationHelper.getErrorsAsString(
                                                  getIn(
                                                    errors,
                                                    "content.operation.wheelOperation.currency",
                                                  ),
                                                ),
                                              }}
                                            />
                                          </FormControl>

                                          {_.isString(
                                            getIn(errors, "content.operation.wheelOperation"),
                                          ) && (
                                            <FormHelperText error>
                                              {getIn(errors, "content.operation.wheelOperation")}
                                            </FormHelperText>
                                          )}
                                        </Box>,
                                      )
                                      .elseif(
                                        values.content?.operation?.operationType ===
                                          TenantRequestOperationType.Wash,
                                      )
                                      .then(
                                        <Box>
                                          <FormControl margin='dense' fullWidth>
                                            <VehicleAutocompleteOrCreate
                                              autocompleteProps={{
                                                required: true,
                                                disabled: !isCreate || !isCanEdit,
                                                entityId:
                                                  values.content?.operation?.wash?.vehicleId,
                                                isPreload: isCreate,
                                                textFieldProps: {
                                                  error: Boolean(
                                                    getIn(
                                                      errors,
                                                      "content.operation.wash.vehicleId",
                                                    ),
                                                  ),
                                                  helperText: ValidationHelper.getErrorsAsString(
                                                    getIn(
                                                      errors,
                                                      "content.operation.wash.vehicleId",
                                                    ),
                                                  ),
                                                },
                                                onChange: (newValue) => {
                                                  setFieldValue(
                                                    "content.operation.wash.vehicleId",
                                                    newValue?.id,
                                                  );
                                                },
                                              }}
                                              createFormPlacement='modal'
                                              onCreate={(newValue) => {
                                                setFieldValue(
                                                  "content.operation.wash.vehicleId",
                                                  newValue?.id,
                                                );
                                              }}
                                            />
                                          </FormControl>

                                          <FormControl margin='dense' fullWidth>
                                            <CurrencyAutocomplete
                                              entity={values.content?.operation?.wash?.currency}
                                              onChange={(newValue) =>
                                                setFieldValue(
                                                  "content.operation.wash.currency",
                                                  newValue,
                                                )
                                              }
                                              textFieldProps={{
                                                label: "Currency",
                                                error: Boolean(
                                                  getIn(errors, "content.operation.wash.currency"),
                                                ),
                                                helperText: ValidationHelper.getErrorsAsString(
                                                  getIn(errors, "content.operation.wash.currency"),
                                                ),
                                              }}
                                            />
                                          </FormControl>

                                          {_.isString(getIn(errors, "content.operation.wash")) && (
                                            <FormHelperText error>
                                              {getIn(errors, "content.operation.wash")}
                                            </FormHelperText>
                                          )}
                                        </Box>,
                                      )
                                      .elseif(
                                        values.content?.operation?.operationType ===
                                          TenantRequestOperationType.Maintenance,
                                      )
                                      .then(
                                        <Box>
                                          <FormControl margin='dense' fullWidth>
                                            <VehicleAutocompleteOrCreate
                                              autocompleteProps={{
                                                required: true,
                                                disabled: !isCreate || !isCanEdit,
                                                entityId:
                                                  values.content?.operation?.maintenance?.vehicleId,
                                                isPreload: isCreate,
                                                textFieldProps: {
                                                  error: Boolean(
                                                    getIn(
                                                      errors,
                                                      "content.operation.maintenance.vehicleId",
                                                    ),
                                                  ),
                                                  helperText: ValidationHelper.getErrorsAsString(
                                                    getIn(
                                                      errors,
                                                      "content.operation.maintenance.vehicleId",
                                                    ),
                                                  ),
                                                },
                                                onChange: (newValue) => {
                                                  setFieldValue(
                                                    "content.operation.maintenance.vehicleId",
                                                    newValue?.id,
                                                  );
                                                },
                                              }}
                                              createFormPlacement='modal'
                                              onCreate={(newValue) => {
                                                setFieldValue(
                                                  "content.operation.maintenance.vehicleId",
                                                  newValue?.id,
                                                );
                                              }}
                                            />
                                          </FormControl>

                                          <FormControl margin='dense' fullWidth>
                                            <CurrencyAutocomplete
                                              entity={
                                                values.content?.operation?.maintenance?.currency
                                              }
                                              onChange={(newValue) =>
                                                setFieldValue(
                                                  "content.operation.maintenance.currency",
                                                  newValue,
                                                )
                                              }
                                              textFieldProps={{
                                                label: "Currency",
                                                error: Boolean(
                                                  getIn(
                                                    errors,
                                                    "content.operation.maintenance.currency",
                                                  ),
                                                ),
                                                helperText: ValidationHelper.getErrorsAsString(
                                                  getIn(
                                                    errors,
                                                    "content.operation.maintenance.currency",
                                                  ),
                                                ),
                                              }}
                                            />
                                          </FormControl>

                                          {_.isString(
                                            getIn(errors, "content.operation.maintenance"),
                                          ) && (
                                            <FormHelperText error>
                                              {getIn(errors, "content.operation.maintenance")}
                                            </FormHelperText>
                                          )}
                                        </Box>,
                                      )
                                      .else(
                                        <Box>
                                          <InlineApiEnumValue
                                            type='TenantRequestType'
                                            value={values.content?.type}
                                          />{" "}
                                          not supported yet!
                                        </Box>,
                                      )
                                      .render()}
                                  </Box>
                                )}

                                {values.receiverTenantId && (
                                  <Alert severity='warning' sx={{ my: 1 }}>
                                    By adding vehicle to the request you confirm sharing of vehicle
                                    and vehicle related data (history, damages, event log, etc) with
                                    company{" "}
                                    <strong>
                                      <TenantInline
                                        entity={undefined}
                                        entityId={values.receiverTenantId}
                                        withIcon={false}
                                      />
                                    </strong>
                                    .
                                  </Alert>
                                )}

                                {_.isString(getIn(errors, "content.operation")) && (
                                  <FormHelperText error>
                                    {getIn(errors, "content.operation")}
                                  </FormHelperText>
                                )}
                              </Box>
                            )}
                          </Stack>
                        </FoldableBlock>
                      )}

                    <FoldableBlock
                      defaultIsFolded={false}
                      trigger={{
                        label: (
                          <Typography component='span' variant='subtitle1'>
                            Data access settings
                          </Typography>
                        ),
                      }}
                    >
                      <Box sx={{ mt: 1 }}>
                        <ApiEnumMultiselect
                          type='DataGrantPermission'
                          values={values.dataGrantSettings?.permissions}
                          disabledValues={[DataGrantPermission.Read]}
                          required
                          disabled={isEdit || !isCanEdit}
                          fullWidth
                          label='Permissions'
                          helperText='Specify what receiver company can do with your data.'
                          error={Boolean(getIn(errors, "dataGrantSettings.permissions"))}
                          errorHelperText={ValidationHelper.getErrorsAsString(
                            getIn(errors, "dataGrantSettings.permissions"),
                          )}
                          onChange={(newValues) => {
                            setFieldValue("dataGrantSettings.permissions", newValues);
                          }}
                        />
                      </Box>
                    </FoldableBlock>

                    <FoldableBlock
                      defaultIsFolded={false}
                      trigger={{
                        label: (
                          <Typography component='span' variant='subtitle1'>
                            Other
                          </Typography>
                        ),
                      }}
                    >
                      <AppTextArea
                        error={Boolean(getIn(errors, "content.notes"))}
                        disabled={!isCanEdit}
                        fullWidth
                        minRows={2}
                        helperText={getIn(errors, "content.notes")}
                        mode='notes'
                        margin='dense'
                        name='content.notes'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.content?.notes || ""}
                        variant='outlined'
                      />

                      <FormControl fullWidth margin='none'>
                        <Typography component='div' variant='subtitle1'>
                          Attachments
                        </Typography>
                        <FileUploader
                          disabled={!isCanEdit}
                          multiple
                          maxFiles={100}
                          defaultFiles={FileItem.createManyFrom(
                            values.content?.uploadedAttachments ||
                              values.content?.attachments ||
                              values.content?.initialAttachments,
                          )}
                          onChange={(newFiles) => {
                            setFieldValue(
                              "content.attachments",
                              FileItem.toManyGeneralAttachmentInputDto(newFiles),
                            );
                            setFieldValue("content.uploadedAttachments", newFiles);
                          }}
                          onUploadStarted={() => {
                            setIsUploadingFiles(true);
                          }}
                          onUploadFinished={() => {
                            setIsUploadingFiles(false);
                          }}
                          onValidationStatusChange={(filesValidationStatus) => {
                            if (filesValidationStatus)
                              setFieldValue(
                                "content.isAttachmentFilesHaveErrors",
                                Object.values(filesValidationStatus).some((x) => x === false),
                              );
                          }}
                        />

                        {errors.content?.attachments && (
                          <FormHelperText error>
                            {ValidationHelper.getFormikErrorsAsString(errors.content?.attachments)}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </FoldableBlock>
                  </Stack>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <Stack direction={{ xxs: "column", md: "row" }} spacing={1}>
                    {customization?.secondaryActions}

                    <SplitDropdownButton
                      isSelectAndTriggerAction={false}
                      buttonProps={{
                        variant: "contained",
                        color: "primary",
                        type: "submit",
                        fullWidth: true,
                        loading: isSubmitting,
                        disabled: isUploadingFiles || values.content.isAttachmentFilesHaveErrors,
                      }}
                      options={[
                        {
                          content: "Save",
                          onClick: () => {
                            submitForm();
                          },
                        },
                        {
                          content: "Save & Send",
                          active: values.newStage?.newStage === TenantRequestStage.Pending,
                          onClick: () => {
                            const newValue: GeneralStageUpdateDtoOfTenantRequestStage = {
                              newStage: TenantRequestStage.Pending,
                            };
                            setFieldValue("newStage", newValue);
                            submitForm();
                          },
                        },
                      ]}
                    />
                  </Stack>
                </Stack>
              </form>
            );
          }}
        </Formik>
      </Stack>
    </BaseEntityCreateUpdate>
  );
}
