import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

// Figma name: Icon/Fill/inbox
export default forwardRef<SVGSVGElement, SvgIconProps>(function WarningTriangleIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.2565 4.16475C6.38325 3.9105 6.6435 3.75 6.92775 3.75H11.073C11.3573 3.75 11.6167 3.9105 11.7435 4.16475L13.7865 8.25H12C11.586 8.25 11.25 8.586 11.25 9V10.5C11.25 10.9148 10.914 11.25 10.5 11.25H7.5C7.086 11.25 6.75 10.9148 6.75 10.5V9C6.75 8.586 6.414 8.25 6 8.25H4.2135L6.2565 4.16475ZM15.5917 8.5065L13.0852 3.4935C12.702 2.727 11.931 2.25 11.073 2.25H6.927C6.069 2.25 5.29875 2.727 4.91475 3.4935L2.40825 8.5065C2.30475 8.7135 2.25 8.946 2.25 9.17775V13.5C2.25 14.7413 3.2595 15.75 4.5 15.75H13.5C14.7413 15.75 15.75 14.7413 15.75 13.5V9.17775C15.75 8.946 15.6952 8.7135 15.5917 8.5065Z'
        fill='#5A5A5A'
      />
      <mask
        id='mask0_6265_32821'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='14'
        height='14'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.2565 4.16475C6.38325 3.9105 6.6435 3.75 6.92775 3.75H11.073C11.3573 3.75 11.6167 3.9105 11.7435 4.16475L13.7865 8.25H12C11.586 8.25 11.25 8.586 11.25 9V10.5C11.25 10.9148 10.914 11.25 10.5 11.25H7.5C7.086 11.25 6.75 10.9148 6.75 10.5V9C6.75 8.586 6.414 8.25 6 8.25H4.2135L6.2565 4.16475ZM15.5917 8.5065L13.0852 3.4935C12.702 2.727 11.931 2.25 11.073 2.25H6.927C6.069 2.25 5.29875 2.727 4.91475 3.4935L2.40825 8.5065C2.30475 8.7135 2.25 8.946 2.25 9.17775V13.5C2.25 14.7413 3.2595 15.75 4.5 15.75H13.5C14.7413 15.75 15.75 14.7413 15.75 13.5V9.17775C15.75 8.946 15.6952 8.7135 15.5917 8.5065Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_6265_32821)'>
        <rect width='18' height='18' fill='#5A5A5A' />
      </g>
    </SvgIcon>
  );
});
