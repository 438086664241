import { Grid, Stack, SxProps, Theme, Typography } from "@mui/material";

import EntityDataBlock, {
  EntityDataBlockProps,
} from "@/common/components/EntityData/EntityDataBlock";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { NegotiationDto, NegotiationReferenceDto } from "@/core/api/generated";

interface BaseProps extends Pick<EntityDataBlockProps, "withDetailsToggle" | "isDetailsVisible"> {
  withLink?: boolean;
  briefDataProps?: {
    result?: boolean;
  };
  detailedDataProps?: {
    result?: boolean;
  };
  sx?: SxProps<Theme>;
}

type Props =
  | ({ variant: "full"; negotiation?: NegotiationDto } & BaseProps)
  | ({ variant: "reference"; negotiation?: NegotiationReferenceDto } & BaseProps);

const defaultBriefDataProps: Props["briefDataProps"] = {
  result: true,
};
const defaultDetailedDataProps: Props["detailedDataProps"] = {
  result: true,
};

/** Entity info as block element. */
export default function NegotiationBlock({
  variant,
  negotiation,
  withLink,
  briefDataProps = defaultBriefDataProps,
  detailedDataProps = defaultDetailedDataProps,
  sx,
  ...entityDataBlockProps
}: Props) {
  briefDataProps = { ...defaultBriefDataProps, ...briefDataProps };
  detailedDataProps = {
    ...defaultDetailedDataProps,
    ...detailedDataProps,
  };

  const fullDto = negotiation as NegotiationDto | undefined;
  // const refDto = negotiation as NegotiationReferenceDto | undefined;

  if (!negotiation) {
    return null;
  }

  return (
    <EntityDataBlock
      sx={{
        flex: 1,
        ...sx,
      }}
      {...entityDataBlockProps}
      to={withLink ? ROUTE_PATH.NEGOTIATION_VIEW(negotiation.id) : undefined}
      title={
        <>
          <AppIcon of='negotiation' inText /> Negotiation
        </>
      }
      briefContent={
        <>
          <Typography component='div' variant='body1'>
            {negotiation.localNumber}{" "}
            <InlineApiEnumValue type='NegotiationType' value={negotiation.type} />{" "}
          </Typography>
          {fullDto?.status && (
            <Typography component='div' variant='body2'>
              <InlineApiEnumValue type='NegotiationStatus' value={fullDto?.status} />
            </Typography>
          )}
          {fullDto?.name && (
            <Typography component='div' variant='body2'>
              {fullDto.name}
            </Typography>
          )}
          {fullDto?.description && (
            <Typography component='div' variant='body2'>
              {fullDto.description}
            </Typography>
          )}
        </>
      }
      detailedContent={
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Number'
              >
                {negotiation.localNumber}
              </FieldValue>
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Type'
              >
                <InlineApiEnumValue type='NegotiationType' value={negotiation.type} />
              </FieldValue>
              {fullDto?.status && (
                <FieldValue
                  labelIcon={<AppIcon of='item' inText />}
                  withBorder={false}
                  size='compact'
                  valueAlight={{ horizontal: "end" }}
                  ellipsing={{ enabled: true }}
                  label='Type'
                >
                  <InlineApiEnumValue type='NegotiationStatus' value={fullDto.status} />
                </FieldValue>
              )}
            </Stack>
          </Grid>
          {/* <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue label='Customer name'>{fullDto.customer?.contact?.name}</FieldValue>
            </Stack>
          </Grid> */}
        </Grid>
      }
    />
  );
}
