import {
  Box,
  Chip,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import _ from "lodash";

import AppIcon from "@/common/components/Icons/AppIcon";
import { RepairSpecHelper } from "@/common/helpers/entity/repairSpec";
import { renderIf } from "@/common/helpers/render/renderIf";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { enumService } from "@/common/services/enum";
import { AutocompleteOptionType, BaseAutocompleteOption } from "@/common/ts/autocomplete";
import { apiClient } from "@/core/api/ApiClient";
import { RepairSpecDto, RepairSpecGetPaginatedDto } from "@/core/api/generated";

import GeneralPriceSummaryInline from "../../PriceSummary/GeneralPriceSummaryInline";
import AppTypography from "../../Text/AppTypography";
import TenantInline from "../Tenant/TenantInline";
import BaseEntitySearchAutocomplete, {
  BaseEntitySearchAutocompleteInheritableProps,
} from "../components/BaseEntitySearchAutocomplete";
import RepairSpecDetalizationInline from "./RepairSpecDetalizationInline";
import { getRepairSpecInlineText } from "./RepairSpecInline";
import RepairSpecViewModal from "./View/RepairSpecViewModal";

type RepairSpecAutocompleteOption = BaseAutocompleteOption<RepairSpecDto>;

export const getRepairSpecTitle = (data: RepairSpecDto): string => getRepairSpecInlineText(data);

const repairSpecToOption = (data: RepairSpecDto): RepairSpecAutocompleteOption => ({
  id: data.id!,
  title: getRepairSpecTitle(data),
  optionType: AutocompleteOptionType.Normal,
  data: data,
});

export interface RepairSpecAutocompleteProps
  extends BaseEntitySearchAutocompleteInheritableProps<RepairSpecDto> {
  searchFilters?: Partial<Omit<RepairSpecGetPaginatedDto, "search" | "includeIds">>;
}

export default function RepairSpecAutocomplete({
  searchFilters,

  isPreload = true,
  ...otherProps
}: RepairSpecAutocompleteProps) {
  const currentTenant = useCurrentTenant();

  return (
    <>
      <BaseEntitySearchAutocomplete
        sx={{ minWidth: 200, flex: 1 }}
        entityToOption={repairSpecToOption}
        isPreload={isPreload}
        request={{
          requestFunc: apiClient.repairSpecsApi.apiV1RepairSpecsGetPost,
          limit: 25,
          parameters: {
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            repairSpecGetPaginatedDto: {
              ...searchFilters,
            },
          },
          combineParameters: (params, newParams) => ({
            ...params,
            repairSpecGetPaginatedDto: {
              ...params.repairSpecGetPaginatedDto,
              ...newParams,
            },
          }),
          deps: [searchFilters],
        }}
        label='Repair spec'
        placeholder='Search for repair spec...'
        renderOption={(props, option) => {
          return (
            <ListItem {...props}>
              <ListItemIcon>
                {renderIf()
                  .if(option.optionType === AutocompleteOptionType.Normal)
                  .then(<AppIcon of='repairSpec' />)
                  .elseif(option.optionType === AutocompleteOptionType.DynamicCreateNew)
                  .then(<AppIcon of='add' />)
                  .render()}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant={
                      option.optionType === AutocompleteOptionType.DynamicCreateNew
                        ? "subtitle1"
                        : "body1"
                    }
                    component='div'
                  >
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                      <Box>{option.data?.name || option.title}</Box>

                      {!_.isNil(option.data?.matchMeta?.score) && (
                        <Box>
                          <Tooltip title='The bigger the match score the better the result suits your request.'>
                            <Typography variant='caption'>
                              <Chip
                                size='extraSmall'
                                variant='filled'
                                label={<>Match score: {option.data?.matchMeta?.score} / 100</>}
                              />
                            </Typography>
                          </Tooltip>
                        </Box>
                      )}
                    </Stack>
                  </Typography>
                }
                secondary={
                  option.data && (
                    <Box>
                      <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
                        {option.data?.partType?.name} - {option.data?.damageType?.name}
                      </AppTypography>
                      {option.data.detalization && (
                        <AppTypography
                          ellipsing={{ enabled: true }}
                          component='div'
                          variant='body2'
                        >
                          <RepairSpecDetalizationInline detalization={option.data.detalization} />
                        </AppTypography>
                      )}
                      <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
                        {`${enumService.getEnumValueName(
                          "RepairType",
                          option.data?.repairType,
                        )} repair`}
                      </AppTypography>
                      <AppTypography ellipsing={{ enabled: true }} component='div' variant='body2'>
                        <GeneralPriceSummaryInline
                          summary={{
                            currency: option.data?.currency,
                            subTotal: option.data?.subTotal,
                            discount: option.data?.discount,
                            tax: option.data?.tax,
                            total: option.data?.total,
                          }}
                        />
                      </AppTypography>

                      {RepairSpecHelper.isExternal({
                        tenantId: currentTenant?.id,
                        repairSpec: option.data,
                      }) && (
                        <Box>
                          <Chip
                            variant='outlined'
                            size='extraSmall'
                            label={
                              <>
                                <AppIcon of='external' inText /> External
                              </>
                            }
                          />{" "}
                          <Chip
                            variant='outlined'
                            size='extraSmall'
                            label={
                              <TenantInline
                                entityId={option.data.tenantId}
                                entity={undefined}
                                withTooltip
                              />
                            }
                          />
                        </Box>
                      )}
                    </Box>
                  )
                }
              />
            </ListItem>
          );
        }}
        renderEntityViewModal={(params) => (
          <RepairSpecViewModal
            {...params.dialogProps}
            viewProps={{
              repairSpec: params.entity,
            }}
          />
        )}
        {...otherProps}
      />
    </>
  );
}
