import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogProps,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { Formik, getIn } from "formik";
import _ from "lodash";
import moment from "moment";
import { useEffect } from "react";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import { DatetimeHelper } from "@/common/helpers/datetime";
import { NegotiationHelper } from "@/common/helpers/entity/negotiation";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppSelector, useAppThunkDispatch } from "@/common/hooks/redux";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useModalOpenStatus } from "@/common/hooks/useModalOpenStatus";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { NegotiationDto, UpdateNegotiationDto } from "@/core/api/generated";
import * as negotiationsSlice from "@/store/communication/negotiationsSlice";

import ApiEnumSelect from "../../Enum/ApiEnumSelect";
import GeneralValidationError from "../../Error/GeneralValidationError";
import AppTextArea from "../../Form/Input/AppTextArea";
import AppIcon from "../../Icons/AppIcon";
import AppModalTitle from "../../Modals/AppModalTitle";

export interface OwnProps {
  negotiation?: NegotiationDto;
}

type Props = OwnProps & DialogProps;

function NegotiationSettingsModal({ negotiation, ...dialogProps }: Props) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const openStatus = useModalOpenStatus(dialogProps);
  const thunkDispatch = useAppThunkDispatch();
  const allowedActions = useAppSelector(
    (x) => x.communication.negotiations.negotiationAllowedActionsMap[negotiation?.id || ""],
  );

  const canEdit = NegotiationHelper.canEditNegotiation(negotiation, allowedActions);
  const canEditDeadline = NegotiationHelper.canEditNegotiationDeadline(negotiation);

  useEffect(() => {
    if (negotiation?.id && openStatus.isOpening) {
      thunkDispatch(
        negotiationsSlice.getNegotiationAllowedActions({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          negotiationId: negotiation.id,
        }),
      );
    }
  }, [negotiation, allowedActions]);

  return (
    <Box>
      <Dialog {...dialogProps}>
        <AppModalTitle
          onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
        >
          Negotiation settings
        </AppModalTitle>
        <DialogContent>
          {!canEdit.value && canEdit.reason && (
            <FormHelperText sx={{ mb: 1 }}>{canEdit.reason}</FormHelperText>
          )}

          <Formik<UpdateNegotiationDto & BaseFormikValues & { isDeadlineEnabled: boolean }>
            enableReinitialize
            initialValues={{
              name: negotiation?.name || "",
              description: negotiation?.description || "",
              settings: negotiation?.settings || undefined,

              isDeadlineEnabled: !!negotiation?.settings?.deadlineAt,
              submit: "",
            }}
            validationSchema={Yup.object().shape({
              // name: Yup.string().required("Name is required"),
            })}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                await thunkDispatch(
                  negotiationsSlice.updateNegotiation({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    negotiationId: negotiation!.id!,
                    updateNegotiationDto: {
                      ..._.omit(values, ["isDeadlineEnabled", "submit"]),
                    },
                  }),
                );
                enqueueSnackbar("Negotiation updated.", { variant: "success" });
                dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown");

                if (mounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
              } catch (err: any) {
                if (mounted.current) {
                  ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Box>
                    <TextField
                      disabled={!canEdit.value}
                      error={Boolean(touched.name && errors.name)}
                      fullWidth
                      helperText={touched.name && errors.name}
                      label='Name'
                      margin='dense'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.name}
                      variant='outlined'
                    />

                    <AppTextArea
                      disabled={!canEdit.value}
                      error={Boolean(touched.description && errors.description)}
                      fullWidth
                      helperText={touched.description && errors.description}
                      mode='description'
                      margin='dense'
                      name='description'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.description}
                      variant='outlined'
                    />
                  </Box>

                  <Divider sx={{ my: 1 }} />

                  {/* Settings */}
                  <Box>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!canEdit.value}
                            checked={values.settings?.allowCreateNewProposal || false}
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue("settings.allowCreateNewProposal", e.target.checked)
                            }
                          />
                        }
                        label='Allow create new proposals'
                      />
                    </FormGroup>
                    <FormHelperText>{`Whether it's allowed to create new proposals manually.`}</FormHelperText>

                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!canEdit.value}
                            checked={values.settings?.allowReopen || false}
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue("settings.allowReopen", e.target.checked)
                            }
                          />
                        }
                        label='Allow reopen negotiation'
                      />
                    </FormGroup>
                    <FormHelperText>{`Whether negotiation can be reopened once resolved.`}</FormHelperText>

                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!canEdit.value}
                            checked={values.settings?.allowChangeProposalResponse || false}
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue(
                                "settings.allowChangeProposalResponse",
                                e.target.checked,
                              )
                            }
                          />
                        }
                        label='Allow change proposal response'
                      />
                    </FormGroup>
                    <FormHelperText>{`Whether it's allowed to change answer.`}</FormHelperText>

                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!canEdit.value}
                            checked={values.settings?.requireProposalResolution || false}
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue("settings.requireProposalResolution", e.target.checked)
                            }
                          />
                        }
                        label='Require proposal resolution'
                      />
                    </FormGroup>
                    <FormHelperText>
                      Whether all proposals must be resolved before opening a new one.
                    </FormHelperText>

                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!canEdit.value}
                            checked={values.settings?.requireProposalConsensus || false}
                            onBlur={handleBlur}
                            onChange={(e) =>
                              setFieldValue("settings.requireProposalConsensus", e.target.checked)
                            }
                          />
                        }
                        label='Require proposal consensus'
                      />
                    </FormGroup>
                    <FormHelperText>
                      Everyone should give the same answer in order to resolve the proposal.
                    </FormHelperText>

                    <FormGroup sx={{ mt: 0.5 }}>
                      <FormControlLabel
                        control={
                          <Switch
                            disabled={!canEdit.value || !canEditDeadline.value}
                            checked={values.isDeadlineEnabled}
                            onChange={(e) => {
                              const newValue = e.target.checked;
                              setFieldValue("isDeadlineEnabled", newValue);
                              if (!newValue) {
                                setFieldValue("settings.deadlineAt", undefined);
                                setFieldValue("settings.autoResponseTypeOnDeadline", undefined);
                              }
                            }}
                          />
                        }
                        label='Deadline'
                      />
                    </FormGroup>
                    {values.isDeadlineEnabled && (
                      <Box>
                        <FormControl
                          fullWidth
                          margin='dense'
                          error={Boolean(getIn(errors, `settings.deadlineAt`))}
                        >
                          <MobileDateTimePicker
                            ampm={false}
                            disabled={!canEdit.value || !canEditDeadline.value}
                            label='Deadline date'
                            value={
                              (values.settings?.deadlineAt &&
                                moment(values.settings?.deadlineAt)) ||
                              null
                            }
                            format={DATETIME_FORMATS.DISPLAY_DATETIME}
                            onChange={(newValue) => {
                              setFieldValue("settings.deadlineAt", newValue);
                            }}
                            slots={{
                              textField: (params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                      <IconButton
                                        onClick={() =>
                                          setFieldValue("settings.deadlineAt", undefined)
                                        }
                                      >
                                        <AppIcon of='clear' color='inherit' />
                                      </IconButton>
                                    ),
                                  }}
                                  helperText={DatetimeHelper.humanizeDateRangeDurationFromNow(
                                    values.settings?.deadlineAt,
                                    { isSuffix: true },
                                  )}
                                />
                              ),
                            }}
                          />
                          <FormHelperText>{getIn(errors, `settings.deadlineAt`)}</FormHelperText>
                        </FormControl>

                        <FormControl
                          fullWidth
                          margin='dense'
                          error={Boolean(getIn(errors, `settings.autoResponseTypeOnDeadline`))}
                        >
                          <InputLabel>On deadline date auto-respond with</InputLabel>
                          <ApiEnumSelect
                            type='ApprovalResponseType'
                            value={values.settings?.autoResponseTypeOnDeadline}
                            onChange={(newValue) =>
                              setFieldValue(
                                "settings.autoResponseTypeOnDeadline",
                                newValue || undefined,
                              )
                            }
                            selectProps={{
                              label: "On deadline date auto-respond with",
                              disabled: !canEdit.value || !canEditDeadline.value,
                            }}
                          />
                          <FormHelperText>
                            {getIn(errors, `settings.autoResponseTypeOnDeadline`)}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    )}
                    {!canEditDeadline.value && canEditDeadline.reason && (
                      <FormHelperText>{canEditDeadline.reason}</FormHelperText>
                    )}
                  </Box>

                  <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                  <Stack direction='row' spacing={1} sx={{ justifyContent: "flex-end", mt: 1 }}>
                    <Button
                      variant='outlined'
                      color='text'
                      onClick={(e) =>
                        dialogProps.onClose && dialogProps.onClose(e, "escapeKeyDown")
                      }
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      variant='contained'
                      color='primary'
                      disabled={!negotiation || !canEdit.value}
                      loading={isSubmitting}
                      type='submit'
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default NegotiationSettingsModal;
