import { Box, Stack } from "@mui/material";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AppIconButton from "@/common/components/Button/AppIconButton";
import AppIcon from "@/common/components/Icons/AppIcon";
import TextLineSkeleton from "@/common/components/Skeleton/TextLineSkeleton";
import AdminTenantSummaryWidget from "@/common/components/Widgets/Business/TenantSummaryWidget";
import { widgetEventEmitter } from "@/common/eventEmmiters/widgetEventEmitter";
import { useUserProfile } from "@/common/hooks/useUserProfile";

export default function CompanyDashboardPage() {
  const profile = useUserProfile();

  return (
    <ViewPageLayout
      goBackLayoutProps={{ displayProps: { breadcrumbs: false } }}
      header={
        <SimpleViewPageHeader
          title={
            <Stack direction='row' sx={{ justifyContent: "space-between", alignItems: "center" }}>
              <Box>
                {!profile && <TextLineSkeleton />}
                {profile && (
                  <>Hi, {`${profile?.personName?.name || profile?.email || "User"} 👋`}</>
                )}
              </Box>

              <Box>
                <AppIconButton
                  variant='text'
                  color='inherit'
                  size='small'
                  tooltipProps={{ title: "Recompute all data" }}
                  onClick={() => {
                    widgetEventEmitter.emit("recomputeAllDataRequested", undefined);
                  }}
                >
                  <AppIcon of='recompute' />
                </AppIconButton>
              </Box>
            </Stack>
          }
        />
      }
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(400px, 700px))",
          gap: 2,
        }}
      >
        <AdminTenantSummaryWidget />
      </Box>
    </ViewPageLayout>
  );
}
