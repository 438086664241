import {
  Box,
  Button,
  DialogContentText,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  Tooltip,
  Typography,
} from "@mui/material";
import { FormikErrors, useFormikContext } from "formik";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactZoomPanPinchRef } from "react-zoom-pan-pinch";

import { FileItem } from "@/common/fileItem";
import { TextHelper } from "@/common/helpers/text";
import { useLatestRef } from "@/common/hooks/ref/useLatestRef";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import {
  VehicleArea,
  VehiclePartTypeDto,
  VehicleProjection,
  VehicleVisualModelDto,
  VehicleVisualModelImageDto,
} from "@/core/api/generated";

import AppTooltip from "../AppTooltip";
import DataTabular from "../DataTabular/DataTabular";
import {
  CreateUpdateDDFormikType,
  ExtendedDamageType,
  VisualModelPointClickedInfo,
} from "../Entity/DamageDetection/DamageDetectionCreateUpdate";
import DamageTypeAutocompleteV2 from "../Entity/ReferenceData/DamageType/DamageTypeAutocompleteV2";
import { VehiclePartTypeAutocompleteProps } from "../Entity/ReferenceData/VehiclePartType/VehiclePartTypeAutocomplete";
import VehiclePartTypeAutocompleteV2 from "../Entity/ReferenceData/VehiclePartType/VehiclePartTypeAutocompleteV2";
import VehicleDamageLink from "../Entity/VehicleDamage/VehicleDamageLink";
import InlineApiEnumValue from "../Enum/InlineApiEnumValue";
import FileListView from "../Files/FileListView";
import FileUploader from "../Files/FileUploader";
import AppTextArea from "../Form/Input/AppTextArea";
import AppIcon from "../Icons/AppIcon";
import CompoundIcon from "../Icons/CompoundIcon";
import MenuWithTrigger from "../Menu/MenuWithTrigger";
import ConfirmationModal from "../Modals/ConfirmationModal";
import ConfirmationModalWithTrigger from "../Modals/ConfirmationModalWithTrigger";
import AppTypography from "../Text/AppTypography";
import { DamagePointInfoModal } from "./DamagePointInfoModal";
import DamagePointsByVisualModelViewerModal from "./DamagePointsByVisualModelViewerModal";
import DamagePointsVisualizer from "./DamagePointsVisualizer";
import ZoomableBlock from "./ZoomableBlock";

interface Props {
  containerSx?: SxProps<Theme>;
  damages: Array<ExtendedDamageType>;
  oldDamages: Array<ExtendedDamageType>;
  isCreateModalConfirmEnabled?: boolean;
  isEditingModalConfirmEnabled?: boolean;
  isDisableDelete?: boolean;
  isDisablePartTypeEdit?: boolean;
  isDisableDamageTypeEdit?: boolean;
  isPreload?: boolean;
  updateDamageItems: (items: Array<ExtendedDamageType>) => void;
  visualModel: VehicleVisualModelDto | undefined;
  errors: FormikErrors<CreateUpdateDDFormikType>;
}

type AdditionalPartTypesFilteringInfoType = VehiclePartTypeAutocompleteProps["searchFilters"];

export const DamagePointsEditor = ({
  containerSx,
  damages,
  oldDamages,
  isCreateModalConfirmEnabled,
  isEditingModalConfirmEnabled,
  isDisableDelete,
  isDisablePartTypeEdit,
  isDisableDamageTypeEdit,
  isPreload,
  updateDamageItems,
  visualModel,
  errors,
}: Props) => {
  const { enqueueSnackbar } = useAppSnackbar();
  const { setFieldValue } = useFormikContext<CreateUpdateDDFormikType>();

  const damagesRef = useLatestRef(damages);

  const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
  const [isViewingModalOpened, setIsViewingModalOpened] = useState(false);
  const [newPointInfo, setNewPointInfo] = useState<VisualModelPointClickedInfo | null>(null);
  const [viewingPointInfo, setViewingPointInfo] = useState<ExtendedDamageType | null>(null);
  const [__, setEditingPointInfo] = useState<ExtendedDamageType | null>(null);
  const [isAttachmentFilesUploading, setIsAttachmentFilesUploading] = useState(false);
  const [isAttachmentFilesHaveErrors, setIsAttachmentFilesHaveErrors] = useState(false);
  const [additionalPartTypesFilteringInfo, setAdditionalPartTypesFilteringInfo] = useState<
    AdditionalPartTypesFilteringInfoType | undefined
  >(undefined);
  const [editingDamageItem, setEditingDamageItem] = useState<ExtendedDamageType | null>(null);
  const [newDamageItem, setNewDamageItem] = useState<ExtendedDamageType | null>(null);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [isOtherVisualModelsModalOpen, setIsOtherVisualModelsModalOpen] = useState<boolean>(false);
  const [damageOnOtherVisualModel, setDamageOnOtherVisualModel] = useState<
    Array<ExtendedDamageType>
  >([]);
  const [selectedPerspective, setSelectedPerspective] = useState<
    VehicleVisualModelImageDto | undefined | null
  >(null);
  const transformComponentRef = useRef<ReactZoomPanPinchRef | null>(null);

  const areaComputed =
    additionalPartTypesFilteringInfo?.area ||
    editingDamageItem?.area ||
    newPointInfo?.area ||
    selectedPerspective?.area;
  const projectionComputed =
    (areaComputed === VehicleArea.Exterior
      ? editingDamageItem?.projection || selectedPerspective?.projection
      : undefined) ||
    (areaComputed === VehicleArea.Interior && undefined) ||
    undefined;

  const onCreatePartTypeChanged = useCallback(
    (newValue: VehiclePartTypeDto | null | undefined) => {
      setNewDamageItem({
        ...newDamageItem,
        partTypeId: newValue?.id,
        savedPartType: newValue,
      });
    },
    [newDamageItem],
  );

  useEffect(() => {
    setSelectedPerspective(visualModel?.images && visualModel?.images[0]);
  }, [visualModel]);

  const imageUrl = selectedPerspective?.file?.url;

  useEffect(() => {
    if (oldDamages.length && visualModel?.id) {
      setDamageOnOtherVisualModel(oldDamages.filter((d) => d.visualModel?.id !== visualModel!.id!));
    }
  }, [oldDamages]);

  return (
    <Stack spacing={1}>
      <Stack direction='row' spacing={1} sx={{ mb: 1 }}>
        <Box>
          <Button
            variant='outlined'
            color='text'
            size='small'
            startIcon={<AppIcon of='add' />}
            onClick={() => {
              setNewPointInfo(null);
              setNewDamageItem(null);
              setAdditionalPartTypesFilteringInfo({
                area: VehicleArea.Interior,
                projection: undefined,
              });
              setIsCreateModalOpened(true);
            }}
          >
            Add interior damage
          </Button>
        </Box>

        <Box>
          <Button
            variant='outlined'
            color='text'
            size='small'
            startIcon={<AppIcon of='add' />}
            onClick={() => {
              setNewPointInfo(null);
              setNewDamageItem(null);
              setAdditionalPartTypesFilteringInfo({
                area: VehicleArea.Exterior,
                projection: VehicleProjection.Top,
              });
              setIsCreateModalOpened(true);
            }}
          >
            Add damage
          </Button>
        </Box>
      </Stack>

      {/* Selected visual model image */}
      <Stack direction='row' spacing={1}>
        <ZoomableBlock wrapperRef={transformComponentRef}>
          <DamagePointsVisualizer
            pointsInfo={[
              ...oldDamages.filter((d) => d.visualModel?.id === visualModel?.id),
              ...damages,
            ].filter((item) => {
              return item.projection === selectedPerspective?.projection;
            })}
            imageUrl={imageUrl}
            containerSx={containerSx}
            onNewPointClicked={(pointInfo) => {
              console.log("onNewPointClicked.", pointInfo);
              setNewPointInfo(pointInfo);
              setNewDamageItem(pointInfo);
              setAdditionalPartTypesFilteringInfo({
                category: pointInfo.svgPathMetadata?.vehiclePartCategory,
                types: pointInfo.svgPathMetadata?.vehiclePartTypes || undefined,
                descriptors: pointInfo.svgPathMetadata?.vehiclePartDescriptors || undefined,
              });
              setIsCreateModalOpened(true);
            }}
            onPointClicked={(pointInfo) => {
              if (pointInfo.isOld) {
                setViewingPointInfo(pointInfo);
                setIsViewingModalOpened(true);
              } else {
                setEditingPointInfo(pointInfo);
                const item =
                  damages.find((el) => {
                    return pointInfo?.id === el?.id;
                  }) || {};
                setEditingDamageItem(item);
                setAdditionalPartTypesFilteringInfo({
                  category: pointInfo?.svgPathMetadata?.vehiclePartCategory,
                  types: pointInfo?.svgPathMetadata?.vehiclePartTypes || undefined,
                  descriptors: pointInfo?.svgPathMetadata?.vehiclePartDescriptors || undefined,
                });
                setIsEditModalOpened(true);
              }
            }}
            onPointMoved={(pointInfo) => {
              const isPointOnSameVehiclePartType =
                pointInfo.svgPathMetadata.vehiclePartTypes![0] ===
                pointInfo.initialPointInfo.svgPathMetadata!.vehiclePartTypes![0];
              if (isPointOnSameVehiclePartType) {
                const newDamages = damages.map((el) => {
                  if (pointInfo.id === el.id) {
                    el.point = pointInfo.point;
                  }
                  return el;
                });
                setFieldValue("items", newDamages);
                return;
              }
              // if dragged point to different vehicle part - show modal which asks for new part details
              const newDamages = damages.filter((el) => pointInfo.id !== el.id && el);
              setFieldValue("items", newDamages);
              setNewPointInfo(pointInfo);
              setNewDamageItem(pointInfo);
              setAdditionalPartTypesFilteringInfo({
                category: pointInfo?.svgPathMetadata?.vehiclePartCategory,
                types: pointInfo?.svgPathMetadata?.vehiclePartTypes || undefined,
                descriptors: pointInfo?.svgPathMetadata?.vehiclePartDescriptors || undefined,
              });
              setIsCreateModalOpened(true);
              enqueueSnackbar(
                "You moved point to different vehicle part. Please confirm new part.",
                {
                  variant: "info",
                },
              );
            }}
          />
        </ZoomableBlock>
      </Stack>

      {/* Visual model images */}
      <Stack direction='row' flexWrap={"wrap"} spacing={1} sx={{ mb: 1 }}>
        {visualModel?.images?.map((img, index) => {
          const file = FileItem.createFrom(img);
          return (
            <Box
              sx={{
                cursor: "pointer",
                "& svg": {
                  pointerEvents: "none",
                },
                display: "flex",
              }}
              key={index}
              onClick={() => {
                transformComponentRef?.current?.resetTransform();
                setSelectedPerspective(img);
              }}
            >
              <DamagePointsVisualizer
                pointCircleRadius={2}
                pointsInfo={damages
                  .filter((item) => {
                    return item.projection === img?.projection;
                  })
                  .map((item) => {
                    return item as ExtendedDamageType;
                  })}
                containerSx={{
                  width: { xxs: "100px", md: "150px" },
                  height: { xxs: "100px", md: "150px" },
                  overflow: "hidden",
                }}
                imageUrl={file?.file?.url}
              />
            </Box>
          );
        })}
      </Stack>

      <FormControl margin='dense' fullWidth error={Boolean(errors.items)}>
        <FormHelperText>{errors.items?.toString()}</FormHelperText>
      </FormControl>

      {damageOnOtherVisualModel.length ? (
        <Box>
          <Button
            variant='outlined'
            color='text'
            size='small'
            onClick={() => setIsOtherVisualModelsModalOpen(true)}
          >
            View {damageOnOtherVisualModel.length} other{" "}
            {TextHelper.pluralize("damage", damageOnOtherVisualModel.length)} that were marked on
            different visual models.
          </Button>
        </Box>
      ) : (
        <></>
      )}

      {/* List of added damages */}
      <DataTabular
        columns={[
          {
            field: "partType",
            title: "Part type",
            flex: 1,
            renderCell: (item) => (
              <Stack direction='row' spacing={1} sx={{ alignItems: "center" }}>
                <Box>{item.savedPartType?.name}</Box>

                <Typography component='div' variant='body2' color='text.secondary'>
                  (<InlineApiEnumValue type='VehicleArea' value={item.area} /> /{" "}
                  {(item.projection && (
                    <InlineApiEnumValue type='VehicleProjection' value={item.projection} />
                  )) ||
                    "-"}
                  )
                </Typography>
              </Stack>
            ),
          },
          {
            field: "damageType",
            title: "Damage type",
            flex: 1,
            renderCell: (item) => <>{item?.savedDamageType?.name}</>,
          },
          {
            field: "damage.id",
            title: "Damage",
            flex: 1,
            renderCell: (item) =>
              item.damage ? (
                <VehicleDamageLink
                  vehicleId={item.damage?.vehicleId}
                  entity={item.damage}
                  withIcon
                />
              ) : (
                "-"
              ),
          },
          {
            field: "notes",
            title: "Notes",
            width: 200,
            renderCell: (item) => (
              <AppTooltip title={item.notes || ""}>
                <AppTypography ellipsing={{ enabled: true }}>{item.notes}</AppTypography>
              </AppTooltip>
            ),
          },
          {
            field: "attachments",
            title: "Attachments",
            width: 150,
            renderCell: (item) => (
              <>
                {item.attachments && item.attachments?.length !== 0 && (
                  <FileListView
                    files={FileItem.createManyFrom(item.initialAttachments)}
                    maxVisibleFiles={2}
                    itemWidth={45}
                    itemActions={() => ({
                      enabled: true,
                      click: true,
                      download: true,
                    })}
                  />
                )}
              </>
            ),
          },
        ]}
        rows={damages}
        getRowId={(item) => item.id!}
        borderVariant='bordered'
        rowHeight={65}
        isColumnSelectorEnabled={false}
        isColumnMenuEnabled={false}
        isPaginationEnabled={false}
        renderRowAction={({ item }) => (
          <MenuWithTrigger
            trigger={
              <IconButton>
                <AppIcon of='moreVert' />
              </IconButton>
            }
          >
            {({ handleClose }) => [
              <MenuItem
                key='edit'
                onClick={() => {
                  setEditingDamageItem(item);
                  setIsEditModalOpened(true);
                  handleClose();
                }}
              >
                <ListItemIcon>
                  <AppIcon of='edit' fontSize='small' />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>,

              <ConfirmationModalWithTrigger
                key='delete'
                trigger={
                  <MenuItem disabled={isDisableDelete}>
                    <ListItemIcon>
                      <AppIcon of='delete' fontSize='small' />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                }
                title='Delete damage?'
                body={
                  <DialogContentText>
                    <Box>{`You're going to delete the damage.`}</Box>
                    {item.damage && (
                      <Box>
                        <strong>
                          {`NB: This damage won't be automatically deleted from the vehicle history.`}{" "}
                          If you wish to do so, you should do it manually on the damage page{" "}
                          <VehicleDamageLink
                            vehicleId={item.damage.vehicleId}
                            entity={item.damage}
                            entityId={undefined}
                          />
                          .
                        </strong>
                      </Box>
                    )}
                  </DialogContentText>
                }
                onConfirm={() => {
                  updateDamageItems(
                    damagesRef.current.filter((x) => {
                      return x.id !== item.id;
                    }),
                  );
                  handleClose();
                }}
              />,
            ]}
          </MenuWithTrigger>
        )}
      />

      {/* Add damage modal */}
      <ConfirmationModal
        keepMounted={false}
        title='Add damage'
        fullWidth
        maxWidth='md'
        open={isCreateModalOpened}
        onClose={() => {
          setIsCreateModalOpened(false);
        }}
        onCancel={() => {
          setNewDamageItem(null);
          setIsCreateModalOpened(false);
        }}
        onConfirm={() => {
          const newItem = {
            ...newDamageItem,
            id: newPointInfo?.id || _.uniqueId("frontend-temp-id-"),
            area: areaComputed,
            projection: projectionComputed,
            point: newPointInfo?.point,
            isDraggable: true,
          };
          updateDamageItems([...(damagesRef.current || []), newItem]);
          setNewDamageItem(null);
          setIsCreateModalOpened(false);
        }}
        isConfirmEnabled={
          !!newDamageItem?.partTypeId &&
          !!newDamageItem?.damageTypeId &&
          !isAttachmentFilesUploading &&
          !isAttachmentFilesHaveErrors &&
          isCreateModalConfirmEnabled
        }
        body={
          <Box sx={{ pt: 1 }}>
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item xxs={12} md={5}>
                <FormControl margin='none' fullWidth>
                  <VehiclePartTypeAutocompleteV2
                    entityId={newDamageItem?.partTypeId}
                    isPreload={isPreload}
                    isAutoSelectSingleOption
                    size='small'
                    required
                    metadata={{
                      area: areaComputed,
                      projection: projectionComputed,
                    }}
                    searchFilters={{
                      area: areaComputed,
                      projection: projectionComputed,
                      category: additionalPartTypesFilteringInfo?.category,
                      types: additionalPartTypesFilteringInfo?.types,
                      descriptors: additionalPartTypesFilteringInfo?.descriptors,
                      isEnabledForDamageDetection: true,
                    }}
                    onChange={onCreatePartTypeChanged}
                    onPaginatedLoaded={(paginated) => {
                      if (paginated?.pagination?.totalCount === 0) {
                        setAdditionalPartTypesFilteringInfo(undefined);
                      }
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xxs={12} md={5}>
                <FormControl margin='none' fullWidth>
                  <DamageTypeAutocompleteV2
                    entityId={newDamageItem?.damageTypeId}
                    isPreload={isPreload && !!newDamageItem?.partTypeId}
                    isAutoSelectSingleOption
                    size='small'
                    required
                    disabled={!newDamageItem?.partTypeId}
                    searchFilters={{
                      vehiclePartTypeId: newDamageItem?.partTypeId || undefined,
                    }}
                    onChange={async (newValue) => {
                      setNewDamageItem({
                        ...newDamageItem,
                        damageTypeId: newValue?.id,
                        savedDamageType: newValue,
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xxs={12} md={2}>
                {/* Controls */}
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='flex-end'
                  spacing={0.5}
                  sx={{ width: "100%" }}
                >
                  {newDamageItem?.notes === undefined && (
                    <Tooltip title='Add notes'>
                      <IconButton
                        onClick={() =>
                          setNewDamageItem({
                            ...newDamageItem,
                            notes: "",
                          })
                        }
                      >
                        <CompoundIcon
                          mainIcon={<AppIcon of='notes' />}
                          badgeIcon={<AppIcon of='add' />}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {newDamageItem?.notes !== undefined && (
                    <Tooltip title='Remove notes'>
                      <IconButton
                        onClick={() =>
                          setNewDamageItem({
                            ...newDamageItem,
                            notes: undefined,
                          })
                        }
                      >
                        <CompoundIcon
                          mainIcon={<AppIcon of='notes' />}
                          badgeIcon={<AppIcon of='remove' />}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  {newDamageItem?.attachments === undefined && (
                    <Tooltip title='Add attachments'>
                      <IconButton
                        onClick={() =>
                          setNewDamageItem({
                            ...newDamageItem,
                            attachments: [],
                          })
                        }
                      >
                        <CompoundIcon
                          mainIcon={<AppIcon of='attachments' />}
                          badgeIcon={<AppIcon of='add' />}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {newDamageItem?.attachments !== undefined && (
                    <Tooltip title='Remove attachments'>
                      <IconButton
                        onClick={() =>
                          setNewDamageItem({
                            ...newDamageItem,
                            attachments: undefined,
                          })
                        }
                      >
                        <CompoundIcon
                          mainIcon={<AppIcon of='attachments' />}
                          badgeIcon={<AppIcon of='remove' />}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              </Grid>
            </Grid>

            {newDamageItem?.notes !== undefined && (
              <AppTextArea
                fullWidth
                mode='notes'
                margin='normal'
                onChange={(e) => {
                  setNewDamageItem({
                    ...newDamageItem,
                    notes: e.target.value,
                  });
                }}
                value={newDamageItem?.notes}
                variant='outlined'
              />
            )}

            {newDamageItem?.attachments !== undefined && (
              <FormControl fullWidth margin='dense'>
                {/* <FormLabel component='legend'>Attachments</FormLabel> */}
                <FileUploader
                  multiple
                  onChange={(newFiles) => {
                    setNewDamageItem({
                      ...newDamageItem,
                      attachments: FileItem.toManyGeneralAttachmentInputDto(newFiles) || undefined,
                      initialAttachments:
                        FileItem.toManyGeneralAttachmentDto(newFiles) || undefined,
                    });
                  }}
                  onUploadStarted={() => {
                    setIsAttachmentFilesUploading(true);
                  }}
                  onUploadFinished={() => {
                    setIsAttachmentFilesUploading(false);
                  }}
                  onValidationStatusChange={(filesValidationStatus) => {
                    if (filesValidationStatus)
                      setIsAttachmentFilesHaveErrors(
                        Object.values(filesValidationStatus).some((x) => x === false),
                      );
                  }}
                />
              </FormControl>
            )}
          </Box>
        }
      />

      {/* Edit damage */}
      <ConfirmationModal
        keepMounted={false}
        title='Edit damage'
        fullWidth
        maxWidth='md'
        body={
          <>
            {!editingDamageItem && <></>}
            {editingDamageItem && (
              <Box sx={{ pt: 1 }}>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  <Grid item xxs={12} md={5} sx={{ order: 0 }}>
                    <FormControl margin='none' fullWidth>
                      <VehiclePartTypeAutocompleteV2
                        entityId={editingDamageItem.partTypeId}
                        isPreload={isPreload}
                        isAutoSelectSingleOption
                        size='small'
                        required
                        disabled={isDisablePartTypeEdit}
                        metadata={{
                          area: areaComputed,
                          projection: projectionComputed,
                        }}
                        searchFilters={{
                          area: areaComputed,
                          projection: projectionComputed,
                          category: editingDamageItem?.savedPartType?.category,
                          type: editingDamageItem?.savedPartType?.type || undefined,
                          descriptor: editingDamageItem?.savedPartType?.descriptor || undefined,
                          isEnabledForDamageDetection: true,
                        }}
                        onChange={async (newValue) => {
                          setEditingDamageItem({
                            ...editingDamageItem,
                            partTypeId: newValue?.id,
                            savedPartType: newValue,
                          });
                        }}
                        onPaginatedLoaded={(paginated) => {
                          if (paginated?.pagination?.totalCount === 0) {
                            setAdditionalPartTypesFilteringInfo(undefined);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xxs={12} md={5}>
                    <FormControl margin='none' fullWidth>
                      <DamageTypeAutocompleteV2
                        entityId={editingDamageItem.damageTypeId}
                        isPreload={isPreload}
                        isAutoSelectSingleOption
                        size='small'
                        required
                        disabled={!editingDamageItem.partTypeId || isDisableDamageTypeEdit}
                        searchFilters={{
                          vehiclePartTypeId: editingDamageItem.partTypeId || undefined,
                        }}
                        onChange={async (newValue) => {
                          setEditingDamageItem({
                            ...editingDamageItem,
                            damageTypeId: newValue?.id,
                            savedDamageType: newValue,
                          });
                        }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xxs={12} md={2}>
                    {/* Controls */}
                    <Stack
                      direction='row'
                      alignItems='center'
                      justifyContent='flex-end'
                      spacing={0.5}
                      sx={{ width: "100%" }}
                    >
                      {editingDamageItem.notes === undefined && (
                        <Tooltip title='Add notes'>
                          <IconButton
                            onClick={() => {
                              setEditingDamageItem({
                                ...editingDamageItem,
                                notes: "",
                              });
                            }}
                          >
                            <CompoundIcon
                              mainIcon={<AppIcon of='notes' />}
                              badgeIcon={<AppIcon of='add' />}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {editingDamageItem.notes !== undefined && (
                        <Tooltip title='Remove notes'>
                          <IconButton
                            onClick={() => {
                              setEditingDamageItem({
                                ...editingDamageItem,
                                notes: undefined,
                              });
                            }}
                          >
                            <CompoundIcon
                              mainIcon={<AppIcon of='notes' />}
                              badgeIcon={<AppIcon of='remove' />}
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      {editingDamageItem.attachments === undefined && (
                        <Tooltip title='Add attachments'>
                          <IconButton
                            onClick={() => {
                              setEditingDamageItem({
                                ...editingDamageItem,
                                attachments: [],
                              });
                            }}
                          >
                            <CompoundIcon
                              mainIcon={<AppIcon of='attachments' />}
                              badgeIcon={<AppIcon of='add' />}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {editingDamageItem.attachments !== undefined && (
                        <Tooltip title='Remove attachments'>
                          <IconButton
                            onClick={() => {
                              setEditingDamageItem({
                                ...editingDamageItem,
                                attachments: undefined,
                              });
                            }}
                          >
                            <CompoundIcon
                              mainIcon={<AppIcon of='attachments' />}
                              badgeIcon={<AppIcon of='remove' />}
                            />
                          </IconButton>
                        </Tooltip>
                      )}

                      {isPreload && (
                        <Tooltip title='Delete'>
                          <IconButton
                            onClick={(e) => {
                              e.preventDefault();
                              updateDamageItems(
                                damagesRef.current.filter(
                                  (item) => item.id !== editingDamageItem.id,
                                ),
                              );
                              setIsEditModalOpened(false);
                            }}
                          >
                            <AppIcon of='delete' />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </Grid>
                </Grid>

                {editingDamageItem.notes !== undefined && (
                  <AppTextArea
                    fullWidth
                    mode='notes'
                    margin='normal'
                    onChange={(ev) => {
                      setEditingDamageItem({
                        ...editingDamageItem,
                        notes: ev.target.value,
                      });
                    }}
                    type='text'
                    value={editingDamageItem.notes}
                    variant='outlined'
                  />
                )}

                {editingDamageItem.attachments !== undefined && (
                  <FormControl fullWidth margin='dense'>
                    {/* <FormLabel component='legend'>Attachments</FormLabel> */}
                    <FileUploader
                      multiple
                      defaultFiles={FileItem.createManyFrom(
                        damagesRef.current[
                          damagesRef.current.findIndex((el) => {
                            return editingDamageItem?.id === el?.id;
                          })
                        ].initialAttachments,
                      )}
                      onChange={(newFiles) => {
                        setEditingDamageItem({
                          ...editingDamageItem,
                          attachments:
                            FileItem.toManyGeneralAttachmentInputDto(newFiles) || undefined,
                          initialAttachments:
                            FileItem.toManyGeneralAttachmentDto(newFiles) || undefined,
                        });
                      }}
                      onUploadStarted={() => {
                        setIsAttachmentFilesUploading(true);
                      }}
                      onUploadFinished={() => {
                        setIsAttachmentFilesUploading(false);
                      }}
                      onValidationStatusChange={(filesValidationStatus) => {
                        if (filesValidationStatus)
                          setIsAttachmentFilesHaveErrors(
                            Object.values(filesValidationStatus).some((x) => x === false),
                          );
                      }}
                    />
                  </FormControl>
                )}
              </Box>
            )}
          </>
        }
        open={isEditModalOpened}
        onClose={() => {
          setIsEditModalOpened(false);
          setEditingDamageItem(null);
        }}
        onCancel={() => setIsEditModalOpened(false)}
        onConfirm={() => {
          if (editingDamageItem) {
            const newItems = damagesRef.current.filter((el) => {
              return editingDamageItem?.id !== el?.id;
            });
            updateDamageItems([...newItems, editingDamageItem]);
          }
          setIsEditModalOpened(false);
          setEditingDamageItem(null);
        }}
        isConfirmEnabled={
          !!editingDamageItem?.partTypeId &&
          !!editingDamageItem?.damageTypeId &&
          !isAttachmentFilesUploading &&
          !isAttachmentFilesHaveErrors &&
          isEditingModalConfirmEnabled
        }
      />

      <DamagePointInfoModal
        damagePointInfo={viewingPointInfo}
        open={isViewingModalOpened}
        onClose={() => setIsViewingModalOpened(false)}
      />

      <DamagePointsByVisualModelViewerModal
        damages={damageOnOtherVisualModel}
        open={isOtherVisualModelsModalOpen}
        onClose={() => setIsOtherVisualModelsModalOpen(false)}
      />
    </Stack>
  );
};
