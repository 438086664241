import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, Stack, TextField } from "@mui/material";
import { useFormik } from "formik";
import _ from "lodash";
import { useRef } from "react";

import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import TenantIdentifierInput from "@/common/components/Form/Input/TenantIdentifierInput";
import AppModal, { AppModalBaseProps } from "@/common/components/Modals/AppModal";
import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { tenantService } from "@/common/services/tenant";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { CreateCompanyDto } from "@/core/api/generated";

interface Props extends AppModalBaseProps {
  onCompanyCreated: () => void;
}

export default function CreateTenantModal({
  trigger,
  open,
  onOpen,
  onClose,
  onCompanyCreated,
}: Props) {
  const isIdentifierEditedRef = useRef(false);
  const { enqueueSnackbar } = useAppSnackbar();
  const mounted = useMounted();

  const formik = useFormik<BaseFormikValues & CreateCompanyDto>({
    enableReinitialize: true,
    initialValues: {
      name: undefined,
      identifier: undefined,
      description: undefined,
      department: undefined,
      submit: "",
    },
    onSubmit: async (values, { setFieldValue, setFieldError, setSubmitting, setStatus }) => {
      setSubmitting(true);
      try {
        await apiClient.accountApi.apiV1AccountRegisterCompanyNewPost({
          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
          createCompanyDto: {
            ..._.omit(values, "submit"),
            name: values.name,
            identifier: values.identifier,
            description: values.description,
            department: values.department,
          },
        });
        enqueueSnackbar("New company created", { variant: "success" });
        onCompanyCreated();
        onClose!();
        if (mounted.current) {
          setStatus({ success: true });
          setSubmitting(false);
        }
      } catch (err: Error | any) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
          setStatus({ success: false });
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = formik;

  // const brandingFormikProps = FormikHelper.getSubProps<
  //   BaseFormikValues & CreateCompanyDto,
  //   GeneralBrandingInputDto | undefined
  // >(formik, "department.branding", (v) => v.department?.branding);

  return (
    <AppModal
      fullWidth
      keepMounted={false}
      trigger={trigger}
      open={open}
      onOpen={onOpen}
      onClose={onClose}
    >
      <AppModalTitle title='Create new company' withCloseIcon onCloseClicked={onClose} />
      <DialogContent sx={{ mt: 2 }}>
        <Stack spacing={2} sx={{ pt: 1 }}>
          <TextField
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
            fullWidth
            required
            autoComplete='off'
            label='Company name'
            name='name'
            onBlur={handleBlur}
            onChange={(e) => {
              setFieldValue("name", e.target.value);
              if (!isIdentifierEditedRef.current) {
                setFieldValue(
                  "identifier",
                  tenantService.formatNameAsTenantIdentifier(e.target.value),
                );
              }
            }}
            value={values.name || ""}
          />

          <TenantIdentifierInput
            error={Boolean(errors.identifier)}
            helperText={errors.identifier}
            fullWidth
            required
            onChange={async (e) => {
              handleChange(e);
              isIdentifierEditedRef.current = true;
            }}
            onError={(err) => {
              const validation = ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setFieldError("identifier", validation.getFieldError("identifier"));
            }}
            value={values.identifier || ""}
          />

          <TextField
            error={Boolean(touched.description && errors.description)}
            helperText={touched.description && errors.description}
            fullWidth
            required
            multiline
            rows={3}
            label='Description'
            name='description'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.description || ""}
          />
        </Stack>

        <GeneralValidationError sx={{ my: 1 }} errors={errors} />

        <DialogActions sx={{ mt: 2 }}>
          <Button variant='outlined' color='text' onClick={() => onClose!()}>
            Cancel
          </Button>
          <LoadingButton variant='contained' loading={isSubmitting} onClick={() => handleSubmit()}>
            Save
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </AppModal>
  );
}
