import { LinearProgress } from "@mui/material";

import PageTabs from "@/App/Layouts/PageBody/PageTabs";
import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import EmailSettingsTabContent from "./Tabs/EmailSettingsTabContent";
import MainSettingsTabContent from "./Tabs/MainSettingsTabContent";

export enum TenantSettingsEditPageTabs {
  Main = "Main",
  Email = "Email",
}

export default function CreateUpdateTenantSettingsPage() {
  const tenantSettingsRequest = useApiRequest(
    apiClient.tenantProfileApi.apiV1TenantProfileSettingsGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
  );
  const tenantSettings = tenantSettingsRequest?.data;

  return (
    <CreateUpdatePageLayout header={<SimpleViewPageHeader title={"Company settings"} />}>
      {tenantSettingsRequest.isLoading && <LinearProgress />}

      <ViewContentBlock>
        <PageTabs
          tabIdsDefinition={TenantSettingsEditPageTabs}
          defaultTabId={TenantSettingsEditPageTabs.Main}
          tabs={[
            { label: "Main", value: TenantSettingsEditPageTabs.Main },
            { label: "Email", value: TenantSettingsEditPageTabs.Email },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            tenantSettings && (
              <>
                {activeTab === TenantSettingsEditPageTabs.Main && (
                  <MainSettingsTabContent tenantSettings={tenantSettings} />
                )}
                {activeTab === TenantSettingsEditPageTabs.Email && (
                  <EmailSettingsTabContent tenantSettings={tenantSettings} />
                )}
              </>
            )
          }
        </PageTabs>
      </ViewContentBlock>
    </CreateUpdatePageLayout>
  );
}
