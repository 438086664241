import { Box, Divider, Stack, Typography } from "@mui/material";
import { Switch } from "react-router-dom";

import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import AppRoute from "@/common/components/Route/AppRoute";
import { ROUTE_PATH } from "@/common/constants/routing";

import DevAlerts from "./DevAlerts";
import DevAutocomplete from "./DevAutocompletes";
import DevAvatars from "./DevAvatars";
import DevButtonGroups from "./DevButtonGroups";
import DevButtons from "./DevButtons";
import DevCards from "./DevCards";
import DevChats from "./DevChats";
import DevCheckboxes from "./DevCheckboxes";
import DevChips from "./DevChips";
import DevDataDisplay from "./DevDataDisplay";
import DevDataGrid from "./DevDataGrid";
import DevDataList from "./DevDataList";
import DevDataTabular from "./DevDataTabular";
import DevFieldValue from "./DevFieldValue";
import DevFiles from "./DevFiles";
import DevFloatingActionButtons from "./DevFloatingActionButton";
import DevFoldableBlock from "./DevFoldableBlock";
import DevIcons from "./DevIcons";
import DevInputs from "./DevInputs";
import DevListItemGroup from "./DevListItemGroup";
import DevMenus from "./DevMenus";
import DevMisc from "./DevMisc";
import DevModals from "./DevModals";
import DevPagination from "./DevPagination";
import DevPalette from "./DevPalette";
import DevPaper from "./DevPaper";
import DevPopovers from "./DevPopovers";
import DevRadioGroups from "./DevRadioGroups";
import DevRatings from "./DevRating";
import DevRenderHelpers from "./DevRenderHelpers";
import DevSelects from "./DevSelects";
import DevSkeletons from "./DevSkeletons";
import DEvSliders from "./DevSliders";
import DevSwitches from "./DevSwitches";
import DevTabs from "./DevTabs";
import DevTags from "./DevTags";
import DevTempPlayground from "./DevTempPlayground";
import DevText from "./DevText";
import DevTextFields from "./DevTextFields";
import DevTooltips from "./DevTooltips";
import DevTypography from "./DevTypography";
import DevColorInput from "./Input/DevColorInput";

export default function DevStyleguide() {
  return (
    <Stack sx={{ p: 3 }} direction={{ xxs: "column", md: "row" }} spacing={4}>
      {/* Nav */}
      <Stack direction='column' spacing={1}>
        <AppLink to={""} icon={<AppIcon of='code' inText />}>
          Index
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/palette`} icon={<AppIcon of='code' inText />}>
          Palette
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/typography`} icon={<AppIcon of='code' inText />}>
          Typography
        </AppLink>

        <Stack spacing={1}>
          <Typography variant='subtitle1'>Inputs</Typography>
          <AppLink
            to={`${ROUTE_PATH.DEV_STYLEGUIDE}/text-fields`}
            icon={<AppIcon of='code' inText />}
          >
            TextField
          </AppLink>
          <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/selects`} icon={<AppIcon of='code' inText />}>
            Select
          </AppLink>
          <AppLink
            to={`${ROUTE_PATH.DEV_STYLEGUIDE}/autocomplete`}
            icon={<AppIcon of='code' inText />}
          >
            Autocomplete
          </AppLink>
          <AppLink
            to={`${ROUTE_PATH.DEV_STYLEGUIDE}/checkboxes`}
            icon={<AppIcon of='code' inText />}
          >
            Checkbox
          </AppLink>
          <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/switches`} icon={<AppIcon of='code' inText />}>
            Switch
          </AppLink>
          <AppLink
            to={`${ROUTE_PATH.DEV_STYLEGUIDE}/color-input`}
            icon={<AppIcon of='code' inText />}
          >
            ColorInput
          </AppLink>
          <AppLink
            to={`${ROUTE_PATH.DEV_STYLEGUIDE}/custom-inputs`}
            icon={<AppIcon of='code' inText />}
          >
            Custom inputs
          </AppLink>
          <Divider />
        </Stack>

        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/paper`} icon={<AppIcon of='code' inText />}>
          Paper
        </AppLink>

        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/cards`} icon={<AppIcon of='code' inText />}>
          Cards
        </AppLink>

        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/alerts`} icon={<AppIcon of='code' inText />}>
          Alerts
        </AppLink>

        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/avatars`} icon={<AppIcon of='code' inText />}>
          Avatars
        </AppLink>

        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/buttons`} icon={<AppIcon of='code' inText />}>
          Buttons
        </AppLink>
        <AppLink
          to={`${ROUTE_PATH.DEV_STYLEGUIDE}/button-groups`}
          icon={<AppIcon of='code' inText />}
        >
          Button Groups
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/chips`} icon={<AppIcon of='code' inText />}>
          Chips
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/chats`} icon={<AppIcon of='code' inText />}>
          Chats
        </AppLink>

        <AppLink
          to={`${ROUTE_PATH.DEV_STYLEGUIDE}/data-display`}
          icon={<AppIcon of='code' inText />}
        >
          Data display
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/datalist`} icon={<AppIcon of='code' inText />}>
          DataList
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/datalist`} icon={<AppIcon of='code' inText />}>
          DataGrid
        </AppLink>
        <AppLink
          to={`${ROUTE_PATH.DEV_STYLEGUIDE}/datatabular`}
          icon={<AppIcon of='code' inText />}
        >
          DataTabular
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/icons`} icon={<AppIcon of='code' inText />}>
          Icons
        </AppLink>

        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/files`} icon={<AppIcon of='code' inText />}>
          Files
        </AppLink>
        <AppLink
          to={`${ROUTE_PATH.DEV_STYLEGUIDE}/floating-action-buttons`}
          icon={<AppIcon of='code' inText />}
        >
          Floating Action Buttons
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/menus`} icon={<AppIcon of='code' inText />}>
          Menus
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/modals`} icon={<AppIcon of='code' inText />}>
          Modals
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/pagination`} icon={<AppIcon of='code' inText />}>
          Pagination
        </AppLink>
        <AppLink
          to={`${ROUTE_PATH.DEV_STYLEGUIDE}/radio-groups`}
          icon={<AppIcon of='code' inText />}
        >
          Radio Groups
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/ratings`} icon={<AppIcon of='code' inText />}>
          Ratings
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/tags`} icon={<AppIcon of='code' inText />}>
          Tags
        </AppLink>

        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/text`} icon={<AppIcon of='code' inText />}>
          Text
        </AppLink>

        <AppLink
          to={`${ROUTE_PATH.DEV_STYLEGUIDE}/render-helpers`}
          icon={<AppIcon of='code' inText />}
        >
          Render helpers
        </AppLink>

        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/sliders`} icon={<AppIcon of='code' inText />}>
          Sliders
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/skeletons`} icon={<AppIcon of='code' inText />}>
          Skeletons
        </AppLink>

        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/tooltips`} icon={<AppIcon of='code' inText />}>
          Tooltips
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/popovers`} icon={<AppIcon of='code' inText />}>
          Popovers
        </AppLink>
        <AppLink
          to={`${ROUTE_PATH.DEV_STYLEGUIDE}/field-value`}
          icon={<AppIcon of='code' inText />}
        >
          FieldValue
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/tabs`} icon={<AppIcon of='code' inText />}>
          Tabs
        </AppLink>
        <AppLink
          to={`${ROUTE_PATH.DEV_STYLEGUIDE}/foldable-block`}
          icon={<AppIcon of='code' inText />}
        >
          FoldableBlock
        </AppLink>
        <AppLink
          to={`${ROUTE_PATH.DEV_STYLEGUIDE}/list-item-group`}
          icon={<AppIcon of='code' inText />}
        >
          ListItemGroup
        </AppLink>
        <AppLink to={`${ROUTE_PATH.DEV_STYLEGUIDE}/misc`} icon={<AppIcon of='code' inText />}>
          Misc
        </AppLink>
        <AppLink
          to={`${ROUTE_PATH.DEV_STYLEGUIDE}/temp-playground`}
          icon={<AppIcon of='code' inText />}
        >
          Temp playground
        </AppLink>
      </Stack>

      {/* Content */}
      <Box sx={{ flex: 1, minWidth: 0 }}>
        <Typography component='div' variant='h1' sx={{ mb: 2 }}>
          Styleguide (dev only)
        </Typography>

        <Switch>
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/palette`}
            render={() => {
              return <DevPalette />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/avatars`}
            render={() => {
              return <DevAvatars />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/autocomplete`}
            render={() => {
              return <DevAutocomplete />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/icons`}
            render={() => {
              return <DevIcons />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/buttons`}
            render={() => {
              return <DevButtons />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/button-groups`}
            render={() => {
              return <DevButtonGroups />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/chips`}
            render={() => {
              return <DevChips />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/color-input`}
            render={() => {
              return <DevColorInput />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/custom-inputs`}
            render={() => {
              return <DevInputs />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/datalist`}
            render={() => {
              return <DevDataList />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/datagrid`}
            render={() => {
              return <DevDataGrid />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/datatabular`}
            render={() => {
              return <DevDataTabular />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/files`}
            render={() => {
              return <DevFiles />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/floating-action-buttons`}
            render={() => {
              return <DevFloatingActionButtons />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/chats`}
            render={() => {
              return <DevChats />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/checkboxes`}
            render={() => {
              return <DevCheckboxes />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/menus`}
            render={() => {
              return <DevMenus />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/modals`}
            render={() => {
              return <DevModals />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/pagination`}
            render={() => {
              return <DevPagination />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/radio-groups`}
            render={() => {
              return <DevRadioGroups />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/ratings`}
            render={() => {
              return <DevRatings />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/paper`}
            render={() => {
              return <DevPaper />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/cards`}
            render={() => {
              return <DevCards />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/alerts`}
            render={() => {
              return <DevAlerts />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/data-display`}
            render={() => {
              return <DevDataDisplay />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/tags`}
            render={() => {
              return <DevTags />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/typography`}
            render={() => {
              return <DevTypography />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/text`}
            render={() => {
              return <DevText />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/text-fields`}
            render={() => {
              return <DevTextFields />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/render-helpers`}
            render={() => {
              return <DevRenderHelpers />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/selects`}
            render={() => {
              return <DevSelects />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/sliders`}
            render={() => {
              return <DEvSliders />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/skeletons`}
            render={() => {
              return <DevSkeletons />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/switches`}
            render={() => {
              return <DevSwitches />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/tooltips`}
            render={() => {
              return <DevTooltips />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/popovers`}
            render={() => {
              return <DevPopovers />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/field-value`}
            render={() => {
              return <DevFieldValue />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/tabs`}
            render={() => {
              return <DevTabs />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/foldable-block`}
            render={() => {
              return <DevFoldableBlock />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/list-item-group`}
            render={() => {
              return <DevListItemGroup />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/misc`}
            render={() => {
              return <DevMisc />;
            }}
          />
          <AppRoute
            exact
            path={`${ROUTE_PATH.DEV_STYLEGUIDE}/temp-playground`}
            render={() => {
              return <DevTempPlayground />;
            }}
          />
        </Switch>
      </Box>
    </Stack>
  );
}
