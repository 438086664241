import { Alert, Box, LinearProgress, Typography, TypographyProps } from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";

import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { FilterDefinition } from "@/common/filters/filterDefinition";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { apiClient } from "@/core/api/ApiClient";
import {
  EntityType,
  FilterDefinitionDto,
  VehicleDamageGetPaginatedDto,
  VehicleDamageState,
} from "@/core/api/generated";

import DamagePointsByVisualModelViewer from "../../Images/DamagePointsByVisualModelViewer";
import AppLink from "../../Link/AppLink";
import VehicleDamageList from "./VehicleDamageList";

export const VehicleDamagePaginatedListTabs = {
  All: "All",
  Actual: VehicleDamageState.Actual,
  Repaired: VehicleDamageState.Repaired,
} as const;
export type VehicleDamagePaginatedListTabs =
  (typeof VehicleDamagePaginatedListTabs)[keyof typeof VehicleDamagePaginatedListTabs];

const defaultHeaderProps = {
  withLink: false,
  title: undefined as ReactNode | undefined,
  typographyProps: undefined as TypographyProps<"div"> | undefined,
};

const defaultDisplayProps = {
  header: true,
  filters: true,
  tabs: true,
  pagination: true,
  vehicleDamagesWithModels: false,
  vehicleColumn: true,
  viewVariant: ViewLayoutVariant.Page,
};

type OwnProps = {
  initialValues?: {
    filterDefinition?: Nil<FilterDefinition>;
    filterDefinitionDto?: Nil<FilterDefinitionDto>;
    isResetFilterDefinition?: boolean;
    state?: VehicleDamageState;
  };
  defaultValues?: {
    vehicleId?: string;
    state?: VehicleDamageState;
    offset?: number;
    limit?: number;
  };
  headerProps?: Partial<typeof defaultHeaderProps>;
  displayProps?: Partial<typeof defaultDisplayProps>;
};

type Props = OwnProps;

export default function VehicleDamagePaginatedList({
  initialValues,
  defaultValues,
  headerProps = defaultHeaderProps,
  displayProps = defaultDisplayProps,
}: Props) {
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const commonRequestParams = useCommonRequestParams<VehicleDamageGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.VehicleDamage,
      isEnabled:
        !displayProps?.viewVariant ||
        [ViewLayoutVariant.Page, ViewLayoutVariant.Modal].includes(displayProps.viewVariant),
    },
    initialValues: {
      filterDefinition: initialValues?.filterDefinition || undefined,
      filterDefinitionDto: initialValues?.filterDefinitionDto || undefined,
      isResetFilterDefinition: initialValues?.isResetFilterDefinition,
      params: {
        ...(initialValues?.state ? { state: initialValues?.state } : undefined),
      },
    },
    defaultValues: {
      limit: defaultValues?.limit,
      params: {
        state: defaultValues?.state,
        ...defaultValues,
      },
    },
  });

  const paginatedVehicleDamagesRequest = useApiRequest(
    apiClient.vehicleDamagesApi.apiV1VehicleDamagesGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleDamageGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
        vehicleId: defaultValues?.vehicleId || undefined,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedVehicleDamages = paginatedVehicleDamagesRequest?.data;

  const vehicleRequest = useApiRequest(
    apiClient.vehiclesApi.apiV1VehiclesVehicleIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      vehicleId: defaultValues?.vehicleId || "",
    },
    {
      deps: [defaultValues?.vehicleId],
      skip: !defaultValues?.vehicleId,
    },
  );
  const vehicle = vehicleRequest.data;
  const isAnyDamages = paginatedVehicleDamages?.items?.length !== 0;
  const groupedByVisualModel = _.groupBy(paginatedVehicleDamages?.items, (d) => {
    return d.visualModel?.id;
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumbs:
      (vehicle && [
        {
          idValue: vehicle?.id || "",
          newTitle: vehicle?.localNumber || "",
        },
      ]) ||
      undefined,
  });

  return (
    <ViewLayoutV2
      displayProps={{ viewVariant: displayProps.viewVariant }}
      header={
        <>
          {displayProps?.header && (
            <Box>
              <AppLink
                enabled={headerProps?.withLink}
                to={ROUTE_PATH.VEHICLE_DAMAGES({ vehicleId: defaultValues?.vehicleId })}
              >
                {headerProps?.title || (
                  <Typography component='div' variant='h6' {...headerProps?.typographyProps}>
                    Vehicle damages
                  </Typography>
                )}
              </AppLink>
            </Box>
          )}
        </>
      }
    >
      {!isAnyDamages && defaultValues?.vehicleId && (
        <Alert severity='info' title='Vehicle has no damages.'>
          Vehicle has no damages.
        </Alert>
      )}

      {/* Visual models */}
      {displayProps?.vehicleDamagesWithModels && (
        <>
          {isAnyDamages &&
            Object.keys(groupedByVisualModel).map((visualModelId) => {
              return (
                <DamagePointsByVisualModelViewer
                  key={visualModelId}
                  visualModelId={visualModelId}
                  damages={groupedByVisualModel[visualModelId]}
                />
              );
            })}

          {/* Show empty visual model */}
          {!isAnyDamages && (
            <DamagePointsByVisualModelViewer
              visualModelId={undefined}
              visualModelLatestOrDefaultFor={{
                vehicleId: defaultValues?.vehicleId,
              }}
              damages={[]}
            />
          )}
        </>
      )}

      {paginatedVehicleDamagesRequest.isLoading && <LinearProgress sx={{ my: 1 }} />}

      <VehicleDamageList
        vehicleDamages={paginatedVehicleDamages?.items || []}
        dataTabularProps={{
          isLoading: paginatedVehicleDamagesRequest.isLoading,
          statePersistence: commonRequestParams.dataTabularProps.statePersistence,
          pagination: commonRequestParams.dataTabularProps.pagination,
          sort: commonRequestParams.dataTabularProps.sort,
          quickFilter: commonRequestParams.dataTabularProps.quickFilter,
          filters: commonRequestParams.dataTabularProps.filters,
          refetch: commonRequestParams.dataTabularProps.refetch,
          tabs: displayProps?.tabs
            ? {
                // value: pageTabs.activeTabId,
                value: commonRequestParams.params?.state || VehicleDamagePaginatedListTabs.All,
                onChange: (e, newValue) => {
                  commonRequestParams.setOneParam2({
                    state:
                      newValue === VehicleDamagePaginatedListTabs.All
                        ? undefined
                        : (newValue as VehicleDamageState),
                  });
                },
                tabs: [
                  {
                    label: <Box>{VehicleDamagePaginatedListTabs.All}</Box>,
                    value: VehicleDamagePaginatedListTabs.All,
                  },
                  {
                    label: <Box>{VehicleDamagePaginatedListTabs.Actual}</Box>,
                    value: VehicleDamagePaginatedListTabs.Actual,
                  },
                  {
                    label: <Box>{VehicleDamagePaginatedListTabs.Repaired}</Box>,
                    value: VehicleDamagePaginatedListTabs.Repaired,
                  },
                ],
              }
            : undefined,
        }}
      />
    </ViewLayoutV2>
  );
}
