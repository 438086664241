import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import * as Yup from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import { ContactCardData } from "@/common/components/Contact/ContactCard";
import ContactInput from "@/common/components/Contact/ContactInput";
import GeneralAddressInput from "@/common/components/Entity/General/Input/GeneralAddressInput";
import BaseEntityCreateUpdate from "@/common/components/Entity/components/BaseEntityCreateUpdate";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import FormActions from "@/common/components/Form/FormActions";
import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import AppIcon from "@/common/components/Icons/AppIcon";
import { IdHelper } from "@/common/helpers/id";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { cast } from "@/common/ts/conversions";
import { BaseFormikValues, ValidationErrors } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import { TenantPaymentProfileInputDto } from "@/core/api/generated";
import { EntityType, PaymentContactInputDto } from "@/core/api/generated/api";

type ContactValue = PaymentContactInputDto & { tempId?: string };

function PaymentProfileEdit() {
  const mounted = useMounted();
  const history = useHistory();
  const { enqueueSnackbar } = useAppSnackbar();
  const profile = useUserProfile();

  const paymentProfileRequest = useApiRequest(
    apiClient.paymentsApi.apiV1PaymentsPaymentProfileGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
    },
    {},
  );
  const paymentProfile = paymentProfileRequest?.data;

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.None}
      entityId={undefined}
      entity={undefined}
      entityRequest={paymentProfileRequest}
      isIniting={!profile || !paymentProfile}
    >
      <CreateUpdatePageLayout header={<SimpleViewPageHeader title={"Payment profile edit"} />}>
        <Formik<
          BaseFormikValues &
            Omit<TenantPaymentProfileInputDto, "contacts"> & {
              contactName: string;
              contactEmail: string;
              contactPhoneNumber: string;
              contacts?: ContactValue[];
            }
        >
          enableReinitialize={false}
          initialValues={{
            businessInfo: {
              businessName: paymentProfile?.businessInfo?.businessName || undefined,
              personName: {
                firstName: paymentProfile?.businessInfo?.personName?.firstName || undefined,
                lastName: paymentProfile?.businessInfo?.personName?.lastName || undefined,
              },
              email: paymentProfile?.businessInfo?.email || undefined,
              phoneNumber: paymentProfile?.businessInfo?.phoneNumber || undefined,
              address: {
                country: paymentProfile?.businessInfo?.address?.country || undefined,
                state: paymentProfile?.businessInfo?.address?.state || undefined,
                city: paymentProfile?.businessInfo?.address?.city || undefined,
                line1: paymentProfile?.businessInfo?.address?.line1 || undefined,
                line2: paymentProfile?.businessInfo?.address?.line2 || undefined,
                postalCode: paymentProfile?.businessInfo?.address?.postalCode || undefined,
              },
            },

            contacts: paymentProfile?.contacts
              ? paymentProfile.contacts.map((x) => ({
                  ...x,
                  id: x.id,
                  tempId: x.id || IdHelper.newUuid4(),
                  personName: x.personName,
                  email: x.email,
                  phoneNumber: x.phoneNumber,
                  isPrimary: x.isPrimary,
                }))
              : [
                  {
                    id: undefined,
                    tempId: IdHelper.newUuid4(),
                    isPrimary: true,
                  },
                ],

            contactName: "",
            contactEmail: "",
            contactPhoneNumber: "",

            submit: "",
          }}
          validationSchema={Yup.object().shape({
            // businessInfo: Yup.object().shape({
            //   businessName: Yup.string().max(100).nullable().required("Business name is required"),
            //   name: Yup.string().max(100).nullable().required("Name is required"),
            //   email: Yup.string()
            //     .nullable()
            //     .email("Must be a valid email")
            //     .max(255)
            //     .required("Email is required"),
            //   phoneNumber: Yup.string().nullable().required("Phone number is required"),
            //   address: Yup.object().shape({
            //     country: Yup.string().nullable().required("Country is required"),
            //     state: Yup.string().nullable().required("State is required"),
            //     city: Yup.string().nullable().required("City is required"),
            //     line1: Yup.string().nullable().required("Line 1 is required"),
            //     postalCode: Yup.string().nullable().required("Postal code is required"),
            //   }),
            // }),
          })}
          onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
            try {
              await apiClient.paymentsApi.apiV1PaymentsPaymentProfilePut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                tenantPaymentProfileInputDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Saved.", { variant: "success" });

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
              history.goBack();
            } catch (err: any) {
              if (mounted.current) {
                ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
            setValues,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <Stack spacing={1}>
                  <Box>
                    <Button
                      variant='outlined'
                      color='text'
                      disabled={!profile}
                      onClick={() => {
                        setFieldValue("businessInfo.personName", profile!.personName);
                        setFieldValue("businessInfo.email", profile!.email);
                        setFieldValue("businessInfo.phoneNumber", profile!.phoneNumber);
                        if (!values.contacts!.some((x) => x.email === profile!.email)) {
                          const contact: PaymentContactInputDto = {
                            personName: profile!.personName,
                            email: profile!.email,
                            phoneNumber: profile!.phoneNumber,
                            isPrimary: !values.contacts!.some((x) => x.isPrimary),
                          };
                          setFieldValue("contacts", [...values.contacts!, contact]);
                        }
                      }}
                    >
                      Use my profile data
                    </Button>
                  </Box>

                  <Box>
                    <Stack direction='column' spacing={0}>
                      <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={1}>
                        <FormControl
                          error={Boolean(getIn(errors, `businessInfo.businessName`))}
                          sx={{ width: "50%" }}
                        >
                          <TextField
                            error={Boolean(getIn(errors, `businessInfo.businessName`))}
                            autoFocus
                            fullWidth
                            required
                            label='Business name'
                            margin='normal'
                            name='businessInfo.businessName'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='text'
                            value={values.businessInfo?.businessName || ""}
                            variant='outlined'
                          />
                          <FormHelperText>
                            {getIn(errors, `businessInfo.businessName`)}
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          error={Boolean(getIn(errors, `businessInfo.personName.firstName`))}
                          sx={{ width: "50%" }}
                        >
                          <TextField
                            error={Boolean(getIn(errors, `businessInfo.personName.firstName`))}
                            fullWidth
                            required
                            label='FirstName'
                            margin='normal'
                            name='businessInfo.personName.firstName'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='text'
                            value={values.businessInfo?.personName?.firstName || ""}
                            variant='outlined'
                          />
                          <FormHelperText>
                            {getIn(touched, `businessInfo.personName.firstName`) &&
                              getIn(errors, `businessInfo.personName.firstName`)}
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          error={Boolean(getIn(errors, `businessInfo.personName.lastName`))}
                          sx={{ width: "50%" }}
                        >
                          <TextField
                            error={Boolean(getIn(errors, `businessInfo.personName.lastName`))}
                            fullWidth
                            required
                            label='LastName'
                            margin='normal'
                            name='businessInfo.personName.lastName'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='text'
                            value={values.businessInfo?.personName?.lastName || ""}
                            variant='outlined'
                          />
                          <FormHelperText>
                            {getIn(touched, `businessInfo.personName.lastName`) &&
                              getIn(errors, `businessInfo.personName.lastName`)}
                          </FormHelperText>
                        </FormControl>
                      </Stack>

                      <Stack direction={{ xs: "column", sm: "column", md: "row" }} spacing={1}>
                        <FormControl
                          error={Boolean(getIn(errors, `businessInfo.email`))}
                          sx={{ width: "50%" }}
                        >
                          <TextField
                            error={Boolean(getIn(errors, `businessInfo.email`))}
                            fullWidth
                            label='Email'
                            margin='normal'
                            name='businessInfo.email'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            type='email'
                            value={values.businessInfo?.email || ""}
                            variant='outlined'
                          />
                          <FormHelperText>
                            {getIn(touched, `businessInfo.email`) &&
                              getIn(errors, `businessInfo.email`)}
                          </FormHelperText>
                        </FormControl>

                        <FormControl sx={{ width: "50%" }}>
                          <PhoneInput
                            error={Boolean(getIn(errors, `businessInfo.phoneNumber`))}
                            fullWidth
                            helperText={
                              getIn(errors, `businessInfo.phoneNumber`) &&
                              getIn(errors, `businessInfo.phoneNumber`)
                            }
                            label='Phone number'
                            margin='normal'
                            name='businessInfo.phoneNumber'
                            value={values.businessInfo?.phoneNumber || ""}
                            variant='outlined'
                            color='primary'
                            placeholder='Phone number'
                            onBlur={handleBlur}
                            onChange={(e, ph, iso) => {
                              setFieldValue(`businessInfo.phoneNumber`, ph || undefined);
                            }}
                          />
                        </FormControl>
                      </Stack>

                      <Paper sx={{ my: 1, p: 1 }}>
                        <Typography component='div' sx={{ mb: 1, fontWeight: "bold" }}>
                          Address *
                        </Typography>

                        <GeneralAddressInput
                          value={values.businessInfo?.address}
                          errors={getIn(errors, "businessInfo.address")}
                          touched={getIn(touched, "businessInfo.address")}
                          inputsProps={{ all: {} }}
                          onChange={(newValue) => setFieldValue("businessInfo.address", newValue)}
                        />
                      </Paper>
                    </Stack>

                    <Paper sx={{ mt: 1, p: 1 }}>
                      <Typography component='div' sx={{ fontWeight: "bold" }}>
                        Contacts *
                      </Typography>

                      <Stack
                        direction={{ xxs: "column", md: "row" }}
                        spacing={1}
                        sx={{ mt: 1, flexWrap: "wrap" }}
                      >
                        {values.contacts?.map((contact, i) => (
                          <Box key={i}>
                            <ContactInput
                              sx={{ mb: 1 }}
                              value={{
                                id: contact.id || undefined,
                                tempId: contact.tempId || undefined,
                                firstName: contact.personName?.firstName,
                                lastName: contact.personName?.lastName,
                                email: contact.email,
                                phoneNumber: contact.phoneNumber || undefined,
                                isPrimary: contact.isPrimary,
                              }}
                              displayProps={{
                                firstName: true,
                                lastName: true,
                                email: true,
                                phoneNumber: true,
                                nationalIdentityNumber: false,
                                isPrimary: true,
                              }}
                              inputsProps={{
                                firstName: {
                                  name: `contacts[${i}].personName.firstName`,
                                  onBlur: handleBlur,
                                },
                                lastName: {
                                  name: `contacts[${i}].personName.lastName`,
                                  onBlur: handleBlur,
                                },
                                email: {
                                  name: `contacts[${i}].email`,
                                  onBlur: handleBlur,
                                },
                                phoneNumber: {
                                  name: `contacts[${i}].phoneNumber`,
                                  onBlur: handleBlur,
                                },
                                isPrimary: {
                                  name: `contacts[${i}].isPrimary`,
                                  onBlur: handleBlur,
                                },
                              }}
                              errors={
                                getIn(errors, `contacts.${i}`) as ValidationErrors<ContactCardData>
                              }
                              action={
                                <IconButton
                                  onClick={() => {
                                    setValues((currentValues) => ({
                                      ...currentValues,
                                      contacts: currentValues.contacts?.filter(
                                        (x) => x.tempId !== contact.tempId,
                                      ),
                                    }));
                                  }}
                                >
                                  <AppIcon of='close' />
                                </IconButton>
                              }
                              onChange={(newValue) => {
                                setValues((currentValues) => ({
                                  ...currentValues,
                                  contacts: currentValues.contacts?.map((x) =>
                                    x.tempId === newValue?.tempId
                                      ? {
                                          ...x,
                                          ...newValue,
                                          personName: {
                                            firstName: newValue?.firstName,
                                            lastName: newValue?.lastName,
                                          },
                                        }
                                      : {
                                          ...x,
                                          isPrimary: newValue.isPrimary ? false : x.isPrimary,
                                        },
                                  ),
                                }));
                              }}
                            />
                          </Box>
                        ))}
                      </Stack>

                      <Button
                        sx={{ mt: 1, width: "fit-content" }}
                        variant='outlined'
                        color='text'
                        size='small'
                        type='button'
                        startIcon={<AppIcon of='add' />}
                        onClick={() => {
                          setFieldValue("contacts", [
                            ...(values.contacts || []),
                            cast<ContactValue>({
                              tempId: IdHelper.newUuid4(),
                              isPrimary: values.contacts?.length === 0,
                            }),
                          ]);
                        }}
                      >
                        Add contact
                      </Button>

                      {errors.contacts && (
                        <Box sx={{ mt: 1, mb: 1 }}>
                          {!Array.isArray(errors.contacts) && (
                            <FormHelperText error>
                              {ValidationHelper.getFormikErrorsAsString(errors.contacts)}
                            </FormHelperText>
                          )}
                        </Box>
                      )}
                    </Paper>
                  </Box>

                  <GeneralValidationError errors={errors} />

                  <FormActions>
                    <LoadingButton
                      loading={isSubmitting}
                      color='primary'
                      fullWidth
                      type='submit'
                      variant='contained'
                    >
                      Save
                    </LoadingButton>
                  </FormActions>
                </Stack>
              </form>
            );
          }}
        </Formik>
      </CreateUpdatePageLayout>
    </BaseEntityCreateUpdate>
  );
}

export default withTranslation()(PaymentProfileEdit);
