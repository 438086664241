import { Grid, Stack, SxProps, Theme, Typography } from "@mui/material";

import EntityDataBlock, {
  EntityDataBlockProps,
} from "@/common/components/EntityData/EntityDataBlock";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { UserDto, UserReferenceDto } from "@/core/api/generated";

interface BaseProps extends Pick<EntityDataBlockProps, "withDetailsToggle" | "isDetailsVisible"> {
  withLink?: boolean;
  briefDataProps?: {
    // vehicle?: boolean;
    temp?: boolean;
  };
  detailedDataProps?: {
    // vehicle?: boolean;
    temp?: boolean;
  };
  sx?: SxProps<Theme>;
}

type Props =
  | ({ variant: "full"; user?: UserDto } & BaseProps)
  | ({ variant: "reference"; user?: UserReferenceDto } & BaseProps);

const defaultBriefDataProps: Props["briefDataProps"] = {
  // vehicle: true,
};
const defaultDetailedDataProps: Props["detailedDataProps"] = {
  // vehicle: true,
};

/** User info as block element. */
export default function BlockUser({
  variant,
  user,
  withLink,
  briefDataProps = defaultBriefDataProps,
  detailedDataProps = defaultDetailedDataProps,
  sx,
  ...entityDataBlockProps
}: Props) {
  briefDataProps = { ...defaultBriefDataProps, ...briefDataProps };
  detailedDataProps = {
    ...defaultDetailedDataProps,
    ...detailedDataProps,
  };

  const fullDto = variant === "full" ? (user as UserDto | undefined) : null;
  // const refDto = variant === "reference" ? (user as UserReferenceDto | undefined) : null;

  if (!user) {
    return null;
  }

  return (
    <EntityDataBlock
      sx={{
        flex: 1,
        ...sx,
      }}
      {...entityDataBlockProps}
      to={withLink ? ROUTE_PATH.USER_VIEW(user.id) : undefined}
      title={
        <>
          <AppIcon of='user' inText /> User
        </>
      }
      briefContent={
        <>
          {user.personName && (
            <Typography component='div' variant='body1'>
              {user.personName.name}
            </Typography>
          )}
          {user.email && (
            <Typography component='div' variant='body2'>
              {user.email}
            </Typography>
          )}
        </>
      }
      detailedContent={
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xxs={12} md minWidth={200}>
            <Stack direction='column' spacing={1}>
              <FieldValue
                labelIcon={<AppIcon of='item' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Name'
              >
                {user.personName?.name}
              </FieldValue>
              <FieldValue
                labelIcon={<AppIcon of='email' inText />}
                withBorder={false}
                size='compact'
                valueAlight={{ horizontal: "end" }}
                ellipsing={{ enabled: true }}
                label='Email'
              >
                {user.email}
              </FieldValue>
              {fullDto?.phoneNumber && (
                <FieldValue
                  labelIcon={<AppIcon of='phone' inText />}
                  withBorder={false}
                  size='compact'
                  valueAlight={{ horizontal: "end" }}
                  ellipsing={{ enabled: true }}
                  label='Phone number'
                >
                  {fullDto.phoneNumber}
                </FieldValue>
              )}
            </Stack>
          </Grid>
        </Grid>
      }
    />
  );
}
