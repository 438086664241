import { Button, Stack, Typography } from "@mui/material";

import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import ViewLayout from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import ContactCard from "@/common/components/Contact/ContactCard";
import GeneralBrandingDisplay from "@/common/components/Entity/General/GeneralBranding/GeneralBrandingDisplay";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useTenantProfile } from "@/common/hooks/entity/tenant/useTenantProfile";
import { AppPermission } from "@/core/api/generated";

export default function CompanyProfileViewPage() {
  const profile = useTenantProfile();

  return (
    <ViewLayout
      header={
        <DetailedViewPageHeader
          title={"Company profile"}
          primaryActions={
            <AuthorizedElement permissions={[AppPermission.TenantManage]}>
              <Button
                component={AppLink}
                to={ROUTE_PATH.MANAGEMENT_TENANT_PROFILE_EDIT}
                variant='outlined'
                color='text'
                size='medium'
                startIcon={<AppIcon of='edit' />}
              >
                Edit
              </Button>
            </AuthorizedElement>
          }
        />
      }
    >
      <Stack spacing={2}>
        <Stack spacing={1}>
          <FieldValue label='Name'>{profile?.name}</FieldValue>
          <FieldValue label='Description'>{profile?.description}</FieldValue>
        </Stack>

        <Stack spacing={2}>
          <Typography variant='h2'>Contact person</Typography>

          <ContactCard
            contact={{
              name: profile?.contactPerson?.personName?.name,
              email: profile?.contactPerson?.email,
              phoneNumber: profile?.contactPerson?.phoneNumber,
            }}
          />
        </Stack>

        <Stack spacing={1}>
          <Typography variant='h2'>Branding</Typography>

          <GeneralBrandingDisplay branding={profile?.branding} />
        </Stack>
      </Stack>
    </ViewLayout>
  );
}
