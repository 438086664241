import {
  Autocomplete,
  CircularProgress,
  FormControl,
  FormHelperText,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  SxProps,
  TextField,
  TextFieldProps,
  Theme,
} from "@mui/material";
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import _ from "lodash";
import {
  DependencyList,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";

import { PaginationHelper } from "@/common/helpers/pagination";
import { useEffectWithDeepCompare } from "@/common/hooks/effect/useEffectWithDeepCompare";
import { useMemoWithDeepCompare } from "@/common/hooks/memo/useMemoWithDeepCompare";
import {
  AutocompleteOption,
  AutocompleteOptionType,
  EntitiesToAutocompleteOptionsFunc,
  EntityToAutocompleteOptionFunc,
  createOptionFromInputValue,
} from "@/common/ts/autocomplete";
import { PaginationDtoOfTItemTyped } from "@/common/ts/pagination";
import { IBaseEntityDto } from "@/core/api/generated";

import AppIconButton from "../../Button/AppIconButton";
import AppIcon from "../../Icons/AppIcon";
import {
  AutocompleteGroupByFunc,
  AutocompleteRenderGroupTitleParams,
  AutocompleteSortByFunc,
  AutocompleteSortOrder,
  BaseRequestParams,
  EntitySearchRequestFuncParameters,
  EntitySearchResponseData,
  sortOptions,
} from "./BaseEntitySearchAutocomplete";

export type EntitiesSearchResponseData<TEntity> = TEntity[] | PaginationDtoOfTItemTyped<TEntity>;

export type EntitiesSearchRequestFuncParameters<
  TEntity,
  TRequestFunc extends (
    requestParameters: any,
    options?: AxiosRequestConfig,
  ) => Promise<AxiosResponse<EntitySearchResponseData<TEntity>>>,
> = Parameters<TRequestFunc>[0] & BaseRequestParams;

export interface BaseEntitiesSearchAutocompleteProps<
  TEntity extends IBaseEntityDto,
  TRequestFunc extends (
    ...args: any
  ) => Promise<AxiosResponse<EntitiesSearchResponseData<TEntity>>>,
  TRequestParams extends EntitySearchRequestFuncParameters<
    TEntity,
    TRequestFunc
  > = EntitySearchRequestFuncParameters<TEntity, TRequestFunc>,
> {
  // generic props
  entityIds: string[] | null | undefined;
  entities: TEntity[] | undefined;
  entityToOption: EntityToAutocompleteOptionFunc<TEntity>;
  /** Load data even if the autocomplete is closed.*/
  isPreload: boolean;
  /** Auto-select option if it's the single option in the list and the input is required. */
  isAutoSelectSingleOption?: boolean;
  /** Request configuration. */
  request: {
    requestFunc: TRequestFunc;
    limit?: number;
    parameters: TRequestParams;
    combineParameters: (params: TRequestParams, newParams: BaseRequestParams) => TRequestParams;
    deps: DependencyList;
    skip?: boolean;
  };
  /** Additional request configuration. */
  requestConfig?: {
    limit?: number;
    skip?: boolean;
  };

  // component props
  renderOption: (
    props: HTMLAttributes<HTMLLIElement> & { key: string },
    option: AutocompleteOption<TEntity>,
  ) => ReactNode;
  renderGroupTitle?: (params: AutocompleteRenderGroupTitleParams<TEntity>) => ReactNode;
  disabled?: boolean;
  required?: boolean;
  size?: "small" | "medium";
  fullWidth?: boolean;
  /** If `true`, hide the selected options from the list box. */
  filterSelectedOptions?: boolean;
  label: TextFieldProps["label"];
  placeholder: TextFieldProps["placeholder"];
  sortBy?: AutocompleteSortByFunc<TEntity>;
  sortOrder?: AutocompleteSortOrder;
  groupBy?: AutocompleteGroupByFunc<TEntity>;
  withCreate?: boolean;
  createOptionTitle?: string | ((inputValue: string) => string) | null;
  textFieldProps?: TextFieldProps;
  /** If `true`, the entity option will be disabled. */
  shouldDisable?: (entity: TEntity) => boolean;
  /** If `true`, the entity option will be hidden. */
  shouldHide?: (entity: TEntity) => boolean;
  onChange?: (newEntities?: TEntity[]) => void;
  /** Works only if withCreate is true. */
  onCreate?: (newOption?: AutocompleteOption<TEntity>) => void;
  sx?: SxProps<Theme>;
}

/** Interface to be used by wrapper components that utilize this component. */
export interface InheritableBaseEntitiesSearchAutocompleteProps<TEntity extends IBaseEntityDto> {
  entityIds: string[] | null | undefined;
  entities: TEntity[] | undefined;
  /** Load data even if the autocomplete is closed.*/
  isPreload?: boolean;
  /** Auto-select option if it's the single option in the list and the input is required. */
  isAutoSelectSingleOption?: boolean;
  /** Additional request configuration. */
  requestConfig?: {
    limit?: number;
    skip?: boolean;
  };
  disabled?: boolean;
  required?: boolean;
  size?: "small" | "medium";
  fullWidth?: boolean;
  /** If `true`, hide the selected options from the list box. */
  filterSelectedOptions?: boolean;
  withCreate?: boolean;
  createOptionTitle?: string | ((inputValue: string) => string) | null;
  textFieldProps?: TextFieldProps;
  /** If `true`, the entity option will be disabled. */
  shouldDisable?: (entity: TEntity) => boolean;
  /** If `true`, the entity option will be hidden. */
  shouldHide?: (entity: TEntity) => boolean;
  onChange?: (newEntities?: TEntity[]) => void;
  onCreate?: (newOption?: AutocompleteOption<TEntity>) => void;
}

export default function BaseEntitiesSearchAutocomplete<
  TEntity extends IBaseEntityDto,
  TRequestFunc extends (
    requestParameters: any,
    options?: AxiosRequestConfig,
  ) => Promise<AxiosResponse<EntitySearchResponseData<TEntity>>>,
  TRequestParams extends EntitiesSearchRequestFuncParameters<
    TEntity,
    TRequestFunc
  > = EntitySearchRequestFuncParameters<TEntity, TRequestFunc>,
>({
  entityIds,
  entities,
  entityToOption,
  isPreload = true,
  isAutoSelectSingleOption = true,
  request,
  requestConfig,
  renderOption,
  renderGroupTitle,
  disabled,
  required,
  size,
  fullWidth,
  filterSelectedOptions = false,
  label,
  placeholder,
  sortBy,
  sortOrder,
  groupBy,
  withCreate,
  createOptionTitle,
  textFieldProps,
  shouldDisable,
  shouldHide,
  onChange,
  onCreate,
  sx,
  ...otherProps
}: BaseEntitiesSearchAutocompleteProps<TEntity, TRequestFunc>) {
  const [open, setOpen] = useState(false);
  const [hasPermission, setHasPermissions] = useState(true);
  const [options, setOptions] = useState<readonly AutocompleteOption<TEntity>[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<AutocompleteOption<TEntity>[] | null>(
    null,
  );

  const isRequestStarted = useRef(false);
  const [inputValue, setInputValue] = useState<string | null | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResponseData, setSearchResponseData] = useState<
    EntitiesSearchResponseData<TEntity> | undefined
  >(undefined);

  const [isTextFieldHovered, setIsTextFieldHovered] = useState<boolean>(false);
  const isSelectedOptionsLoaded =
    _.isEmpty(entityIds) ||
    (!_.isEmpty(entityIds) && options.some((x) => entityIds!.includes(x.id)));
  const canLoad =
    ((isPreload || (!isPreload && open)) && (_.isEmpty(selectedOptions) || !_.isNil(inputValue))) ||
    !isSelectedOptionsLoaded;

  const optionsGroupedMap = useMemo(
    () =>
      options &&
      _.chain(options)
        .groupBy((x) => x.groupBy)
        .value(),
    [options],
  );

  const entityList = useMemo(
    () => (searchResponseData && _.isArray(searchResponseData) ? searchResponseData : undefined),
    [searchResponseData],
  );
  const paginatedEntities = useMemo(
    () => (searchResponseData && !_.isArray(searchResponseData) ? searchResponseData : undefined),
    [searchResponseData],
  );
  const entityListComputed = useMemo(
    () => entityList || paginatedEntities?.items || undefined,
    [entityList, paginatedEntities],
  );

  const entitiesToOptions = useMemo<EntitiesToAutocompleteOptionsFunc<TEntity>>(
    () => (entities2) => (entities2 && entities2.map((x) => entityToOption(x))) || [],
    [entityToOption],
  );

  const requestComputed = useMemo<typeof request>(
    () => ({
      ...request,
      ...requestConfig,
      limit: requestConfig?.limit ?? request.limit ?? PaginationHelper.defaultLimit,
      skip: request.skip || requestConfig?.skip,
    }),
    [request, requestConfig],
  );

  const currentLimitRef = useRef<number | undefined>(requestComputed.limit);

  const computeParameters = useCallback(
    (limit?: number, searchValue?: string | null, ids?: string[] | null) => {
      const baseSearchParams: BaseRequestParams = {
        limit: limit,
        search: searchValue || undefined,
        includeIds: ids ? [...ids] : undefined,
      };
      return requestComputed.combineParameters(requestComputed.parameters, baseSearchParams);
    },
    [requestComputed.parameters],
  );

  // compute request params
  const parametersComputed = useMemoWithDeepCompare(() => {
    return computeParameters(currentLimitRef.current, inputValue, entityIds);
  }, [entityIds, inputValue, currentLimitRef.current]);

  const searchRef = useRef(requestComputed.requestFunc);
  const searchComputed = useCallback(async (requestParams: TRequestParams) => {
    if (isRequestStarted.current) {
      return;
    }
    isRequestStarted.current = true;
    setIsLoading(true);
    try {
      const response = await searchRef.current(requestParams);
      setSearchResponseData(response.data);
    } catch (e) {
      const statusCode = (e as AxiosError).response?.status;
      if (statusCode === 403 || statusCode === 401) {
        setHasPermissions(false);
      }
    } finally {
      isRequestStarted.current = false;
      setIsLoading(false);
    }
  }, []);
  const searchThrottle = useCallback(
    _.throttle(searchComputed, 500, { leading: true, trailing: false }),
    [searchComputed],
  );
  const searchDebounce = useCallback(
    _.debounce(searchComputed, 500, { leading: false, trailing: true }),
    [searchComputed],
  );

  useEffectWithDeepCompare(() => {
    if (!canLoad) return;
    if (isRequestStarted.current) {
      return;
    }
    currentLimitRef.current = requestComputed.limit;
    const params = computeParameters(currentLimitRef.current, inputValue, entityIds);
    searchThrottle(params);
  }, [canLoad, ...requestComputed.deps]);

  useEffect(() => {
    if (!canLoad) return;
    if (isRequestStarted.current) {
      return;
    }
    currentLimitRef.current = requestComputed.limit;
    const params = computeParameters(currentLimitRef.current, inputValue, entityIds);
    searchDebounce(params);
  }, [inputValue]);

  useEffect(() => {
    const newOptions = _.uniqBy(
      [...(selectedOptions ? selectedOptions : []), ...entitiesToOptions(entityListComputed || [])],
      (x) => x.id,
    );
    setOptions(sortOptions({ options: newOptions, sortBy, sortOrder }));

    if (
      !_.isEmpty(entityIds) &&
      (_.isEmpty(selectedOptions) ||
        !entityIds!.every((entityId) => selectedOptions!.some((option) => option.id === entityId)))
    ) {
      setSelectedOptions(newOptions.filter((x) => entityIds!.includes(x.id)) || null);
    }

    // handle isAutoSelectSingleOption
    const singleOption = newOptions.length === 1 ? newOptions[0] : undefined;
    if (
      isAutoSelectSingleOption &&
      required &&
      !isLoading &&
      _.isEmpty(entityIds) &&
      _.isEmpty(entities) &&
      !selectedOptions &&
      singleOption
    ) {
      setSelectedOptions([singleOption]);
      onChange && onChange(singleOption.data ? [singleOption.data] : undefined);
    }
  }, [entityListComputed, entityIds, selectedOptions]);

  useEffect(() => {
    if (_.isEmpty(entities) && _.isEmpty(entityIds)) {
      setSelectedOptions(null);
      // setInputValue(undefined); // NB: this break create new option when searching
    } else if (
      !_.isEmpty(entities) &&
      (_.isEmpty(selectedOptions) ||
        !entities!.every((entity) => selectedOptions!.some((option) => option.id === entity.id!)))
    ) {
      setOptions(
        sortOptions({
          options: _.uniqBy([...entitiesToOptions(entities), ...options], (x) => x.id),
          sortBy,
          sortOrder,
        }),
      );
      setSelectedOptions(entitiesToOptions(entities));
    } else if (
      !_.isEmpty(entityIds) &&
      (_.isEmpty(selectedOptions) ||
        !entityIds!.every((entityId) => selectedOptions!.some((option) => option.id === entityId)))
    ) {
      setSelectedOptions(options.filter((x) => entityIds?.includes(x.id)));
    }
  }, [entityIds, entities, selectedOptions, options]);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage:
      (paginatedEntities?.pagination?.limit || 0) <
      (paginatedEntities?.pagination?.totalCount || 0),
    onLoadMore: () => {
      currentLimitRef.current = (currentLimitRef.current || 0) + (requestComputed.limit || 0);
      searchComputed(parametersComputed);
    },
    rootMargin: "0px 0px 300px 0px",
  });

  const sentryOption: AutocompleteOption<TEntity> = useMemo(
    () => ({ id: "sentry", title: "", optionType: AutocompleteOptionType.Sentry }),
    [],
  );
  return (
    <Autocomplete
      sx={{ minWidth: 200, flex: 1, ...sx }}
      multiple
      fullWidth={fullWidth}
      size={size}
      disabled={disabled || !hasPermission}
      filterSelectedOptions={filterSelectedOptions}
      open={open}
      options={options}
      loading={isLoading}
      autoComplete
      openOnFocus
      blurOnSelect={false}
      disableCloseOnSelect
      includeInputInList
      value={selectedOptions || []}
      inputValue={inputValue || ""}
      selectOnFocus
      clearOnBlur={false}
      clearOnEscape
      handleHomeEndKeys
      freeSolo={withCreate}
      ListboxProps={{ ref: rootRef }}
      groupBy={groupBy}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => {
        // value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // regular option
        return option.title;
      }}
      filterOptions={(opts, params) => {
        // suggest the creation of a new value (add dynamic option with input value)
        if (withCreate) {
          const isExisting = options.some((option) => params.inputValue === option.title);
          if (params.inputValue !== "" && !isExisting) {
            const title =
              (createOptionTitle &&
                ((_.isFunction(createOptionTitle) && createOptionTitle(params.inputValue)) ||
                  (_.isString(createOptionTitle) && createOptionTitle))) ||
              `Create new "${params.inputValue}"`;

            opts.push(
              createOptionFromInputValue({
                inputValue: params.inputValue,
                title: title,
              }),
            );
          }
        }

        if (
          !_.isEmpty(opts) &&
          (paginatedEntities?.pagination?.limit || 0) <
            (paginatedEntities?.pagination?.totalCount || 0)
        ) {
          // add sentry option for infinite scroll
          opts.push(sentryOption);
        }

        return opts;
      }}
      onChange={(e, newValues) => {
        const newOptions = newValues.filter((x) => !_.isString(x)) as AutocompleteOption<TEntity>[];
        const newOptionsFromInputValue = newOptions.filter((x) => !!x.inputValue);
        const newOptionFromInputValue =
          newOptionsFromInputValue.length !== 0 ? newOptionsFromInputValue[0] : undefined;
        const newInputValues = newValues.filter((x) => _.isString(x)) as string[];
        const newInputValue = newInputValues.length !== 0 ? newInputValues[0] : undefined;

        // value selected with enter, right from the input
        if (typeof newInputValue === "string") {
          withCreate &&
            onCreate &&
            onCreate(
              createOptionFromInputValue({
                inputValue: newInputValue,
              }),
            );
          return;
        } else if (newOptionFromInputValue && newOptionFromInputValue.inputValue) {
          // create a new value from the user input
          setInputValue("");
          withCreate && onCreate && onCreate(newOptionFromInputValue);
          return;
        } else {
          setSelectedOptions(newOptions);
          setInputValue("");
          onChange &&
            onChange(newOptions.length !== 0 ? newOptions.map((x) => x.data!) : undefined);
        }
      }}
      onInputChange={(e, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <FormControl fullWidth error={!hasPermission}>
          <TextField
            {...textFieldProps}
            {...params}
            required={required}
            label={label || textFieldProps?.label}
            placeholder={placeholder || textFieldProps?.placeholder}
            fullWidth
            onMouseEnter={() => setIsTextFieldHovered(true)}
            onMouseLeave={() => setIsTextFieldHovered(false)}
            InputProps={{
              ...textFieldProps?.InputProps,
              ...params.InputProps,
              startAdornment: textFieldProps?.InputProps?.startAdornment,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : (
                    <AppIconButton
                      title='Refresh'
                      // using visibility like Mui clear button
                      sx={{ visibility: isTextFieldHovered ? "visible" : "hidden" }}
                      disabled={disabled || !hasPermission}
                      size='small'
                      onClick={() => searchComputed(parametersComputed)}
                    >
                      <AppIcon of='refresh' />
                    </AppIconButton>
                  )}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
          <FormHelperText error={!hasPermission}>
            {!hasPermission && `You don't have enough permission`}
          </FormHelperText>
        </FormControl>
      )}
      renderGroup={
        groupBy
          ? (params) => {
              const optionsInGroup = optionsGroupedMap[params.group] || [];
              const titleRendered = renderGroupTitle
                ? renderGroupTitle({
                    group: params.group,
                    options: optionsInGroup,
                    props: {},
                  })
                : undefined;

              return (
                <ListItem key={params.key} disablePadding>
                  <Stack sx={{ width: "100%" }}>
                    <ListSubheader component='div' sx={{ position: "sticky", top: "-8px" }}>
                      {titleRendered || params.group}
                    </ListSubheader>
                    <List disablePadding>{params.children}</List>
                  </Stack>
                </ListItem>
              );
            }
          : undefined
      }
      renderOption={(props, option) => {
        if (option.optionType === AutocompleteOptionType.Sentry) {
          return (
            <ListItem ref={sentryRef}>
              <ListItemText>Loading...</ListItemText>
            </ListItem>
          );
        }

        const isHidden = option.data && shouldHide ? shouldHide(option.data) : false;
        if (isHidden) {
          return null;
        }
        const isDisabled = option.data && shouldDisable ? shouldDisable(option.data) : false;
        return renderOption(
          {
            ...props,
            key: option.id,
            style: {
              ...props?.style,
              cursor: "default",
              ...(option.optionType === AutocompleteOptionType.NoOptions
                ? {
                    cursor: "default",
                  }
                : undefined),
              ...(isDisabled
                ? {
                    pointerEvents: "none",
                    opacity: 0.5,
                  }
                : undefined),
            },
          },
          option,
        );
      }}
      {...otherProps}
    />
  );
}
