import { SvgIcon, SvgIconProps } from "@mui/material";
import { forwardRef } from "react";

export default forwardRef<SVGSVGElement, SvgIconProps>(function ManagementIcon(
  props: SvgIconProps,
  ref,
) {
  return (
    <SvgIcon
      ref={ref}
      {...props}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.25 5.25H3.13875C3.44925 6.12075 4.2735 6.75 5.25 6.75C6.2265 6.75 7.05075 6.12075 7.36125 5.25H15.75C16.1648 5.25 16.5 4.91475 16.5 4.5C16.5 4.08525 16.1648 3.75 15.75 3.75H7.36125C7.05075 2.87925 6.2265 2.25 5.25 2.25C4.2735 2.25 3.44925 2.87925 3.13875 3.75H2.25C1.83525 3.75 1.5 4.08525 1.5 4.5C1.5 4.91475 1.83525 5.25 2.25 5.25ZM14.25 6.75C13.2735 6.75 12.4493 7.37925 12.1388 8.25H2.25C1.83525 8.25 1.5 8.58525 1.5 9C1.5 9.41475 1.83525 9.75 2.25 9.75H12.1388C12.4493 10.6208 13.2735 11.25 14.25 11.25C15.4905 11.25 16.5 10.2405 16.5 9C16.5 7.7595 15.4905 6.75 14.25 6.75ZM10.3612 12.75H15.75C16.1648 12.75 16.5 13.0852 16.5 13.5C16.5 13.9148 16.1648 14.25 15.75 14.25H10.3612C10.0507 15.1208 9.2265 15.75 8.25 15.75C7.2735 15.75 6.44925 15.1208 6.13875 14.25H2.25C1.83525 14.25 1.5 13.9148 1.5 13.5C1.5 13.0852 1.83525 12.75 2.25 12.75H6.13875C6.44925 11.8792 7.2735 11.25 8.25 11.25C9.2265 11.25 10.0507 11.8792 10.3612 12.75Z'
      />
      <mask
        id='mask0_213_2853'
        style={{ maskType: "luminance" }}
        maskUnits='userSpaceOnUse'
        x='1'
        y='2'
        width='16'
        height='14'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.25 5.25H3.13875C3.44925 6.12075 4.2735 6.75 5.25 6.75C6.2265 6.75 7.05075 6.12075 7.36125 5.25H15.75C16.1648 5.25 16.5 4.91475 16.5 4.5C16.5 4.08525 16.1648 3.75 15.75 3.75H7.36125C7.05075 2.87925 6.2265 2.25 5.25 2.25C4.2735 2.25 3.44925 2.87925 3.13875 3.75H2.25C1.83525 3.75 1.5 4.08525 1.5 4.5C1.5 4.91475 1.83525 5.25 2.25 5.25ZM14.25 6.75C13.2735 6.75 12.4493 7.37925 12.1388 8.25H2.25C1.83525 8.25 1.5 8.58525 1.5 9C1.5 9.41475 1.83525 9.75 2.25 9.75H12.1388C12.4493 10.6208 13.2735 11.25 14.25 11.25C15.4905 11.25 16.5 10.2405 16.5 9C16.5 7.7595 15.4905 6.75 14.25 6.75ZM10.3612 12.75H15.75C16.1648 12.75 16.5 13.0852 16.5 13.5C16.5 13.9148 16.1648 14.25 15.75 14.25H10.3612C10.0507 15.1208 9.2265 15.75 8.25 15.75C7.2735 15.75 6.44925 15.1208 6.13875 14.25H2.25C1.83525 14.25 1.5 13.9148 1.5 13.5C1.5 13.0852 1.83525 12.75 2.25 12.75H6.13875C6.44925 11.8792 7.2735 11.25 8.25 11.25C9.2265 11.25 10.0507 11.8792 10.3612 12.75Z'
        />
      </mask>
      <g mask='url(#mask0_213_2853)'>
        <rect width='18' height='18' />
      </g>
    </SvgIcon>
  );
});
