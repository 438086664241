import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  LinearProgress,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import * as Yup from "yup";

import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AssetSubscriptionDto,
  AssetSubscriptionReallocateAssetDto,
  PoolItemStatus,
} from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import FieldValue from "../../Form/Display/FieldValue";
import FormActions from "../../Form/FormActions";
import AssetLink from "../Asset/AssetLink";
import AssetSubscriptionPlanLink from "../AssetSubscriptionPlan/AssetSubscriptionPlanLink";
import PoolAutocomplete from "../Pool/PoolAutocomplete";
import PoolLink from "../Pool/PoolLink";
import PoolItemAutocomplete from "../PoolItem/PoolItemAutocomplete";
import PoolItemLink from "../PoolItem/PoolItemLink";
import AssetSubscriptionLink from "./AssetSubscriptionLink";

type DefaultValues = Partial<AssetSubscriptionReallocateAssetDto>;

export interface AssetSubscriptionUpdateAssetOwnProps {
  assetSubscription: AssetSubscriptionDto;
  defaultValues?: DefaultValues;
  onSave?: (newValue: AssetSubscriptionDto) => void;
}

export type AssetSubscriptionUpdateAssetProps = AssetSubscriptionUpdateAssetOwnProps;

export default function AssetSubscriptionUpdateAsset({
  assetSubscription,
  defaultValues,
  onSave,
}: AssetSubscriptionUpdateAssetProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  const assetSubscriptionPlanRequest = useApiRequest(
    apiClient.assetSubscriptionPlansApi.apiV1AssetSubscriptionPlansAssetSubscriptionPlanIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetSubscriptionPlanId: assetSubscription.plan?.id || "",
    },
    {
      skip: !assetSubscription.plan?.id,
      deps: [assetSubscription.plan?.id],
    },
  );
  const assetSubscriptionPlan = assetSubscriptionPlanRequest?.data;

  const canUpdate = !!assetSubscription?.asset?.asset?.id;
  const disabled = !canUpdate;

  return (
    <Stack spacing={2}>
      {assetSubscriptionPlanRequest.isLoading && <LinearProgress />}

      {!assetSubscriptionPlanRequest.isLoading && assetSubscriptionPlanRequest.isFirstEnded && (
        <>
          {assetSubscription.asset?.asset && assetSubscriptionPlan && (
            <Alert severity='info'>
              <Box>
                The asset <AssetLink entity={assetSubscription.asset?.asset} /> for subscription{" "}
                <AssetSubscriptionLink entity={assetSubscription} /> was allocated from underlying
                pools of the plan <AssetSubscriptionPlanLink entity={assetSubscriptionPlan} />.
              </Box>
              <Box>
                Please select different asset you want to allocate for this subscription. The
                current asset will be released.
              </Box>
            </Alert>
          )}
          <Formik<AssetSubscriptionReallocateAssetDto & BaseFormikValues>
            enableReinitialize
            initialValues={{
              newPoolId:
                defaultValues?.newPoolId || assetSubscription?.asset?.poolItem?.poolId || undefined,
              newPoolItemId:
                defaultValues?.newPoolItemId || assetSubscription?.asset?.poolItem?.id || undefined,
              isConfirmed: defaultValues?.isConfirmed ?? undefined,
              submit: "",
            }}
            validationSchema={Yup.object().shape({
              // vehicleId: Yup.string().required("Vehicle is required"),
            })}
            onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
              try {
                const response =
                  await apiClient.assetSubscriptionsApi.apiV1AssetSubscriptionsAssetSubscriptionIdAssetAllocationReallocatePost(
                    {
                      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                      assetSubscriptionId: assetSubscription?.id || "",
                      assetSubscriptionReallocateAssetDto: {
                        ...values,
                      },
                    },
                  );
                enqueueSnackbar("Asset reallocated.", { variant: "success" });
                onSave && onSave(response.data);

                if (mounted.current) {
                  setStatus({ success: true });
                  setSubmitting(false);
                }
              } catch (err: any) {
                if (mounted.current) {
                  ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                  setStatus({ success: false });
                  setSubmitting(false);
                }
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setErrors,
              setFieldValue,
              setValues,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <Stack spacing={2}>
                    <Stack spacing={1}>
                      <FieldValue
                        label='Old pool'
                        direction='column'
                        isEmpty={!assetSubscription?.asset?.poolItem?.poolId}
                      >
                        <PoolLink
                          entity={undefined}
                          entityId={assetSubscription?.asset?.poolItem?.poolId}
                          withTooltip
                        />
                      </FieldValue>

                      <FieldValue
                        label='Old pool item'
                        direction='column'
                        isEmpty={!assetSubscription?.asset?.poolItem?.id}
                      >
                        {assetSubscription?.asset?.poolItem?.poolId && (
                          <PoolItemLink
                            entity={undefined}
                            entityId={assetSubscription?.asset?.poolItem?.id}
                            withTooltip
                          />
                        )}
                      </FieldValue>
                    </Stack>

                    <Box>
                      <FormControl margin='dense' fullWidth>
                        <PoolAutocomplete
                          entityId={values.newPoolId}
                          isPreload
                          required
                          // disabled
                          label='New pool'
                          searchFilters={{
                            ids: assetSubscriptionPlan?.poolIds || undefined,
                          }}
                          textFieldProps={{
                            error: Boolean(errors.newPoolId),
                            helperText: errors.newPoolId,
                          }}
                          onChange={(newValue) => {
                            setFieldValue("newPoolId", newValue?.id);
                            setFieldValue("newPoolItemId", undefined);
                          }}
                        />
                      </FormControl>

                      {values.newPoolId && (
                        <FormControl margin='dense' fullWidth>
                          <PoolItemAutocomplete
                            entityId={values.newPoolItemId}
                            isPreload
                            required
                            disabled={disabled}
                            label='New pool item'
                            searchFilters={{
                              poolId: values.newPoolId || "",
                              status: PoolItemStatus.Available,
                            }}
                            textFieldProps={{
                              error: Boolean(errors.newPoolItemId),
                              helperText: errors.newPoolItemId,
                            }}
                            onChange={(newValue) => {
                              setFieldValue("newPoolItemId", newValue?.id);
                            }}
                          />
                        </FormControl>
                      )}

                      <FormControl margin='dense' fullWidth>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isConfirmed ?? false}
                              onChange={(e) => setFieldValue("isConfirmed", e.target.checked)}
                            />
                          }
                          label='Confirm allocation'
                        />
                        <FormHelperText>Confirm this is the final reallocation.</FormHelperText>
                      </FormControl>
                    </Box>

                    <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                    <FormActions>
                      <LoadingButton
                        color='primary'
                        disabled={disabled}
                        loading={isSubmitting}
                        fullWidth
                        type='submit'
                        variant='contained'
                      >
                        Save
                      </LoadingButton>
                    </FormActions>
                  </Stack>
                </form>
              );
            }}
          </Formik>
        </>
      )}
    </Stack>
  );
}
