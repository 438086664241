import { LoadingButton } from "@mui/lab";
import { FormControl, FormHelperText, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { AxiosResponse } from "axios";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import ApiEnumSelect from "@/common/components/Enum/ApiEnumSelect";
import PercentInput from "@/common/components/Form/Input/PercentInput";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useCurrentCurrency } from "@/common/hooks/useCurrentCurrency";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateRepairWorkDto,
  EntityType,
  RepairWorkDto,
  UpdateRepairWorkDto,
} from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import PriceSummaryInput from "../../PriceSummary/PriceSummaryInput";
import AppTypography from "../../Text/AppTypography";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  name?: CreateRepairWorkDto["name"];
  description?: CreateRepairWorkDto["description"];
  currency?: CreateRepairWorkDto["currency"];
};

export interface CreateUpdateRepairWorkOwnProps
  extends BaseEntityCreateUpdateInheritableProps<RepairWorkDto, DefaultValues> {
  repairWorkId?: string;
  createFunc?: (params: {
    dto: CreateRepairWorkDto;
  }) => Promise<AxiosResponse<RepairWorkDto, unknown>>;
  updateFunc?: (params: {
    repairWorkId: string;
    dto: UpdateRepairWorkDto;
  }) => Promise<AxiosResponse<RepairWorkDto, unknown>>;
}

export type CreateUpdateRepairWorkProps = CreateUpdateRepairWorkOwnProps;

export default function CreateUpdateRepairWork({
  repairWorkId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
  createFunc,
  updateFunc,
}: CreateUpdateRepairWorkProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const currentCurrency = useCurrentCurrency();

  const isCreate = !repairWorkId;
  const isEdit = !!repairWorkId;

  const repairWorkRequest = useApiRequest(
    apiClient.repairWorkApi.apiV1RepairWorkRepairWorkIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      repairWorkId: repairWorkId!,
    },
    {
      skip: !repairWorkId,
    },
  );
  const repairWork = repairWorkRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: repairWork && {
      idValue: repairWork.id!,
      newTitle: repairWork.localNumber || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.RepairWork}
      entityId={repairWorkId}
      entity={repairWork}
      entityRequest={repairWorkRequest}
    >
      <Formik<
        BaseFormikValues &
          CreateRepairWorkDto &
          UpdateRepairWorkDto & {
            total?: number;
          }
      >
        enableReinitialize={isEdit}
        initialValues={{
          repairCatalogId: repairWork?.repairCatalogId || undefined,
          name: defaultValues?.name || repairWork?.name || undefined,
          description: defaultValues?.description || repairWork?.description || undefined,
          currency: defaultValues?.currency || repairWork?.currency || currentCurrency || undefined,
          unit: repairWork?.unit || undefined,
          price: repairWork?.price ?? 0,
          expendablesPercent: repairWork?.expendablesPercent ?? 0,
          discount: repairWork?.discount || undefined,
          tax: repairWork?.tax || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string().required("This field is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = createFunc
                ? await createFunc({
                    dto: {
                      ..._.omit(values, "submit"),
                    },
                  })
                : await apiClient.repairWorkApi.apiV1RepairWorkPost({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    createRepairWorkDto: {
                      ..._.omit(values, "submit"),
                    },
                  });
              enqueueSnackbar("Repair work created.", { variant: "success" });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = updateFunc
                ? await updateFunc({
                    repairWorkId,
                    dto: {
                      ..._.omit(values, "submit"),
                    },
                  })
                : await apiClient.repairWorkApi.apiV1RepairWorkRepairWorkIdPut({
                    nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                    repairWorkId,
                    updateRepairWorkDto: {
                      ..._.omit(values, "submit"),
                    },
                  });
              enqueueSnackbar("Repair work updated.", { variant: "success" });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Box>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  required
                  helperText={touched.name && errors.name}
                  label='Name'
                  margin='dense'
                  name='name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.name || ""}
                  variant='outlined'
                />

                <AppTextArea
                  error={Boolean(touched.description && errors.description)}
                  fullWidth
                  helperText={touched.description && errors.description}
                  mode='description'
                  margin='dense'
                  name='description'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.description || ""}
                  variant='outlined'
                />

                <FormControl fullWidth margin='dense' error={Boolean(touched.unit && errors.unit)}>
                  <InputLabel required>Unit</InputLabel>
                  <ApiEnumSelect
                    type='MeasurementUnit'
                    value={values.unit}
                    onChange={(newValue) => setFieldValue("unit", newValue)}
                    selectProps={{
                      label: "Unit",
                      required: true,
                    }}
                  />
                  <FormHelperText>{touched.unit && errors.unit}</FormHelperText>
                </FormControl>

                <PercentInput
                  error={Boolean(touched.expendablesPercent && errors.expendablesPercent)}
                  fullWidth
                  required
                  helperText={touched.expendablesPercent && errors.expendablesPercent}
                  label={
                    <AppTypography
                      component='span'
                      isInheritFontStyle
                      decoration={{ variant: "helpText" }}
                      tooltipProps={{
                        title:
                          "Percentage of expendables included in the final price. I.e. SubTotal = Price + (Price * ExpendablesPercent).",
                      }}
                    >
                      Included expendables
                    </AppTypography>
                  }
                  margin='dense'
                  name={`expendablesPercent`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.expendablesPercent ?? 0}
                  variant='outlined'
                />

                <FormControl fullWidth margin='dense'>
                  <PriceSummaryInput
                    values={{
                      currency: values.currency,
                      subTotal: values.price,
                      discount: values.discount,
                      tax: values.tax,
                      total: values.total,
                    }}
                    formikProps={{
                      errors: {
                        subTotal: errors.price,
                        discount: errors.discount,
                        tax: errors.tax,
                        total: errors.total,
                      },
                    }}
                    displayProps={{
                      insurance: false,
                      calcExplanationInline: false,
                      calcExplanationModal: true,
                    }}
                    inputsProps={{
                      all: {
                        margin: "none",
                      },
                      subTotal: {
                        label: "Price",
                        required: true,
                      },
                      total: {
                        required: true,
                      },
                    }}
                    onChange={(newValue) => {
                      setFieldValue(`currency`, newValue?.currency);
                      setFieldValue(`price`, newValue?.subTotal);
                      setFieldValue(`discount`, newValue?.discount);
                      setFieldValue(`tax`, newValue?.tax);
                      setFieldValue(`total`, newValue?.total);
                    }}
                  />
                </FormControl>
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <FormActions>
                <LoadingButton
                  color='primary'
                  loading={isSubmitting}
                  fullWidth
                  type='submit'
                  variant='contained'
                >
                  Save
                </LoadingButton>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
