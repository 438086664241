import { Chip, Stack } from "@mui/material";
import _ from "lodash";

import DataTabular from "@/common/components/DataTabular/DataTabular";
import VersionDisplay from "@/common/components/Display/VersionDisplay";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import InlineApiEnumValueList from "@/common/components/Enum/InlineApiEnumValueList";
import AppTypography from "@/common/components/Text/AppTypography";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  DataUpdatesHubClientMethodName,
  EntityType,
  VehicleVisualModelGetPaginatedDto,
} from "@/core/api/generated";

import VehicleGenerationInline from "../../ReferenceData/VehicleGeneration/VehicleGenerationInline";
import VehicleMakeInline from "../../ReferenceData/VehicleMake/VehicleMakeInline";
import VehicleModelInline from "../../ReferenceData/VehicleModel/VehicleModelInline";
import VehicleModificationInline from "../../ReferenceData/VehicleModification/VehicleModificationInline";

export default function VehicleVisualModelsPaginatedList() {
  const currentTenant = useCurrentTenant();
  const commonRequestParams = useCommonRequestParams<VehicleVisualModelGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.VehicleVisualModel,
    },
  });

  const paginatedVehicleVisualModelRequest = useApiRequest(
    apiClient.vehicleVisualModelsApi.apiV1ReferenceDataVehiclesVisualModelsGetPost,
    {
      vehicleVisualModelGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );
  const paginatedVehicleVisualModel = paginatedVehicleVisualModelRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.VehicleVisualModel),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedVehicleVisualModelRequest.handleEntityChanged(data);
    },
  });

  return (
    <DataTabular
      columns={[
        {
          field: "name",
          title: "Name",
          flex: 1,
          renderCell: (item) => (
            <Stack direction='row' spacing={0.5} alignItems='center'>
              <AppTypography ellipsing={{ enabled: true }}>{item.name}</AppTypography>

              {item.isDefault && (
                <Chip variant='filled' size='small' color='primary' label='Default' />
              )}

              <EntityChipList entity={item} variant='compact' />
            </Stack>
          ),
        },
        {
          field: "version",
          title: "Version",
          flex: 1,
          renderCell: (item) => <VersionDisplay value={item.version} />,
        },
        {
          field: "vehicleTypes",
          title: "Vehicle types",
          flex: 1,
          renderCell: (item) => (
            <>
              {!_.isEmpty(item.vehicleTypes) ? (
                <InlineApiEnumValueList
                  type='VehicleType'
                  values={item.vehicleTypes}
                  direction='row'
                />
              ) : (
                "-"
              )}
            </>
          ),
        },
        {
          field: "bodyTypes",
          title: "Body types",
          flex: 1,
          renderCell: (item) => (
            <>
              {!_.isEmpty(item.bodyTypes) ? (
                <InlineApiEnumValueList
                  type='VehicleBodyType'
                  values={item.bodyTypes}
                  direction='row'
                />
              ) : (
                "-"
              )}
            </>
          ),
        },
        {
          field: "makes",
          title: "Makes",
          flex: 1,
          renderCell: (item) => (
            <Stack>
              {!_.isEmpty(item.makes)
                ? item.makes?.map((x, i) => <VehicleMakeInline key={i} make={x} />)
                : "-"}
            </Stack>
          ),
        },
        {
          field: "models",
          title: "Models",
          flex: 1,
          renderCell: (item) => (
            <Stack>
              {!_.isEmpty(item.models)
                ? item.models?.map((x, i) => <VehicleModelInline key={i} model={x} />)
                : "-"}
            </Stack>
          ),
        },
        {
          field: "generations",
          title: "Generations",
          flex: 1,
          renderCell: (item) => (
            <Stack>
              {!_.isEmpty(item.generations)
                ? item.generations?.map((x, i) => (
                    <VehicleGenerationInline key={i} generation={x} />
                  ))
                : "-"}
            </Stack>
          ),
        },
        {
          field: "modifications",
          title: "Modifications",
          flex: 1,
          renderCell: (item) => (
            <Stack>
              {!_.isEmpty(item.modifications)
                ? item.modifications?.map((x, i) => (
                    <VehicleModificationInline key={i} modification={x} />
                  ))
                : "-"}
            </Stack>
          ),
        },
        {
          field: "areas",
          title: "Areas",
          flex: 1,
          renderCell: (item) => (
            <>
              {!_.isEmpty(item.areas) ? (
                <InlineApiEnumValueList type='VehicleArea' values={item.areas} direction='column' />
              ) : (
                "-"
              )}
            </>
          ),
        },
        {
          field: "projections",
          title: "Projections",
          flex: 1,
          renderCell: (item) => (
            <>
              {!_.isEmpty(item.projections) ? (
                <InlineApiEnumValueList
                  type='VehicleProjection'
                  values={item.projections}
                  direction='row'
                />
              ) : (
                "-"
              )}
            </>
          ),
        },
      ]}
      rows={paginatedVehicleVisualModel?.items}
      isLoading={paginatedVehicleVisualModelRequest.isLoading}
      getRowId={(item) => item.id!}
      rowTo={(item) =>
        ROUTE_PATH.VEHICLE_VISUAL_MODEL_VIEW({ vehicleVisualModelId: item.id || "" })
      }
      statePersistence={commonRequestParams.dataTabularProps.statePersistence}
      pagination={commonRequestParams.dataTabularProps.pagination}
      sort={commonRequestParams.dataTabularProps.sort}
      quickFilter={commonRequestParams.dataTabularProps.quickFilter}
      filters={commonRequestParams.dataTabularProps.filters}
      refetch={commonRequestParams.dataTabularProps.refetch}
    />
  );
}
