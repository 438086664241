import { FormControl } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { WidgetHelper } from "@/common/helpers/widget";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";

import AdminTenantAutocomplete from "../../Entity/Tenant/AdminTenantAutocomplete";
import AppIcon from "../../Icons/AppIcon";
import AppTypography from "../../Text/AppTypography";
import BaseWidget from "../Base/BaseWidget";
import WidgetContent from "../Base/WidgetContent";
import WidgetHeader from "../Base/WidgetHeader";
import WidgetTitle from "../Base/WidgetTitle";
import WidgetTitleDefaultActions from "../Base/WidgetTitleDefaultActions";
import CounterWidgetCard from "../General/Counter/CounterWidgetCard";
import CounterWidgetCardGrid from "../General/Counter/CounterWidgetCardGrid";
import CounterWidgetContent from "../General/Counter/CounterWidgetContent";

export default function AdminTenantSummaryWidget() {
  const [tenantId, setTenantId] = useState<string | undefined>(undefined);

  const dataRequest = useApiRequest(
    apiClient.adminWidgetDataApi.apiV1AdminWidgetDataTenantSummaryGetPost,
    {
      adminWidgetDataOfTenantSummaryGetDto: {
        tenantId: tenantId,
      },
    },
    {
      deps: [tenantId],
    },
  );
  const data = dataRequest?.data;

  useEffect(() => {
    // Set the tenantId to the first tenantId in the list if there is only one tenantId
    if (data && data.tenantIds?.length === 1) {
      setTenantId(data.tenantIds[0]);
    }
  }, [data]);

  useEffect(() => {
    return WidgetHelper.handleRefetchAndRecomputeDataRequestInEffect((requestParams) => {
      dataRequest.refetchWithNewParams({
        adminWidgetDataOfTenantSummaryGetDto: {
          tenantId: tenantId,
          ...requestParams,
        },
      });
    });
  }, [dataRequest, tenantId]);

  const handleTenantChange = useCallback((id: string | undefined) => {
    setTenantId(id);
  }, []);

  return (
    <BaseWidget>
      <WidgetHeader>
        <WidgetTitle
          icon={<AppIcon of='dataConnected' />}
          actions={
            <WidgetTitleDefaultActions
              data={data}
              onRecompute={async (requestParams) => {
                await dataRequest.refetchWithNewParams({
                  adminWidgetDataOfTenantSummaryGetDto: {
                    tenantId: tenantId,
                    ...requestParams,
                  },
                });
              }}
            />
          }
        >
          Tenant Summary
        </WidgetTitle>
        <FormControl fullWidth margin='dense'>
          <AdminTenantAutocomplete
            entityId={tenantId}
            onChange={(tenant) => handleTenantChange(tenant?.id)}
          />
        </FormControl>
      </WidgetHeader>
      <WidgetContent>
        <CounterWidgetCardGrid>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of vehicles" }}
                >
                  Vehicles
                </AppTypography>
              }
              icon={<AppIcon of='vehicle' />}
              isLoading={dataRequest.isLoading}
              counter={data?.vehicle?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of contracts" }}
                >
                  Contracts
                </AppTypography>
              }
              icon={<AppIcon of='contract' />}
              isLoading={dataRequest.isLoading}
              counter={data?.contract?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of assessments" }}
                >
                  Assessments
                </AppTypography>
              }
              icon={<AppIcon of='assessmentFlow' />}
              isLoading={dataRequest.isLoading}
              counter={data?.assessment?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of damage detections" }}
                >
                  Damage detections
                </AppTypography>
              }
              icon={<AppIcon of='damageDetections' />}
              isLoading={dataRequest.isLoading}
              counter={data?.damageDetection?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of damage cost evaluations" }}
                >
                  Cost evaluations
                </AppTypography>
              }
              icon={<AppIcon of='damageCostEvaluation' />}
              isLoading={dataRequest.isLoading}
              counter={data?.damageCostEvaluation?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of repairs" }}
                >
                  Repairs
                </AppTypography>
              }
              icon={<AppIcon of='repair' />}
              isLoading={dataRequest.isLoading}
              counter={data?.repair?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of visual inspection" }}
                >
                  Visual inspections
                </AppTypography>
              }
              icon={<AppIcon of='visualInspections' />}
              isLoading={dataRequest.isLoading}
              counter={data?.visualInspection?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of maintenances" }}
                >
                  Maintenances
                </AppTypography>
              }
              icon={<AppIcon of='maintenance' />}
              isLoading={dataRequest.isLoading}
              counter={data?.maintenance?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of wheel operations" }}
                >
                  Wheel operations
                </AppTypography>
              }
              icon={<AppIcon of='wheelOperation' />}
              isLoading={dataRequest.isLoading}
              counter={data?.wheelOperation?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of washes" }}
                >
                  Washes
                </AppTypography>
              }
              icon={<AppIcon of='wash' />}
              isLoading={dataRequest.isLoading}
              counter={data?.wash?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of assignments" }}
                >
                  Assignments
                </AppTypography>
              }
              icon={<AppIcon of='data' />}
              isLoading={dataRequest.isLoading}
              counter={data?.assignment?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of company connections" }}
                >
                  Connections
                </AppTypography>
              }
              icon={<AppIcon of='tenantConnection' />}
              isLoading={dataRequest.isLoading}
              counter={data?.tenantConnection?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of company requests" }}
                >
                  C2C requests
                </AppTypography>
              }
              icon={<AppIcon of='tenantRequest' />}
              isLoading={dataRequest.isLoading}
              counter={data?.tenantRequest?.count}
            />
          </CounterWidgetCard>
          <CounterWidgetCard>
            <CounterWidgetContent
              title={
                <AppTypography
                  isInheritFontStyle
                  decoration={{ variant: "helpText" }}
                  tooltipProps={{ title: "Total number of users" }}
                >
                  Users
                </AppTypography>
              }
              icon={<AppIcon of='user' />}
              isLoading={dataRequest.isLoading}
              counter={data?.user?.count}
            />
          </CounterWidgetCard>
        </CounterWidgetCardGrid>
      </WidgetContent>
    </BaseWidget>
  );
}
