import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { getIn, useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import CreateUpdatePageLayout from "@/App/Layouts/Pages/CreateUpdatePageLayout";
import CollapseItem from "@/common/components/CollapseItem/CollapseItem";
import ImportEntityTypeSelect from "@/common/components/Entity/Import/ImportEntityTypeSelect";
import ImportMappingSelect from "@/common/components/Entity/Import/ImportMappingSelect";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import FileUploader from "@/common/components/Files/FileUploader";
import CountTile from "@/common/components/Form/Display/CountTile";
import AppIcon from "@/common/components/Icons/AppIcon";
import HelpTooltipIcon from "@/common/components/Icons/HelpTooltipIcon";
import { ROUTE_PATH } from "@/common/constants/routing";
import { FileItem } from "@/common/fileItem";
import { MimeTypeHelper } from "@/common/helpers/mimeType";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { usePreviousValue } from "@/common/hooks/usePreviousValue";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ImportEntityType,
  ImportResultDto,
  ImportRowResultStatus,
  ImportStatus,
  LaunchImportDto,
  SubmitMappingDto,
  SubmitUploadDto,
} from "@/core/api/generated";

import ExampleImportFileDownloadModal from "./components/ExampleImportFileDownload/ExampleImportFileDownloadModal";
import ProblemRowsViewModal from "./components/ProblemRowsView/ProblemRowsViewModal";
import SpreadsheetDataViewModal from "./components/SpreadsheetDataViewModal/SpreadsheetDataViewModal";

export interface ImportCreateUpdateQueryParams extends GeneralQueryParams {
  entityType: ImportEntityType;
}

interface ImportDataFormValues extends BaseFormikValues {
  submitUploadModel: SubmitUploadDto;
  submitMappingModel: SubmitMappingDto;
  launchImportModel: LaunchImportDto;
}

enum ImportTab {
  UploadData = "UploadData",
  MappingConfiguration = "MappingConfiguration",
  PreLaunchConfiguration = "PreLaunchConfiguration",
}

export default function CreateUpdateImportPage() {
  const { importId } = useParams<{ importId?: string }>();
  const mounted = useMounted();
  const history = useHistory();
  const queryParams = useQueryParams<{ entityType: ImportEntityType }>();

  const { enqueueSnackbar } = useAppSnackbar();

  const [isExampleImportFileModalOpened, setIsExampleImportFileModalOpened] = useState(false);
  const [isSpreadsheetDataViewModalOpened, setIsSpreadsheetDataViewModalOpened] = useState(false);

  // used for fetching import entity
  // it can be entityId from props or newly created importId via "Create new import" button
  const importEntityIdRef = useRef<string | undefined>(importId);
  // indicates whether new import is being created
  const [newImportIsCreating, setNewImportIsCreating] = useState(false);
  // used for fetching mapping template selected in MappingTemplateSelect
  const [mappingTemplateId] = useState<string | undefined>(undefined);
  const [mappingTemplateIsLoading, setMappingTemplateIsLoading] = useState(false);
  const [mappingTemplateError, setMappingTemplateError] = useState<string | undefined>(undefined);
  // used for handling current collapsing tab on this page
  const allowedTabsRef = useRef<ImportTab[]>([ImportTab.UploadData]);
  const [currentTab, setCurrentTab] = useState<ImportTab>(ImportTab.UploadData);
  // used file columns
  const usedFileColumnsRef = useRef<{ [key: string]: boolean }>({});

  const handleChangeCurrentTab = (tab: ImportTab) => {
    if (!allowedTabsRef.current.includes(tab)) {
      return;
    }
    setCurrentTab(tab);
  };

  const handleAllowedTabs = (importStatus: ImportStatus) => {
    if (importStatus === ImportStatus.DataUploading) {
      allowedTabsRef.current = [ImportTab.UploadData];
    }
    if (importStatus === ImportStatus.MappingConfiguration) {
      allowedTabsRef.current = [ImportTab.UploadData, ImportTab.MappingConfiguration];
    }
    if (
      importStatus === ImportStatus.PreLaunchConfiguration ||
      importStatus === ImportStatus.Completed ||
      importStatus == ImportStatus.Failed
    ) {
      allowedTabsRef.current = [
        ImportTab.UploadData,
        ImportTab.MappingConfiguration,
        ImportTab.PreLaunchConfiguration,
      ];
    }
    if (importStatus == ImportStatus.Failed) {
      allowedTabsRef.current = [ImportTab.UploadData, ImportTab.MappingConfiguration];
    }
  };

  const handleChangeCurrentTabByImportStatus = (importStatus: ImportStatus) => {
    handleAllowedTabs(importStatus);
    handleChangeCurrentTab(allowedTabsRef.current.at(allowedTabsRef.current.length - 1)!);
  };

  const importRequest = useApiRequest(
    apiClient.dataImportApi.apiV1DataImportImportIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      importId: importEntityIdRef.current!,
    },
    {
      deps: [importEntityIdRef.current],
      skip: !importEntityIdRef.current,
    },
  );

  const prevImportRequestData = usePreviousValue(importRequest.data);

  const importSpecRequest = useApiRequest(
    apiClient.dataImportApi.apiV1DataImportSpecGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      importEntityType: importRequest.data?.entityType,
    },
    {
      deps: [importRequest.data?.entityType],
      skip:
        !importRequest.data ||
        !importRequest.data.entityType ||
        importRequest.data.entityType === ImportEntityType.None,
    },
  );

  const createImportIfNotExists = useCallback(async () => {
    if (newImportIsCreating) return;
    if (!importId && !importEntityIdRef.current) {
      setNewImportIsCreating(true);
      const createdImportResponse = await apiClient.dataImportApi.apiV1DataImportPost({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      });
      importEntityIdRef.current = createdImportResponse.data.id!;
      setNewImportIsCreating(false);
    }
  }, []);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setSubmitting,
    setStatus,
    isSubmitting,
  } = useFormik<ImportDataFormValues>({
    enableReinitialize: false,
    initialValues: {
      submitUploadModel: {
        importId: undefined,
        importEntityType: queryParams.entityType || undefined,
        attachment: undefined,
      },
      submitMappingModel: {
        importId: /* importRequest.data?.id || */ undefined,
        mappingName: undefined,
        shouldSaveMappingForFuture: false,
        mappings: /* importRequest.data?.mapping?.mappings || */ [],
      },
      launchImportModel: {
        importId: /* importRequest.data?.id || */ undefined,
        shouldUpdateEntityIfExists: false,
      },
      submit: "",
    },
    onSubmit: async () => {
      if (currentTab === ImportTab.UploadData) {
        /**
         * Stage: Upload data
         */
        try {
          const importResponse = await apiClient.dataImportApi.apiV1DataImportUploadSubmitPost({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            submitUploadDto: {
              importId: importRequest.data?.id,
              importEntityType: values.submitUploadModel.importEntityType,
              attachment: values.submitUploadModel.attachment,
            },
          });
          // set initial mappings
          setFieldValue("submitMappingModel.mappings", importResponse.data?.mapping?.mappings);
          importRequest.refetch();

          setFieldError("submitUploadModel.importEntityType", undefined);
          setFieldError("submitUploadModel.attachment", undefined);
          setFieldError("submit", undefined);

          setCurrentTab(ImportTab.MappingConfiguration);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
        return;
      }
      if (currentTab == ImportTab.MappingConfiguration) {
        /**
         * Stage: Mapping configuration
         */
        try {
          await apiClient.dataImportApi.apiV1DataImportMappingSubmitPost({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            submitMappingDto: {
              importId: importRequest.data?.id,
              mappingName: values.submitMappingModel.mappingName,
              shouldSaveMappingForFuture: values.submitMappingModel.shouldSaveMappingForFuture,
              mappings: values.submitMappingModel.mappings,
            },
          });
          importRequest.refetch();

          setMappingTemplateError(undefined);
          setFieldError("submitMappingModel.mappingName", undefined);
          setFieldError("submitMappingModel.shouldSaveMappingForFuture", undefined);
          setFieldError("submitMappingModel.mappings", undefined);
          setFieldError("submit", undefined);

          setCurrentTab(ImportTab.PreLaunchConfiguration);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
        return;
      }
      if (currentTab === ImportTab.PreLaunchConfiguration) {
        /**
         * Stage: Pre launch
         */
        try {
          await apiClient.dataImportApi.apiV1DataImportLaunchPost({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            launchImportDto: {
              importId: importRequest.data?.id,
              shouldUpdateEntityIfExists: values.launchImportModel.shouldUpdateEntityIfExists,
            },
          });

          const importResponse = await apiClient.dataImportApi.apiV1DataImportImportIdGet({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            importId: importRequest.data?.id || "",
          });

          if (importResponse.data.status === ImportStatus.Failed) {
            const error = importResponse.data.result?.error;
            if (error) {
              setFieldError("submit", error);
            }
            enqueueSnackbar("Import failed", {
              variant: "error",
            });
          } else {
            setFieldError("launchImportModel.shouldUpdateEntityIfExists", undefined);
            setFieldError("submit", undefined);
            enqueueSnackbar("Data import successfully enqueued", { variant: "success" });
          }

          history.replace(ROUTE_PATH.MANAGEMENT_IMPORTS_VIEW(importRequest.data?.id));

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }

        return;
      }
    },
  });

  // region: Import result simulation
  const importSimulationResultRef = useRef<ImportResultDto | null | undefined>(undefined);
  const [isImportSimulationInProgress, setIsImportSimulationInProgress] = useState(false);
  const [isProblemRowsViewModalOpened, setIsProblemRowsViewModalOpened] = useState(false);
  const resetSimulationResult = useCallback(() => {
    importSimulationResultRef.current = undefined;
  }, []);
  const loadImportSimulationResult = useCallback(async () => {
    setIsImportSimulationInProgress(true);
    try {
      const simulationResult = await apiClient.dataImportApi.apiV1DataImportLaunchSimulatePost({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        launchImportDto: {
          importId: importEntityIdRef.current!,
          shouldUpdateEntityIfExists: values.launchImportModel.shouldUpdateEntityIfExists,
        },
      });
      importSimulationResultRef.current = simulationResult.data;
    } catch (err) {
      ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
    }
    setIsImportSimulationInProgress(false);
  }, [values.launchImportModel]);

  useEffect(() => {
    // if somewhat in inmport configuration changed then reset simulation result
    // as it is not actual anymore
    resetSimulationResult();
  }, [values.submitUploadModel, values.submitMappingModel, values.launchImportModel]);

  const importSimulationComputedProps = useMemo(() => {
    const result = {
      createdEntities: 0,
      updatedEntities: 0,
      skippedEntities: 0,
      failedRows: 0,
    };
    if (importSimulationResultRef.current) {
      result.createdEntities =
        importSimulationResultRef.current?.rows?.filter(
          (r) => r.status === ImportRowResultStatus.Created,
        ).length || 0;
      result.updatedEntities =
        importSimulationResultRef.current?.rows?.filter(
          (r) => r.status === ImportRowResultStatus.Updated,
        ).length || 0;
      result.skippedEntities =
        importSimulationResultRef.current?.rows?.filter(
          (r) => r.status === ImportRowResultStatus.Skipped,
        ).length || 0;
      result.failedRows =
        importSimulationResultRef.current?.rows?.filter(
          (r) => r.status === ImportRowResultStatus.Failed,
        ).length || 0;
    }
    return result;
  }, [importSimulationResultRef.current]);
  // endregion: Import result simulation

  useEffect(() => {
    // if import fetched for first time and importId is passed through page location
    if (importRequest.data && !prevImportRequestData && importId) {
      setFieldValue("submitUploadModel.importId", importRequest.data?.id);
      setFieldValue(
        "submitUploadModel.importEntityType",
        importRequest.data?.entityType || undefined,
      );
      setFieldValue("submitUploadModel.attachment", importRequest.data?.attachment || undefined);
      setFieldValue("submitMappingModel.importId", importRequest.data?.id);
      setFieldValue("submitMappingModel.mappings", importRequest.data?.mapping?.mappings || []);
      setFieldValue("launchImportModel.importId", importRequest.data?.id);
    }
  }, [importRequest.data, prevImportRequestData]);

  useEffect(() => {
    if (
      (!!values.submitUploadModel.importEntityType &&
        values.submitUploadModel.importEntityType !== ImportEntityType.None) ||
      !!values.submitUploadModel.attachment
    ) {
      createImportIfNotExists();
    }
  }, [values.submitUploadModel]);

  const fileColumns = useMemo(() => {
    if (importRequest.data) {
      return importRequest.data?.fileMetadata?.columns?.map((x) => x.name!) || [];
    }
    return [];
  }, [importRequest.data]);

  useEffect(() => {
    usedFileColumnsRef.current =
      fileColumns?.reduce(
        (acc, x) => ({
          ...acc,
          [x]: values.submitMappingModel.mappings?.some((y) => y.source === x),
        }),
        {},
      ) || {};
  }, [fileColumns, values.submitMappingModel.mappings]);

  useEffect(() => {
    if (importRequest.data) {
      handleAllowedTabs(importRequest.data.status!);
      handleChangeCurrentTabByImportStatus(importRequest.data.status!);
      // mappings in submit model are empty but import has mappings
      // then we need to set mappings from import to submit model
      if (
        values.submitMappingModel.mappings?.length === 0 &&
        importRequest.data.mapping?.mappings?.length !== 0
      ) {
        setFieldValue("submitMappingModel.mappings", importRequest.data.mapping?.mappings);
      }
    }
  }, [importRequest.data]);

  if (importRequest.data && importRequest.data.status === ImportStatus.Processing) {
    return (
      <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Stack spacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
          <Typography variant='h6' color='primary'>
            Import is already in progress
          </Typography>
          <Typography variant='body1'>Please wait until import is finished</Typography>
        </Stack>
      </Box>
    );
  }

  if (importRequest.data && importRequest.data.status === ImportStatus.Completed) {
    return (
      <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Stack spacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
          <Typography variant='h6' color='primary'>
            Import is already done and cannot be edited
          </Typography>
          <Typography variant='body1'>Go to the import view page to see results</Typography>
          <Button
            variant='contained'
            onClick={() =>
              history.replace(ROUTE_PATH.MANAGEMENT_IMPORTS_VIEW(importEntityIdRef.current!))
            }
          >
            Go to import
          </Button>
        </Stack>
      </Box>
    );
  }
  return (
    <CreateUpdatePageLayout
      header={<SimpleViewPageHeader title={!importId ? "Create new import" : "Edit import"} />}
    >
      <Stack direction='row' sx={{ justifyContent: "flex-end", mb: 2 }}>
        {/* Download example import file modal*/}
        <ExampleImportFileDownloadModal
          open={isExampleImportFileModalOpened}
          onClose={() => setIsExampleImportFileModalOpened(false)}
        />
        <Button variant='contained' onClick={() => setIsExampleImportFileModalOpened(true)}>
          Download example file
        </Button>
      </Stack>

      <Stack direction='column' spacing={2}>
        <CollapseItem
          iconOf='export'
          label='Upload data source'
          in={currentTab === ImportTab.UploadData}
          onClick={() => handleChangeCurrentTab(ImportTab.UploadData)}
          unmountOnExit
          collapseContent={
            <>
              {importRequest.data && (
                <Stack direction='row' spacing={1} sx={{ mt: 1 }}>
                  {importRequest.data?.entityType && (
                    <Chip
                      label={
                        <InlineApiEnumValue
                          type='ImportEntityType'
                          value={importRequest.data?.entityType}
                        />
                      }
                      variant='outlined'
                    />
                  )}
                  {importRequest.data.attachment && (
                    <Chip
                      label={importRequest.data.attachment.file?.originalFileName}
                      variant='outlined'
                    />
                  )}
                </Stack>
              )}
            </>
          }
        >
          <Stack direction='column' sx={{ p: 2, alignItems: "center" }} spacing={1}>
            <FormControl
              sx={{ minWidth: 400 }}
              error={Boolean(
                getIn(touched, "submitUploadModel.importEntityType") &&
                  getIn(errors, "submitUploadModel.importEntityType"),
              )}
            >
              <InputLabel required>Import entity type</InputLabel>
              <ImportEntityTypeSelect
                importEntityType={values.submitUploadModel.importEntityType}
                required
                onChange={async (e, et) => {
                  handleChange(e);
                  setFieldValue("submitUploadModel.importEntityType", et);
                }}
                selectProps={{
                  onBlur: handleBlur,
                }}
              />
              <FormHelperText
                error={Boolean(
                  getIn(touched, "submitUploadModel.importEntityType") &&
                    getIn(errors, "submitUploadModel.importEntityType"),
                )}
              >
                {getIn(errors, "submitUploadModel.importEntityType")}
              </FormHelperText>
            </FormControl>
            <FormControl>
              {/** If we are editing import then
               * we should wait loading import until mounting the FileUploader
               * to make sure attachment will be passed as props if exists */}
              {(!importId || (importId && importRequest.data)) && (
                <FileUploader
                  // .xlsx,.xls,.csv,.ods
                  accept={MimeTypeHelper.getSpreadsheetFormats()
                    .map((f) => f.mimeType)
                    .join(",")}
                  acceptText='Select .xls, .xlsx, .csv or .ods file'
                  defaultFiles={
                    importRequest.data?.attachment
                      ? FileItem.createManyFrom([importRequest.data.attachment.file!])
                      : undefined
                  }
                  uploadFilesFunc={async (params) => {
                    const result = await apiClient.filesApi.apiV1FilesImportUploadPost({
                      files: params.files,
                    });
                    return result.data;
                  }}
                  onChange={(newFiles) => {
                    if (newFiles.length === 0) {
                      setFieldValue("submitUploadModel.attachment", null);
                      return;
                    }
                    setFieldValue(
                      "submitUploadModel.attachment",
                      FileItem.toGeneralAttachmentInputDto(newFiles[0]),
                    );
                  }}
                  sx={{ minWidth: 400, mb: 2 }}
                />
              )}
              <FormHelperText
                error={Boolean(
                  getIn(touched, "submitUploadModel.attachment") &&
                    getIn(errors, "submitUploadModel.attachment"),
                )}
              >
                {getIn(errors, "submitUploadModel.attachment")}
              </FormHelperText>
            </FormControl>

            <GeneralValidationError sx={{ my: 1 }} errors={errors} />

            <Stack direction='row' sx={{ alignItems: "center" }}>
              <LoadingButton
                type='submit'
                variant='contained'
                disabled={
                  !values.submitUploadModel.attachment || !values.submitUploadModel.importEntityType
                }
                loading={isSubmitting}
                onClick={() => {
                  handleSubmit();
                }}
                sx={{ minWidth: 200 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </CollapseItem>
        <CollapseItem
          iconOf='settings'
          label='Mapping configuration'
          in={currentTab === ImportTab.MappingConfiguration}
          onClick={() => handleChangeCurrentTab(ImportTab.MappingConfiguration)}
          unmountOnExit
          collapseContent={
            <Grid container rowSpacing={1} columnSpacing={1} sx={{ alignItems: "center", mt: 1 }}>
              {values.submitMappingModel.mappings?.length !== 0 &&
                values.submitMappingModel.mappings?.map((m) => (
                  <React.Fragment key={(m.source || "") + (m.target || "")}>
                    <Grid item xs={3} md={4}>
                      {!m.isIgnored && (
                        <Chip
                          label={m.source || m.defaultValue}
                          variant='outlined'
                          sx={{ width: "fill-available" }}
                        />
                      )}
                      {m.isIgnored && (
                        <Chip
                          label={m.target}
                          variant='outlined'
                          sx={{ width: "fill-available" }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3} md={1} sx={{ textAlign: "center" }}>
                      {!m.isIgnored && <AppIcon of='arrowForward' fontSize='medium' />}
                    </Grid>
                    <Grid item xs={3} md={4}>
                      {!m.isIgnored && (
                        <Chip
                          label={m.target}
                          variant='outlined'
                          sx={{ width: "fill-available" }}
                        />
                      )}
                      {m.isIgnored && (
                        <Chip
                          icon={
                            <AppIcon of='check' sx={{ fill: (th) => th.palette.secondary.main }} />
                          }
                          label='Ignored'
                          color='default'
                          variant='outlined'
                          sx={{ width: "fill-available" }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={3} md={3}>
                      {m.isConfirmed && (
                        <Chip
                          icon={
                            <AppIcon of='check' sx={{ fill: (th) => th.palette.success.main }} />
                          }
                          label='Confirmed'
                          color='success'
                          variant='outlined'
                        />
                      )}
                    </Grid>
                  </React.Fragment>
                ))}
            </Grid>
          }
        >
          <Stack spacing={1} direction='column' sx={{ width: "100%", p: 2, alignItems: "center" }}>
            {/* View spreadsheet data from uploaded file */}
            <>
              <Stack direction='row' sx={{ width: "fill-available" }}>
                <Button
                  onClick={() => setIsSpreadsheetDataViewModalOpened(true)}
                  variant='contained'
                  sx={{ ml: "auto" }}
                >
                  View upload data
                </Button>
              </Stack>
              {/* View spreadsheet data from uploaded file */}
              <SpreadsheetDataViewModal
                open={isSpreadsheetDataViewModalOpened}
                onClose={() => setIsSpreadsheetDataViewModalOpened(false)}
                importId={importEntityIdRef.current!}
              />
            </>
            <FormControl sx={{ minWidth: "fill-available" }}>
              <InputLabel size='small'>
                Select saved import mapping configuration (optional)
              </InputLabel>
              <ImportMappingSelect
                entityType={values.submitUploadModel.importEntityType}
                importMappingId={mappingTemplateId}
                onChange={async (e, mappingTemplate) => {
                  handleChange(e);
                  if (mappingTemplate?.id) {
                    try {
                      setMappingTemplateError(undefined);
                      setMappingTemplateIsLoading(true);
                      const importResponse =
                        await apiClient.dataImportApi.apiV1DataImportMappingApplyPost({
                          nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                          applyMappingToImportDto: {
                            importId: importRequest.data?.id,
                            mappingTemplateId: mappingTemplate.id,
                          },
                        });
                      setFieldValue(
                        "submitMappingModel.mappings",
                        importResponse.data.mapping?.mappings,
                      );
                      usedFileColumnsRef.current =
                        fileColumns?.reduce(
                          (acc, x) => ({
                            ...acc,
                            [x]:
                              importResponse.data!.mapping!.mappings?.some((y) => y.source === x) ||
                              false,
                          }),
                          {},
                        ) || {};
                    } catch (err) {
                      ValidationHelper.handleApiErrorResponseFormik(err, (f, message) =>
                        setMappingTemplateError(message),
                      );
                    } finally {
                      setMappingTemplateIsLoading(false);
                    }
                  }
                }}
                selectProps={{
                  size: "small",
                }}
              />
              {mappingTemplateIsLoading && <LinearProgress sx={{ mt: 1 }} />}
              {mappingTemplateError && (
                <FormHelperText error>{mappingTemplateError}</FormHelperText>
              )}
            </FormControl>

            <Stack
              spacing={2}
              sx={{
                width: "100%",
                p: 2,
                borderWidth: "1px",
                borderRadius: "6px",
                borderColor: (th) => th.palette.secondary.light,
                borderStyle: "dashed",
              }}
            >
              <Typography variant='subtitle2'>
                Map spreadsheet columns to Nexus entity fields
              </Typography>
              <Grid container rowSpacing={1} columnSpacing={1} sx={{ alignItems: "center" }}>
                <Grid item xs={12} md={2}>
                  <Typography variant='subtitle1'>
                    Nexus field{" "}
                    <HelpTooltipIcon title='Target field to which data from file column will be saved' />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant='subtitle1'>
                    Spreadsheet column <HelpTooltipIcon title='Source column name from the file' />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant='subtitle1'>
                    Default value{" "}
                    <HelpTooltipIcon title='Default value which will be applied for cells without value' />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant='subtitle1'>
                    Ignore field <HelpTooltipIcon title='Ignore this field during the import' />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} sx={{ pt: 0 }}>
                  <Button
                    variant='contained'
                    sx={{ width: "120px" }}
                    disabled={
                      values.submitMappingModel.mappings?.some(
                        (x) => !x.source && !x.defaultValue && !x.isIgnored,
                      ) || values.submitMappingModel.mappings?.every((x) => x.isConfirmed)
                    }
                    onClick={() => {
                      values.submitMappingModel.mappings?.forEach((x, idx) => {
                        setFieldValue(`submitMappingModel.mappings[${idx}].isConfirmed`, true);
                      });
                    }}
                  >
                    Confirm all
                  </Button>
                </Grid>
                {importSpecRequest.isLoading && <LinearProgress />}
                {!importSpecRequest.isLoading &&
                  importSpecRequest.data &&
                  importSpecRequest.data.fields!.map((field, index) => {
                    const columnMap = values.submitMappingModel.mappings?.find(
                      (x) => x.target == field.name,
                    );
                    const isConfirmed = columnMap?.isConfirmed;
                    const isIgnored = columnMap?.isIgnored;
                    return (
                      <React.Fragment key={field.name}>
                        {/* Target field */}
                        <Grid item xs={12} md={2}>
                          <Typography>
                            {field.name} {field.isRequired && "*"}
                          </Typography>
                        </Grid>
                        {/* Source column from file */}
                        <Grid item xs={12} md={3}>
                          <FormControl sx={{ minWidth: 200 }}>
                            <Select
                              displayEmpty={false}
                              fullWidth
                              size='small'
                              disabled={isConfirmed}
                              value={columnMap?.source || ""}
                              onChange={(e) => {
                                setFieldValue(
                                  `submitMappingModel.mappings[${index}].source`,
                                  e.target.value || "",
                                );

                                // if new value is set
                                // then unignore field if ignored
                                if (e.target.value && !field.isRequired && isIgnored) {
                                  setFieldValue(
                                    `submitMappingModel.mappings[${index}].isIgnored`,
                                    false,
                                  );
                                }
                              }}
                            >
                              <MenuItem value=''>
                                <em>None</em>
                              </MenuItem>
                              {fileColumns &&
                                fileColumns.map((x, idx) => (
                                  <MenuItem
                                    key={idx}
                                    value={x!}
                                    disabled={usedFileColumnsRef.current[x]}
                                  >
                                    {x}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {/* Default value */}
                        <Grid item xs={12} md={3}>
                          {field.allowUserEnteredDefaultValue && (
                            <FormControl>
                              <TextField
                                size='small'
                                placeholder='Default value'
                                disabled={isConfirmed}
                                value={columnMap?.defaultValue || ""}
                                onChange={(e) => {
                                  setFieldValue(
                                    `submitMappingModel.mappings[${index}].defaultValue`,
                                    e.target.value,
                                  );
                                  // if new value is set
                                  // then unignore field if ignored
                                  if (e.target.value && !field.isRequired && isIgnored) {
                                    setFieldValue(
                                      `submitMappingModel.mappings[${index}].isIgnored`,
                                      false,
                                    );
                                  }
                                }}
                              />
                            </FormControl>
                          )}
                        </Grid>
                        {/* Ignore field */}
                        <Grid item xs={12} md={2}>
                          {!field.isRequired && (
                            <Button
                              variant='outlined'
                              color='text'
                              disabled={isConfirmed}
                              onClick={() => {
                                setFieldValue(
                                  `submitMappingModel.mappings[${index}].isIgnored`,
                                  !isIgnored,
                                );

                                // if new value is true
                                // then we need to clear column source and default value
                                // and set isConfirmed to true
                                if (!isIgnored) {
                                  setFieldValue(
                                    `submitMappingModel.mappings[${index}].source`,
                                    undefined,
                                  );
                                  setFieldValue(
                                    `submitMappingModel.mappings[${index}].defaultValue`,
                                    undefined,
                                  );
                                  setFieldValue(
                                    `submitMappingModel.mappings[${index}].isConfirmed`,
                                    true,
                                  );
                                }
                              }}
                              startIcon={isIgnored && <AppIcon of='check' />}
                              sx={{ px: 5, width: "120px" }}
                            >
                              {isIgnored ? "Ignored" : "Ignore"}
                            </Button>
                          )}
                        </Grid>
                        {/* Confirm mapping */}
                        <Grid item xs={12} md={2}>
                          <Button
                            variant={isConfirmed ? "contained" : "outlined"}
                            disabled={!columnMap?.source && !columnMap?.defaultValue && !isIgnored}
                            onClick={() => {
                              setFieldError(`submitMappingModel.mappings[${index}]`, undefined);
                              if (!isConfirmed) {
                                if (
                                  field.isRequired &&
                                  !columnMap?.source &&
                                  !columnMap?.defaultValue
                                ) {
                                  setFieldError(
                                    `submitMappingModel.mappings[${index}]`,
                                    "This field is required",
                                  );
                                  return;
                                }
                              }
                              setFieldValue(
                                `submitMappingModel.mappings[${index}].isConfirmed`,
                                !isConfirmed,
                              );
                            }}
                            startIcon={isConfirmed && <AppIcon of='check' />}
                            color='primary'
                            sx={{ px: 5, width: "120px" }}
                          >
                            {isConfirmed ? "Confirmed" : "Confirm"}
                          </Button>
                        </Grid>
                        {/* Error related to this mapping */}
                        {(getIn(errors, `submitMappingModel.mappings[${index}]`) ||
                          getIn(errors, `mappings[${index}]`)) && (
                          <Grid item xs={12} md={12}>
                            <FormHelperText error>
                              {getIn(errors, `submitMappingModel.mappings[${index}]`) ||
                                getIn(errors, `mappings[${index}]`)}
                            </FormHelperText>
                          </Grid>
                        )}
                      </React.Fragment>
                    );
                  })}
              </Grid>

              <Stack direction='column'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.submitMappingModel.shouldSaveMappingForFuture}
                      onChange={(e, checked) =>
                        setFieldValue("submitMappingModel.shouldSaveMappingForFuture", checked)
                      }
                    />
                  }
                  label={
                    <Typography component='span' sx={{ display: "flex" }}>
                      Save this mapping configuration for future
                    </Typography>
                  }
                />
                {values.submitMappingModel.shouldSaveMappingForFuture && (
                  <FormControl>
                    <TextField
                      label='Mapping configuration name'
                      required
                      value={values.submitMappingModel.mappingName}
                      size='small'
                      onChange={(e) =>
                        setFieldValue("submitMappingModel.mappingName", e.target.value)
                      }
                    />
                  </FormControl>
                )}
              </Stack>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                disabled={
                  values.submitMappingModel.mappings?.some((x) => !x.isConfirmed) ||
                  (values.submitMappingModel.shouldSaveMappingForFuture &&
                    !values.submitMappingModel.mappingName)
                }
                loading={isSubmitting}
                onClick={() => handleSubmit()}
                sx={{ px: 5 }}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </CollapseItem>
        <CollapseItem
          iconOf='start'
          label='Launch'
          in={currentTab === ImportTab.PreLaunchConfiguration}
          onClick={() => handleChangeCurrentTab(ImportTab.PreLaunchConfiguration)}
          unmountOnExit
        >
          <Stack direction='column' sx={{ alignItems: "center" }}>
            <Stack
              direction='column'
              sx={{
                width: "100%",
                mt: 3,
                p: 3,
                borderRadius: (th) => th.shape.borderRadius,
                boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
              }}
            >
              <Stack sx={{ position: "relative" }}>
                <Grid
                  container
                  columnSpacing={2}
                  sx={{
                    ...(!importSimulationResultRef.current ? { filter: "blur(3px)" } : {}),
                    p: 2,
                  }}
                >
                  <Grid item xs={4} md={4}>
                    <CountTile label='Created entities'>
                      {/* values.submitMappingModel.mappings?.filter((x) => x.isConfirmed).length || 0 */}
                      {importSimulationComputedProps.createdEntities}
                    </CountTile>
                  </Grid>
                  {values.launchImportModel.shouldUpdateEntityIfExists && (
                    <Grid item xs={4} md={4}>
                      <CountTile label='Updated entities'>
                        {/* {values.submitMappingModel.mappings?.filter((x) => x.isIgnored).length || 0} */}
                        {importSimulationComputedProps.updatedEntities}
                      </CountTile>
                    </Grid>
                  )}
                  {!values.launchImportModel.shouldUpdateEntityIfExists && (
                    <Grid item xs={4} md={4}>
                      <CountTile label='Skipped entities'>
                        {/* {importRequest.data?.fileMetadata?.rowCapacity || 0} */}
                        {importSimulationComputedProps.skippedEntities}
                      </CountTile>
                    </Grid>
                  )}
                  <Grid item xs={4} md={4}>
                    <CountTile label='Problem rows'>
                      {/* {importRequest.data?.fileMetadata?.rowCapacity || 0} */}
                      <Stack direction='row' spacing={0.5} sx={{ alignItems: "center" }}>
                        <Typography>{importSimulationComputedProps.failedRows}</Typography>
                        {importSimulationComputedProps.failedRows !== 0 && (
                          <AppIcon
                            of='view'
                            onClick={() => setIsProblemRowsViewModalOpened(true)}
                            sx={{ cursor: "pointer", mb: 1 }}
                          />
                        )}
                      </Stack>
                      {/* Problem rows modal */}
                      {importSimulationResultRef.current && (
                        <ProblemRowsViewModal
                          importResult={importSimulationResultRef.current!}
                          open={isProblemRowsViewModalOpened}
                          onClose={() => setIsProblemRowsViewModalOpened(false)}
                        />
                      )}
                    </CountTile>
                  </Grid>
                </Grid>
                {!importSimulationResultRef.current && (
                  <Tooltip title='This will simulate the import and show the results that you will get when import is finished'>
                    <LoadingButton
                      variant='contained'
                      loading={isImportSimulationInProgress}
                      onClick={loadImportSimulationResult}
                      sx={{
                        position: "absolute",
                        width: 150,
                        left: "calc(50% - 75px)",
                        top: "calc(50% - 18px)",
                        zIndex: (th) => th.zIndex.tooltip,
                      }}
                    >
                      Calculate result
                    </LoadingButton>
                  </Tooltip>
                )}
              </Stack>

              <Stack direction='column' sx={{ mt: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.launchImportModel.shouldUpdateEntityIfExists}
                      onChange={(e, checked) =>
                        setFieldValue("launchImportModel.shouldUpdateEntityIfExists", checked)
                      }
                    />
                  }
                  label={
                    <Typography component='span' sx={{ display: "flex" }}>
                      Update entities if they already exist
                    </Typography>
                  }
                />
              </Stack>
            </Stack>

            <GeneralValidationError sx={{ my: 1 }} errors={errors} />

            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={2}
              sx={{ mt: 2, alignItems: "center", justifyContent: "center" }}
            >
              <Button variant='outlined' color='text' sx={{ px: 5 }}>
                Cancel
              </Button>
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
                onClick={() => handleSubmit()}
                sx={{ width: 200, mt: 3 }}
              >
                Launch
              </LoadingButton>
            </Stack>
          </Stack>
        </CollapseItem>
      </Stack>
    </CreateUpdatePageLayout>
  );
}
