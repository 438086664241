import { Stack, TextField, TextFieldProps, Typography } from "@mui/material";
import { useMemo } from "react";

import { VALIDATION_CONSTANTS } from "@/common/constants/validation";

type Props = TextFieldProps & {
  mode: "notes" | "description";
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | null | undefined;
  showCharacterCount?: boolean;
};

type DefaultPropsByMode = {
  [K in Props["mode"]]: {
    label: string;
    rows: number;
    maxLength: number;
  };
};

const defaultPropsByMode: DefaultPropsByMode = {
  notes: {
    label: "Notes",
    rows: 2,
    maxLength: VALIDATION_CONSTANTS.NotesMaxLength,
  },
  description: {
    label: "Description",
    rows: 2,
    maxLength: VALIDATION_CONSTANTS.DescriptionMaxLength,
  },
};
export default function AppTextArea({
  mode,
  value,
  showCharacterCount = true,
  onChange,
  error,
  helperText,
  ...props
}: Props) {
  const { label, rows, maxLength } = defaultPropsByMode[mode];

  const currentLength = useMemo(() => value?.length || 0, [value]);
  const showCharacterCountComputed = showCharacterCount && value;

  return (
    <TextField
      multiline
      rows={rows}
      label={label}
      type='text'
      value={value}
      onChange={onChange}
      inputProps={{ ...props.inputProps, maxLength }}
      helperText={
        (helperText || showCharacterCountComputed) && (
          <Stack
            direction='row'
            sx={{ width: "100%", justifyContent: "flex-start", alignItems: "flex-start" }}
          >
            {helperText}

            {showCharacterCount && value && (
              <Typography
                variant='caption2'
                display='flex'
                justifyContent='end'
                sx={{ ml: "auto" }}
              >
                {currentLength}/{maxLength}
              </Typography>
            )}
          </Stack>
        )
      }
      {...props}
    />
  );
}
