import { LoadingButton } from "@mui/lab";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { Formik } from "formik";
import moment, { Moment } from "moment";
import * as Yup from "yup";

import { DATETIME_FORMATS } from "@/common/constants/common";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractReminderDto,
  ContractReminderUpdatedDto,
  UpdateContractReminderDto,
} from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";

export interface UpdateContractReminderOwnProps {
  contractId: string;
  reminder: ContractReminderDto;
  onUpdate?: (newValue: ContractReminderUpdatedDto) => void;
}

export type UpdateContractReminderProps = UpdateContractReminderOwnProps;

export default function UpdateContractReminder({
  contractId,
  reminder,
  onUpdate,
}: UpdateContractReminderProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  return (
    <Formik<UpdateContractReminderDto & BaseFormikValues>
      enableReinitialize={!!reminder}
      initialValues={{
        isEnabled: reminder?.isEnabled || undefined,
        nextFireAt: reminder?.nextFireAt || undefined,
        submit: "",
      }}
      validationSchema={Yup.object().shape({})}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          const result =
            await apiClient.contractsApi.apiV1ContractsContractIdRemindersReminderIdPut({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              contractId,
              reminderId: reminder.id!,
              updateContractReminderDto: {
                ...values,
                nextFireAt: values.nextFireAt && moment(values.nextFireAt).utc().format(),
              },
            });
          enqueueSnackbar("Reminder updated.", { variant: "success" });
          onUpdate && onUpdate(result.data);

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err: any) {
          if (mounted.current) {
            ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isEnabled}
                      onChange={(e) => setFieldValue("isEnabled", e.target.checked)}
                    />
                  }
                  label={values.isEnabled ? "Enabled" : "Disabled"}
                />
              </FormGroup>

              <FormControl margin='dense' error={Boolean(touched.nextFireAt && errors.nextFireAt)}>
                <MobileDateTimePicker
                  ampm={false}
                  label='Send at'
                  value={(values.nextFireAt && moment(values.nextFireAt)) || null}
                  format={DATETIME_FORMATS.DISPLAY_DATETIME}
                  onChange={(newValue: Moment | null) => {
                    setFieldValue("nextFireAt", newValue?.format() || null);
                  }}
                  slots={{ textField: (params) => <TextField {...params} /> }}
                />
                <FormHelperText>{touched.nextFireAt && errors.nextFireAt}</FormHelperText>
              </FormControl>
            </Box>

            <GeneralValidationError sx={{ my: 1 }} errors={errors} />

            <FormActions>
              <LoadingButton
                color='primary'
                loading={isSubmitting}
                fullWidth
                type='submit'
                variant='contained'
              >
                Save
              </LoadingButton>
            </FormActions>
          </form>
        );
      }}
    </Formik>
  );
}
