import { Box, Chip, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import ViewPageLayout from "@/App/Layouts/Pages/ViewPageLayout";
import AppBreadcrumbs from "@/common/components/AppBreadcrumbs";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import EntityUpdatedByInfoDisplay from "@/common/components/EntityInfo/EntityUpdatedByInfoDisplay";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import PermissionList from "@/common/components/Permission/PermissionList";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission } from "@/core/api/generated";

export default function RoleViewPage() {
  const { roleId } = useParams<{ roleId?: string }>();

  const roleRequest = useApiRequest(
    apiClient.adminAccountApi.apiV1AdminAccountGlobalRolesRoleIdGet,
    {
      roleId: roleId!,
    },
    {
      skip: !roleId,
    },
  );
  const role = roleRequest?.data;

  const allPermissionsRequest = useApiRequest(
    apiClient.adminAccountApi.apiV1AdminAccountAllPermissionsGet,
    {},
    {},
  );
  const allPermissions = allPermissionsRequest?.data;

  const rolePermissions = useMemo(
    () => allPermissions?.filter((x) => role?.permissions?.includes(x.permission!)),
    [role, allPermissions],
  );

  return (
    <ViewPageLayout
      header={
        <>
          Role {role?.name}
          {role?.isBuiltIn && (
            <Chip
              size='small'
              color='primary'
              variant={"outlined"}
              label={"Built-in"}
              sx={{ ml: 1 }}
            />
          )}
          <AuthorizedElement permissions={[AppPermission.GlobalRoleManage]}>
            {!role?.isBuiltIn && (
              <IconButton
                sx={{ ml: 1 }}
                component={RouterLink}
                to={ROUTE_PATH.ADMIN_ROLE_EDIT(role?.id)}
              >
                <AppIcon of='edit' />
              </IconButton>
            )}
          </AuthorizedElement>
        </>
      }
    >
      <Stack direction='column' spacing={2}>
        <AppBreadcrumbs sx={{ mb: 0 }} />
        <Typography component='div' variant='body1' color='text.secondary'>
          {role?.description}
        </Typography>

        <Box>
          <Grid container columnSpacing={2} rowSpacing={1}>
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Created' isEmpty={!role?.createdAt}>
                  <EntityCreatedByInfoDisplay entity={role} />
                </FieldValue>
              </Stack>
            </Grid>
            <Grid item xxs={12} md minWidth={200}>
              <Stack direction='column' spacing={1}>
                <FieldValue label='Updated' isEmpty={!role?.updatedAt}>
                  <EntityUpdatedByInfoDisplay entity={role} />
                </FieldValue>
              </Stack>
            </Grid>
          </Grid>
        </Box>

        <Typography component='div' variant='h6'>
          Permissions
        </Typography>
        <PermissionList permissions={rolePermissions} />
      </Stack>
    </ViewPageLayout>
  );
}
