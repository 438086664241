import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";

import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  ContractDto,
  ContractReallocateVehicleDto,
  ContractStage,
  ContractType,
  VehicleType,
} from "@/core/api/generated";

import InlineApiEnumValueList from "../../Enum/InlineApiEnumValueList";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FieldValue from "../../Form/Display/FieldValue";
import FormActions from "../../Form/FormActions";
import AssetSubscriptionLink from "../AssetSubscription/AssetSubscriptionLink";
import VehicleAutocompleteOrCreate from "../Vehicle/VehicleAutocompleteOrCreate";
import VehicleLink from "../Vehicle/VehicleLink";
import ContractLink from "./ContractLink";

type DefaultValues = Partial<ContractReallocateVehicleDto>;

export interface ContractReallocateVehicleOwnProps {
  contract: ContractDto;
  defaultValues?: DefaultValues;
  onSave?: (newValue: ContractDto) => void;
}

export type ContractReallocateVehicleProps = ContractReallocateVehicleOwnProps;

export default function ContractReallocateVehicle({
  contract,
  defaultValues,
  onSave,
}: ContractReallocateVehicleProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();

  const isContractForAssetSubscription =
    contract?.type === ContractType.Subscription && !!contract.assetSubscription;
  const disabled = !contract?.canReallocate;

  const formik = useFormik<ContractReallocateVehicleDto & BaseFormikValues>({
    enableReinitialize: true,
    initialValues: {
      newVehicleId: contract?.vehicle?.id || defaultValues?.newVehicleId || undefined,
      isConfirmed: defaultValues?.isConfirmed ?? undefined,
      submit: "",
    },
    validationSchema: Yup.object().shape({
      // vehicleId: Yup.string().required("Vehicle is required"),
      // customerId: Yup.string().required("Customer is required"),
      // type: Yup.string().required("Type is required"),
    }),
    onSubmit: async () => {
      try {
        const response =
          await apiClient.contractsApi.apiV1ContractsContractIdVehicleAllocationReallocatePost({
            nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
            contractId: contract.id || "",
            contractReallocateVehicleDto: {
              ...formik.values,
            },
          });
        enqueueSnackbar("Vehicle reallocated.", { variant: "success" });
        onSave && onSave(response.data);

        if (mounted.current) {
          formik.setStatus({ success: true });
          formik.setSubmitting(false);
        }
      } catch (err: any) {
        if (mounted.current) {
          ValidationHelper.handleApiErrorResponseFormik(err, formik.setFieldError);
          formik.setStatus({ success: false });
          formik.setSubmitting(false);
        }
      }
    },
  });

  const { errors, handleSubmit, isSubmitting, values, setFieldValue } = formik;

  return (
    <Box>
      <form noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {contract.stageHistory?.stage !== ContractStage.Draft && (
            <Alert severity='warning'>
              Vehicle can be reallocated only while the contract is on these stages:{" "}
              <InlineApiEnumValueList
                type='ContractStage'
                values={[ContractStage.Draft, ContractStage.Pending]}
              />
              .
            </Alert>
          )}

          {isContractForAssetSubscription &&
            contract?.type === ContractType.Subscription &&
            contract.assetSubscription && (
              <Alert severity='warning'>
                NB: The contract <ContractLink entity={contract} /> is for{" "}
                <AssetSubscriptionLink entity={contract.assetSubscription} />. Please go to{" "}
                <AssetSubscriptionLink entity={contract.assetSubscription} /> and reallocate Asset
                for the subscription first (changes will be auto-populated to the contract).
              </Alert>
            )}

          <Stack spacing={1}>
            <FieldValue label='Old vehicle' direction='column'>
              <VehicleLink entity={contract?.vehicle} />
            </FieldValue>
          </Stack>

          <Box>
            <FormControl
              margin='dense'
              fullWidth
              disabled={disabled}
              error={Boolean(errors.newVehicleId)}
            >
              <VehicleAutocompleteOrCreate
                autocompleteProps={{
                  required: true,
                  disabled: disabled,
                  entityId: values.newVehicleId,
                  isPreload: true,
                  label: "New vehicle",
                  searchFilters: {
                    type: VehicleType.Car,
                  },
                  onChange: (newValue) => {
                    setFieldValue("newVehicleId", newValue?.id);
                  },
                }}
                createFormPlacement='modal'
                onCreate={(newValue) => {
                  setFieldValue("newVehicleId", newValue?.id);
                }}
              />

              <FormHelperText error>{errors.newVehicleId}</FormHelperText>
            </FormControl>

            <FormControl
              margin='dense'
              disabled={disabled}
              error={Boolean(errors.isConfirmed)}
              variant='standard'
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.isConfirmed}
                    onChange={(e) => setFieldValue("isConfirmed", e.target.checked)}
                  />
                }
                label='Confirm allocation'
              />
              <FormHelperText>{`When confirmed you won't be able to reallocate vehicle no more.`}</FormHelperText>
              <FormHelperText error>{errors.isConfirmed}</FormHelperText>
            </FormControl>
          </Box>
        </Stack>

        <GeneralValidationError sx={{ my: 1 }} errors={errors} />

        <FormActions>
          <LoadingButton
            onClick={() => handleSubmit()}
            color='primary'
            loading={isSubmitting}
            disabled={disabled}
            fullWidth
            variant='contained'
          >
            Save
          </LoadingButton>
        </FormActions>
      </form>
    </Box>
  );
}
