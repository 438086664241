import { Alert, Dialog, DialogContent, DialogProps, Stack, Typography } from "@mui/material";
import { ReactNode } from "react";

import AppModalTitle from "@/common/components/Modals/AppModalTitle";
import { EnvHelper } from "@/common/helpers/env";
import { TypeHelper } from "@/common/helpers/type";
import { UrlHelper } from "@/common/helpers/url";
import { ValidationInfo } from "@/common/validation";
import { ProblemDetails, ValidationProblemDetails } from "@/core/api/generated";

import BlockCode from "../Code/BlockCode";
import InlineCode from "../Code/InlineCode";
import FoldableBlock from "../Display/FoldableBlock";
import FieldValue from "../Form/Display/FieldValue";

const defaultDisplayProps = {
  placeholder: true,
};

export interface OwnProps {
  problemDetails: ProblemDetails | ValidationProblemDetails | null | undefined;
  validationInfo: ValidationInfo | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  customContent?: ReactNode;
}

type Props = OwnProps & DialogProps;

export default function ValidationInfoModal({
  problemDetails,
  validationInfo,
  displayProps = defaultDisplayProps,
  customContent,
  ...dialogProps
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const problemDetailsComputed = problemDetails || validationInfo?.problemDetails;

  return (
    <Dialog fullWidth maxWidth='md' {...dialogProps}>
      <AppModalTitle
        onCloseClicked={() => dialogProps?.onClose && dialogProps?.onClose({}, "escapeKeyDown")}
      >
        Error details
      </AppModalTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Alert severity='info'>
            You can report the issue and help us to fix it much quicker. Please copy{" "}
            <strong>Operation ID</strong> below and send it to us along with the following info:
            <ol>
              <li>Page URL.</li>
              <li>Screenshots of the page where error occurred.</li>
            </ol>
            Thank you!
          </Alert>

          <Stack spacing={1}>
            <FieldValue label='Error' isEmpty={TypeHelper.isEmpty(problemDetailsComputed)}>
              <Typography color='error'>{problemDetailsComputed?.title}</Typography>
              <Typography color='error'>{problemDetailsComputed?.detail}</Typography>
            </FieldValue>

            <FieldValue
              label='Validation error'
              isEmpty={TypeHelper.isEmpty(validationInfo?.generalError)}
            >
              <Typography color='error'>{validationInfo?.generalError}</Typography>
            </FieldValue>

            <FieldValue label='Page URL' withCopyButton>
              {UrlHelper.getCurrentUrl()}
            </FieldValue>

            <FieldValue
              label='Operation ID'
              isEmpty={TypeHelper.isEmpty(problemDetailsComputed)}
              withCopyButton
            >
              {problemDetailsComputed && problemDetailsComputed["operationId"]}
            </FieldValue>
            {customContent && <>{customContent}</>}
          </Stack>

          {EnvHelper.isDevelopmentAny && (
            <FoldableBlock
              defaultIsFolded
              trigger={{
                label: (
                  <Typography component='span' variant='h6'>
                    Debug info
                  </Typography>
                ),
              }}
            >
              <Stack spacing={1}>
                <Alert severity='warning'>Shown on development envs only.</Alert>

                <Stack spacing={1}>
                  <FieldValue
                    sx={{ maxWidth: "100%", overflow: "auto" }}
                    label='Raw ProblemDetails from BE error response'
                    isEmpty={TypeHelper.isEmpty(problemDetailsComputed)}
                    direction='column'
                    withCopyButton
                  >
                    <BlockCode>{JSON.stringify(problemDetailsComputed, null, 4)}</BlockCode>
                  </FieldValue>

                  <FieldValue
                    label='Exception message'
                    isEmpty={TypeHelper.isEmpty(problemDetailsComputed?.debug?.exceptionMessage)}
                    direction='column'
                    withCopyButton
                  >
                    <InlineCode>{problemDetailsComputed?.debug?.exceptionMessage}</InlineCode>
                  </FieldValue>

                  <FieldValue
                    sx={{ maxWidth: "100%", overflow: "auto" }}
                    label='Stack trace'
                    isEmpty={TypeHelper.isEmpty(problemDetailsComputed?.debug?.stackTrace)}
                    direction='column'
                    withCopyButton
                  >
                    <BlockCode>{problemDetailsComputed?.debug?.stackTrace}</BlockCode>
                  </FieldValue>
                </Stack>
              </Stack>
            </FoldableBlock>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
