import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/system";
import { Formik, getIn } from "formik";
import * as Yup from "yup";

import ContactInput, { ContactData } from "@/common/components/Contact/ContactInput";
import FoldableBlock from "@/common/components/Display/FoldableBlock";
import GeneralAddressInput from "@/common/components/Entity/General/Input/GeneralAddressInput";
import AppIcon from "@/common/components/Icons/AppIcon";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { IdHelper } from "@/common/helpers/id";
import { RegexHelper } from "@/common/helpers/regex";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { cast } from "@/common/ts/conversions";
import { BaseFormikValues, ValidationErrors } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  CreateCustomerContactDto,
  CreateCustomerDto,
  CustomerDto,
  CustomerType,
  EntityType,
  UpdateCustomerContactDto,
  UpdateCustomerDto,
} from "@/core/api/generated";

import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import AppTextArea from "../../Form/Input/AppTextArea";
import EntityAffiliationInput from "../EntityAffiliation/EntityAffiliationInput";
import GeneralAttachedTagsInput from "../General/GeneralTag/GeneralAttachedTagsInput";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";

type DefaultValues = {
  nameOrEmail?: string;
};

type ContactValue = CreateCustomerContactDto & UpdateCustomerContactDto & { tempId?: string };

interface CustomerCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<CustomerDto, DefaultValues> {
  customerId?: string;
}

export type CustomerCreateUpdateProps = CustomerCreateUpdateOwnProps;

export default function CustomerCreateUpdate({
  customerId,
  defaultValues,
  onCreate,
  onUpdate,
  onSave,
}: CustomerCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !customerId;
  const isEdit = !!customerId;

  const customerRequest = useApiRequest(
    apiClient.customersApi.apiV1CustomersCustomerIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      customerId: customerId!,
    },
    {
      skip: !customerId,
    },
  );
  const customer = customerRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb: customer && {
      idValue: customer.id!,
      newTitle: customer.name || "",
    },
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.Customer}
      entityId={customerId}
      entity={customer}
      entityRequest={customerRequest}
    >
      <Stack spacing={2}>
        {customerRequest.isLoading && <LinearProgress />}

        <Formik<
          Omit<CreateCustomerDto, "contacts"> &
            Omit<UpdateCustomerDto, "contacts"> &
            BaseFormikValues & {
              contacts?: ContactValue[];
            }
        >
          enableReinitialize={false}
          initialValues={{
            type: customer?.type || CustomerType.B2C,
            name: customer?.name || "",
            notes: customer?.notes || "",
            departmentId: customer?.departmentIds?.at(0) || undefined,
            locationId: customer?.locationIds?.at(0) || undefined,
            contacts: customer?.contacts
              ? customer?.contacts.map((x) => ({
                  ...x,
                  id: x.id,
                  tempId: x.id || IdHelper.newUuid4(),
                  personName: {
                    firstName: x.personName?.firstName || undefined,
                    lastName: x.personName?.lastName || undefined,
                  },
                  email: x.email,
                  isPrimary: x.isPrimary,
                  phoneNumber: x.phoneNumber,
                  nationalIdentityNumber: x.nationalIdentityNumber,
                }))
              : [
                  {
                    id: undefined,
                    tempId: IdHelper.newUuid4(),
                    personName: undefined,
                    email:
                      (defaultValues?.nameOrEmail &&
                        RegexHelper.isEmail(defaultValues.nameOrEmail) &&
                        defaultValues?.nameOrEmail) ||
                      undefined,
                    phoneNumber: undefined,
                    nationalIdentityNumber: undefined,
                    isPrimary: true,
                  },
                ],
            submit: "",
            isDriver: true,
            address: {
              country: customer?.address?.country || undefined,
              state: customer?.address?.state || undefined,
              city: customer?.address?.city || undefined,
              line1: customer?.address?.line1 || undefined,
              line2: customer?.address?.line2 || undefined,
              postalCode: customer?.address?.postalCode || undefined,
            },
            tags: customer?.tags || undefined,
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
            const values2 = {
              ...values,
              contacts: values.contacts?.map((x) => ({
                ...x,
                nationalIdentityNumber:
                  x.nationalIdentityNumber?.number && x.nationalIdentityNumber?.countryCode
                    ? x.nationalIdentityNumber
                    : undefined,
              })),
            };
            try {
              if (isCreate) {
                const response = await apiClient.customersApi.apiV1CustomersPost({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  createCustomerDto: {
                    ...values2,
                  },
                });
                enqueueSnackbar("Customer created.", { variant: "success" });
                onCreate && onCreate(response.data);
                onSave && onSave(response.data);
              } else {
                const response = await apiClient.customersApi.apiV1CustomersCustomerIdPut({
                  nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                  customerId,
                  updateCustomerDto: {
                    ...values2,
                  },
                });
                enqueueSnackbar("Customer updated.", { variant: "success" });
                onUpdate && onUpdate(response.data);
                onSave && onSave(response.data);
              }

              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(false);
              }
            } catch (err: any) {
              if (mounted.current) {
                ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
                setStatus({ success: false });
                setSubmitting(false);
              }
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setErrors,
            setFieldValue,
            setValues,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <Box>
                  <Box>
                    {/* Tags */}
                    <FormControl margin='dense' fullWidth>
                      <GeneralAttachedTagsInput
                        value={values.tags}
                        onChange={(newValue) => {
                          setFieldValue("tags", newValue);
                        }}
                      />
                      <FormHelperText error>
                        {ValidationHelper.getFormikErrorsAsString(errors.tags, {
                          isIncludeNested: false,
                        })}
                      </FormHelperText>
                    </FormControl>
                  </Box>

                  <FormControl
                    margin='normal'
                    fullWidth
                    disabled={!isCreate}
                    error={Boolean(touched.type && errors.type)}
                  >
                    <InputLabel required>Customer type</InputLabel>
                    <Select
                      label='Customer type'
                      displayEmpty={false}
                      name='type'
                      value={values.type}
                      onChange={(e) => setFieldValue("type", e.target.value as CustomerType)}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      {Object.values(CustomerType)
                        .filter((x) => x !== CustomerType.None)
                        .map((x, index) => (
                          <MenuItem key={index} value={x}>
                            {x}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{touched.type && errors.type}</FormHelperText>
                  </FormControl>

                  {values.type !== CustomerType.B2C && (
                    <TextField
                      error={Boolean(
                        values.type === CustomerType.B2B && touched.name && errors.name,
                      )}
                      helperText={values.type === CustomerType.B2B && touched.name && errors.name}
                      fullWidth
                      required
                      label='Company name'
                      margin='normal'
                      name='name'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type='text'
                      value={values.name}
                      variant='outlined'
                    />
                  )}

                  {/* Entity affiliation */}
                  <EntityAffiliationInput
                    department={{
                      departmentId: values.departmentId,
                      onChange: (d) => {
                        setFieldValue("departmentId", d?.id);
                        setFieldValue("locationId", undefined);
                        d?.address && setFieldValue("address", d?.address);
                      },
                      error: errors.departmentId,
                    }}
                    location={{
                      locationId: values.locationId,
                      onChange: (l) => {
                        setFieldValue("locationId", l?.id);
                        l?.address && setFieldValue("address", l?.address);
                      },
                      searchFilters: { departmentId: values.departmentId },
                      createUpdateProps: { defaultValues: { departmentId: values.departmentId } },
                      error: errors.locationId,
                      disabled: !values.departmentId,
                    }}
                  />

                  <Box sx={{ mt: 1 }}>
                    <Typography component='div' sx={{ fontWeight: "bold" }}>
                      Contacts *
                    </Typography>

                    {isEdit && (
                      <Alert severity='warning' sx={{ my: 2 }}>
                        <AlertTitle>Keep in mind!</AlertTitle>
                        <ul>
                          <li>
                            When contact email is updated, then customer user with old email is
                            suspended.
                          </li>
                          <li>
                            When contact is deleted, then customer user with that email is
                            suspended.
                          </li>
                          <li>
                            When email of existing suspended customer user is used for a contact,
                            then that user is resumed.
                          </li>
                        </ul>
                      </Alert>
                    )}

                    <Stack
                      direction={{ xxs: "column", md: "row" }}
                      spacing={1}
                      sx={{ mt: 1, flexWrap: "wrap" }}
                    >
                      {values.contacts?.map((contact, i) => (
                        <Box key={contact.id || i}>
                          <ContactInput
                            sx={{ mb: 1, minWidth: 400 }}
                            value={{
                              id: contact.id || undefined,
                              tempId: contact.tempId || undefined,
                              firstName: contact.personName?.firstName,
                              lastName: contact.personName?.lastName,
                              email: contact.email,
                              phoneNumber: contact.phoneNumber,
                              nationalIdentityNumber: contact.nationalIdentityNumber,
                              isPrimary: contact.isPrimary,
                            }}
                            displayProps={{
                              firstName: true,
                              lastName: true,
                              email: true,
                              phoneNumber: true,
                              nationalIdentityNumber: true,
                              isPrimary: true,
                            }}
                            inputsProps={{
                              firstName: {
                                name: `contacts[${i}].personName.firstName`,
                                onBlur: handleBlur,
                              },
                              lastName: {
                                name: `contacts[${i}].personName.lastName`,
                                onBlur: handleBlur,
                              },
                              email: {
                                name: `contacts[${i}].email`,
                                onBlur: handleBlur,
                              },
                              phoneNumber: {
                                name: `contacts[${i}].phoneNumber`,
                                onBlur: handleBlur,
                              },
                              nationalIdentityNumber: {
                                name: `contacts[${i}].nationalIdentityNumber`,
                                onBlur: handleBlur,
                              },
                              isPrimary: {
                                name: `contacts[${i}].isPrimary`,
                                onBlur: handleBlur,
                              },
                            }}
                            errors={
                              getIn(errors, `contacts[${i}]`) as ValidationErrors<ContactData>
                            }
                            action={
                              <IconButton
                                onClick={() => {
                                  setValues((currentValues) => ({
                                    ...currentValues,
                                    contacts: currentValues.contacts?.filter(
                                      (x, j) => x.tempId !== contact.tempId,
                                    ),
                                  }));
                                }}
                              >
                                <AppIcon of='close' />
                              </IconButton>
                            }
                            onChange={(newValue) => {
                              setValues((currentValues) => ({
                                ...currentValues,
                                contacts: currentValues.contacts?.map((x) =>
                                  x.tempId === newValue?.tempId
                                    ? {
                                        ...x,
                                        ...newValue,
                                        personName: {
                                          firstName: newValue?.firstName,
                                          lastName: newValue?.lastName,
                                        },
                                      }
                                    : { ...x, isPrimary: newValue.isPrimary ? false : x.isPrimary },
                                ),
                              }));
                            }}
                          />
                        </Box>
                      ))}
                    </Stack>

                    <Button
                      sx={{ mt: 1, width: "fit-content" }}
                      variant='outlined'
                      color='text'
                      size='small'
                      type='button'
                      startIcon={<AppIcon of='add' />}
                      onClick={() => {
                        setFieldValue("contacts", [
                          ...(values.contacts || []),
                          cast<ContactValue>({
                            tempId: IdHelper.newUuid4(),
                            isPrimary: values.contacts?.length === 0,
                          }),
                        ]);
                      }}
                    >
                      Add contact
                    </Button>
                  </Box>

                  {/* Optional fields */}
                  <FoldableBlock
                    defaultIsFolded
                    trigger={{
                      label: <Typography variant='subtitle1'>Show more details</Typography>,
                    }}
                  >
                    <Box sx={{ my: 1 }}>
                      <Typography component='div' sx={{ mb: 1, fontWeight: "bold" }}>
                        Address
                      </Typography>

                      <GeneralAddressInput
                        value={values.address}
                        errors={getIn(errors, "address")}
                        touched={getIn(touched, "address")}
                        inputsProps={{ all: {} }}
                        onChange={(newValue) => setFieldValue("address", newValue)}
                      />
                    </Box>

                    <AppTextArea
                      error={Boolean(touched.notes && errors.notes)}
                      fullWidth
                      helperText={touched.notes && errors.notes}
                      mode='notes'
                      margin='normal'
                      name='notes'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.notes}
                      variant='outlined'
                    />
                  </FoldableBlock>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isDriver!}
                        onChange={(e) => setFieldValue("isDriver", e.target.checked)}
                      />
                    }
                    label={
                      <Typography component='span' sx={{ display: "flex" }}>
                        Is driver
                      </Typography>
                    }
                  />
                </Box>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <FormActions>
                  <LoadingButton
                    color='primary'
                    loading={isSubmitting}
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </LoadingButton>
                </FormActions>
              </form>
            );
          }}
        </Formik>
      </Stack>
    </BaseEntityCreateUpdate>
  );
}
