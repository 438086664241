import { Box, Grid, LinearProgress, Stack, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { ReactNode, useState } from "react";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import EntityNotFoundAlert from "@/common/components/AppAlerts/EntityNotFoundAlert";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import GeneralCurrencyDisplay from "@/common/components/Entity/General/Display/GeneralCurrencyDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import AppLink from "@/common/components/Link/AppLink";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { useEffectWithThrottle } from "@/common/hooks/effect/useEffectWithThrottle";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { apiClient } from "@/core/api/ApiClient";
import { AppPermission, EntityType, RepairSpecDto } from "@/core/api/generated";

import DamageTypeBlock from "../../ReferenceData/DamageType/DamageTypeBlock";
import VehiclePartTypeBlock from "../../ReferenceData/VehiclePartType/VehiclePartTypeBlock";
import TenantLink from "../../Tenant/TenantLink";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import RepairSpecItemDataList from "../RepairSpecItemDataList";
import RepairSpecLink from "../RepairSpecLink";
import RepairSpecMenu from "../RepairSpecMenu";

const defaultHeaderProps = {
  withGoBack: true,
  withLink: false,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<RepairSpecDto> {
  repairSpecId?: string | null;
  repairSpec?: RepairSpecDto;
  headerProps?: Partial<typeof defaultHeaderProps> & {
    title?: ReactNode;
  };
  displayProps?: Partial<typeof defaultDisplayProps>;
  getFunc?: (params: { repairSpecId: string }) => Promise<AxiosResponse<RepairSpecDto, unknown>>;
  deleteFunc?: (params: { repairSpecId: string }) => Promise<AxiosResponse<unknown, unknown>>;
  onDeleted?: () => void;
}

export type RepairSpecViewProps = OwnProps;

export default function RepairSpecView({
  repairSpecId,
  repairSpec,
  headerProps,
  displayProps = defaultDisplayProps,
  getFunc,
  deleteFunc,
  onDeleted,
}: RepairSpecViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const history = useAppHistory();
  const { hasPermissions } = useAuthorizationInfo();

  const [isLoading, setIsLoading] = useState(false);
  const [repairSpecFetched, setRepairSpecFetched] = useState<RepairSpecDto | undefined>(undefined);

  const _repairSpec = repairSpec || repairSpecFetched || undefined;

  useEffectWithThrottle(
    async () => {
      if (!repairSpec && repairSpecId) {
        setIsLoading(true);
        try {
          const response = getFunc
            ? await getFunc({ repairSpecId })
            : await apiClient.repairSpecsApi.apiV1RepairSpecsRepairSpecIdGet({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                repairSpecId: repairSpecId!,
              });
          setRepairSpecFetched(response.data);
        } finally {
          setIsLoading(false);
        }
      }
    },
    500,
    { leading: true, trailing: false },
    [repairSpec, repairSpecId, getFunc],
  );

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        _repairSpec && {
          idValue: _repairSpec.id!,
          newTitle: _repairSpec.localNumber || "",
        }) ||
      undefined,
  });

  if (isLoading) {
    return <LinearProgress />;
  }
  if (!_repairSpec) {
    return <EntityNotFoundAlert />;
  }

  return (
    <BaseEntityView
      entityType={EntityType.RepairSpec}
      entityId={repairSpecId}
      entity={_repairSpec}
      entityRequest={undefined}
    >
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title={
              <Box>
                {headerProps?.title || (
                  <Box>
                    <span>Repair spec</span>{" "}
                    <AppLink
                      enabled={
                        headerProps?.withLink &&
                        hasPermissions([
                          AppPermission.FleetAppAccess,
                          AppPermission.RepairCatalogRead,
                        ])
                      }
                      to={ROUTE_PATH.REPAIR_SPEC_VIEW(_repairSpec.id)}
                    >
                      <span>{_repairSpec.localNumber}</span>
                    </AppLink>
                  </Box>
                )}
              </Box>
            }
            primaryActions={
              _repairSpec && (
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <RepairSpecMenu
                    entity={_repairSpec}
                    onUpdate={(newValue) => newValue && setRepairSpecFetched(newValue)}
                    onDelete={() => (onDeleted ? onDeleted() : history.goBack())}
                    deleteFunc={deleteFunc}
                  />
                </AuthorizedElement>
              )
            }
          />
        }
      >
        <Stack direction='column' spacing={1}>
          <FieldValue label='Company' isEmpty={!_repairSpec.tenantId}>
            <TenantLink entityId={_repairSpec.tenantId} entity={undefined} />
          </FieldValue>

          {_repairSpec.sourceTenantId && (
            <FieldValue label='Source company' isEmpty={!_repairSpec.sourceTenantId}>
              <TenantLink entityId={_repairSpec.sourceTenantId} entity={undefined} />
            </FieldValue>
          )}

          {_repairSpec.sourceSpecId && (
            <FieldValue label='Source spec' isEmpty={!_repairSpec.sourceSpecId}>
              <RepairSpecLink entityId={_repairSpec.sourceSpecId} entity={undefined} />
            </FieldValue>
          )}

          <FieldValue label='Name'>{_repairSpec.name}</FieldValue>

          <FieldValue label='Description'>{_repairSpec.description}</FieldValue>

          <FieldValue label='Vehicle type'>
            <InlineApiEnumValue
              type='VehicleType'
              value={_repairSpec.vehicleType}
              withDescription
            />
          </FieldValue>

          <FieldValue label='Currency'>
            <GeneralCurrencyDisplay currency={_repairSpec.currency} />
          </FieldValue>

          <FieldValue label='Default spot factor'>
            <PercentValue value={_repairSpec.spotFactor} />
          </FieldValue>

          <Box>
            <Grid container spacing={1}>
              <Grid item xxs={12} md={6}>
                <VehiclePartTypeBlock
                  variant='reference'
                  vehiclePartType={_repairSpec.partType}
                  withLink
                  withDetailsToggle
                  isDetailsVisible={false}
                />
              </Grid>

              <Grid item xxs={12} md={6}>
                <DamageTypeBlock
                  variant='reference'
                  damageType={_repairSpec.damageType}
                  withLink
                  withDetailsToggle
                  isDetailsVisible={false}
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>

        {/* Detalization */}
        <Stack spacing={1}>
          <Typography component='div' variant='h6'>
            Detalization
          </Typography>

          <FieldValue label='Type'>
            <InlineApiEnumValue
              type='RepairSpecDetalizationType'
              value={_repairSpec.detalization?.type}
              withDescription
            />
          </FieldValue>
          {_repairSpec.detalization?.vehicleSize && (
            <FieldValue label='Vehicle size'>
              <InlineApiEnumValue
                type='VehicleSize'
                value={_repairSpec.detalization?.vehicleSize}
                withDescription
              />
            </FieldValue>
          )}
          {_repairSpec.detalization?.bodyType && (
            <FieldValue label='Body type'>
              <InlineApiEnumValue
                type='VehicleBodyType'
                value={_repairSpec.detalization?.bodyType}
                withDescription
              />
            </FieldValue>
          )}
          {_repairSpec.detalization?.make && (
            <FieldValue label='Make'>{_repairSpec.detalization?.make?.name}</FieldValue>
          )}
          {_repairSpec.detalization?.model && (
            <FieldValue label='Model'>{_repairSpec.detalization?.model?.name}</FieldValue>
          )}
          {_repairSpec.detalization?.generation && (
            <FieldValue label='Generation'>{_repairSpec.detalization?.generation?.name}</FieldValue>
          )}
          {_repairSpec.detalization?.modification && (
            <FieldValue label='Modification'>
              {_repairSpec.detalization?.modification?.name}
            </FieldValue>
          )}
        </Stack>

        {/* Items */}
        <Stack spacing={1}>
          <Typography component='div' variant='h6'>
            Items
          </Typography>

          <RepairSpecItemDataList repairSpec={_repairSpec} />
        </Stack>

        {/* Summary */}
        {_repairSpec.items?.length !== 0 && (
          <Box>
            <Stack direction='row' justifyContent='flex-end' sx={{ mt: 2 }}>
              <GeneralPriceSummaryDisplay
                sx={{ minWidth: { xxs: "100%", md: "300px" } }}
                summary={{
                  currency: _repairSpec.currency,
                  subTotal: _repairSpec.subTotal,
                  subTotalIncDiscount: _repairSpec.subTotalIncDiscount,
                  discount: _repairSpec.discount,
                  tax: _repairSpec.tax,
                  total: _repairSpec.total,
                }}
              />
            </Stack>
          </Box>
        )}
      </ViewLayout>
    </BaseEntityView>
  );
}
