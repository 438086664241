import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SxProps,
  Theme,
} from "@mui/material";
import _ from "lodash";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";

import AppIcon from "../Icons/AppIcon";

export interface SplitDropdownButtonOption {
  content: ReactNode;
  /** Show/hide. */
  if?: boolean;
  /** Active/selected. */
  active?: boolean;
  buttonProps?: Partial<LoadingButtonProps>;
  onClick?: () => void | Promise<void>;
}

export interface SplitDropdownButtonProps {
  options: SplitDropdownButtonOption[];
  /** Whether to trigger action when dropdown option is selected. By default false. */
  isSelectAndTriggerAction?: boolean;
  buttonProps?: Partial<LoadingButtonProps>;
  expandButtonProps?: Partial<LoadingButtonProps>;
  sx?: SxProps<Theme>;
  onClick?: () => void | Promise<void>;
}

/** Button with dropdown of alternative buttons. */
export default function SplitDropdownButton({
  options,
  isSelectAndTriggerAction = false,
  buttonProps,
  expandButtonProps,
  sx,
  onClick,
}: SplitDropdownButtonProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    Math.max(
      options.findIndex((x) => x.active),
      0,
    ),
  );
  const anchorRef = useRef<HTMLButtonElement>(null);

  const optionsComputed = useMemo(() => options.filter((x) => _.isNil(x.if) || x.if), [options]);
  const selectedOption = useMemo(() => options[selectedIndex], [options, selectedIndex]);
  const buttonPropsComputed = useMemo<Partial<LoadingButtonProps>>(() => {
    return {
      ...buttonProps,
      ...selectedOption.buttonProps,
    };
  }, [buttonProps, selectedOption]);
  const expandButtonPropsComputed = useMemo<Partial<LoadingButtonProps>>(() => {
    return {
      ..._.pick(buttonPropsComputed, ["variant", "color"]),
      ...expandButtonProps,
    };
  }, [expandButtonProps, buttonPropsComputed]);

  const isLoadingComputed = buttonPropsComputed?.loading || isLoading;
  const isShowExpandButton = optionsComputed.length > 1 && !isLoadingComputed;

  const triggerActionForOption = useCallback(
    async (option: SplitDropdownButtonOption) => {
      setIsLoading(true);
      try {
        onClick && (await onClick());
        option.onClick && (await option.onClick());
      } finally {
        setIsLoading(false);
      }
    },
    [onClick],
  );

  const handleClick = useCallback(() => {
    triggerActionForOption(selectedOption);
  }, [selectedOption, triggerActionForOption]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
    setIsOpen(false);

    if (isSelectAndTriggerAction) {
      const newSelectedOption = optionsComputed[index];
      triggerActionForOption(newSelectedOption);
    }
  };

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setIsOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant='contained'
        color={buttonPropsComputed.color}
        fullWidth={buttonPropsComputed.fullWidth}
        sx={sx}
      >
        {/* Main button */}
        <LoadingButton
          variant='contained'
          color='primary'
          {...buttonPropsComputed}
          loading={isLoadingComputed}
          onClick={(e) => {
            handleClick();
            expandButtonPropsComputed?.onClick && expandButtonPropsComputed?.onClick(e);
          }}
        >
          {selectedOption.content}
        </LoadingButton>

        {/* Expand button */}
        {isShowExpandButton && (
          <Button
            ref={anchorRef}
            variant='contained'
            color='primary'
            size='small'
            fullWidth={false}
            {...expandButtonPropsComputed}
            onClick={(e) => {
              setIsOpen((prevIsOpen) => !prevIsOpen);
              expandButtonPropsComputed?.onClick && expandButtonPropsComputed?.onClick(e);
            }}
          >
            {isOpen && <AppIcon of='expandLess' />}
            {!isOpen && <AppIcon of='expandMore' />}
          </Button>
        )}
      </ButtonGroup>

      {/* Popper with dropdown options */}
      <Popper
        sx={{
          zIndex: (th) => th.zIndex.tooltip,
        }}
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {optionsComputed.map((option, index) => (
                    <MenuItem
                      key={index}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.content}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
