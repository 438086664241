import { Box, Button, Stack, TypographyProps } from "@mui/material";
import { useMemo } from "react";

import PageTabsV2 from "@/App/Layouts/PageBody/PageTabsV2";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AuthorizedTenantElement from "@/common/components/Auth/AuthorizedTenantElement";
import EntityChipList from "@/common/components/EntityInfo/EntityChipList";
import AppIcon from "@/common/components/Icons/AppIcon";
import Image from "@/common/components/Images/Image";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { MakeLogoHelper } from "@/common/helpers/makeLogo";
import { StringHelper } from "@/common/helpers/string";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DamageCostEvaluationDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import DamageCostEvaluationMenu from "../DamageCostEvaluationMenu";
import CommentsTabContent from "./Tabs/CommentsTabContent";
import ContractTabContent from "./Tabs/ContractTabContent";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import RelatedOperationsTabContent from "./Tabs/RelatedOperationsTabContent";
import VehicleTabContent from "./Tabs/VehicleTabContent";

export enum DamageCostEvaluationViewPageTabs {
  Overview = "Overview",
  Vehicle = "Vehicle",
  Contract = "Contract",
  RelatedOperations = "RelatedOperations",
  GeneralHistory = "GeneralHistory",
  Comments = "Comments",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<DamageCostEvaluationDto> {
  damageCostEvaluationId: string | null | undefined;
  damageCostEvaluation: DamageCostEvaluationDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps;

export default function DamageCostEvaluationView({
  damageCostEvaluationId,
  damageCostEvaluation,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useAppHistory();
  const currentTenant = useCurrentTenant();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.DamageCostEvaluation),
      viewVariant: displayProps.viewVariant,
    },
  });

  const damageCostEvaluationRequest = useApiRequest(
    apiClient.damageCostEvaluationsApi.apiV1DamageCostEvaluationsDamageCostEvaluationIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      damageCostEvaluationId: damageCostEvaluationId!,
    },
    {
      debugKey: "DamageCostEvaluationView",
      deps: [damageCostEvaluationId],
      skip: !damageCostEvaluationId || !!damageCostEvaluation,
    },
  );
  damageCostEvaluation = damageCostEvaluation || damageCostEvaluationRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [
      DataUpdatesChannelName.Entity(
        currentTenant?.id,
        EntityType.DamageCostEvaluation,
        damageCostEvaluation?.id || "",
      ),
    ],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      damageCostEvaluationRequest.handleEntityChanged(data);
    },
  });

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        damageCostEvaluation && {
          idValue: damageCostEvaluation.id!,
          newTitle: damageCostEvaluation.localNumber || "",
        }) ||
      undefined,
  });

  const vehicleMakeUrl = useMemo(() => {
    return MakeLogoHelper.getMakeLogoByIdentifier(
      damageCostEvaluation?.vehicle?.spec?.make?.identifier,
    );
  }, [damageCostEvaluation]);

  return (
    <BaseEntityView
      entityType={EntityType.DamageCostEvaluation}
      entityId={damageCostEvaluationId}
      entity={damageCostEvaluation}
      entityRequest={damageCostEvaluationRequest}
    >
      <ViewLayoutV2
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={
                // vehicleMakeUrl because RepairOperationDto doesn't have vehicle attachments
                <>
                  {vehicleMakeUrl && (
                    <Image
                      src={vehicleMakeUrl}
                      sx={{
                        height: "45px",
                        width: "45px",
                        borderRadius: "6px",
                        objectFit: "contain",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: (th) => th.palette.divider,
                      }}
                    />
                  )}
                </>
              }
              title={
                <>
                  {damageCostEvaluation?.vehicle?.spec?.make?.name}
                  {damageCostEvaluation?.vehicle?.spec?.model?.name}{" "}
                  <EntityChipList entity={damageCostEvaluation?.vehicle} variant='normal' />
                </>
              }
              subtitle={
                <Stack spacing={0.5}>
                  <Box>
                    {StringHelper.joinIntoString(
                      [
                        damageCostEvaluation?.vehicle?.plateNo?.plateNo,
                        damageCostEvaluation?.vehicle?.identificationNumber,
                      ],
                      " • ",
                      { skipEmpty: true },
                    )}
                  </Box>

                  <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                    <GeneralAttachedTagsDisplay
                      tags={damageCostEvaluation?.tags}
                      defaultIsFolded={false}
                      edit={{
                        entityType: TagEntityType.DamageCostEvaluation,
                        entityId: damageCostEvaluation?.id,
                        onSaved: (newValue) => {
                          damageCostEvaluationRequest.updateData((x) => {
                            x.tags = newValue || undefined;
                          });
                        },
                      }}
                    />
                  </AuthorizedElement>
                </Stack>
              }
              titleProps={{
                to: headerProps?.withLink
                  ? ROUTE_PATH.ACCESSORY_VIEW(damageCostEvaluation?.id)
                  : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              primaryActions={
                displayProps.actions && (
                  <AuthorizedTenantElement tenantIds={[damageCostEvaluation?.tenantId]}>
                    <AuthorizedElement permissions={[AppPermission.DamageCostEvaluationManage]}>
                      <Button
                        component={AppLink}
                        to={ROUTE_PATH.DAMAGE_COST_EVALUATION_EDIT(damageCostEvaluation?.id)}
                        variant='outlined'
                        color='text'
                        size='medium'
                        startIcon={<AppIcon of='edit' />}
                      >
                        Edit
                      </Button>
                    </AuthorizedElement>
                  </AuthorizedTenantElement>
                )
              }
              secondaryActions={
                <DamageCostEvaluationMenu
                  entity={damageCostEvaluation}
                  onDelete={() => history.goBack()}
                  onUpdate={(newValue) =>
                    newValue
                      ? damageCostEvaluationRequest.replaceData(newValue)
                      : damageCostEvaluationRequest.refetch()
                  }
                />
              }
            />
          )
        }
      >
        <PageTabsV2
          tabIdsDefinition={DamageCostEvaluationViewPageTabs}
          defaultTabId={DamageCostEvaluationViewPageTabs.Overview}
          viewVariant={displayProps.viewVariant}
          commonViewParams={commonViewParams}
          tabs={[
            {
              label: "Overview",
              value: DamageCostEvaluationViewPageTabs.Overview,
              isHideable: false,
            },
            { label: "Vehicle", value: DamageCostEvaluationViewPageTabs.Vehicle },
            ...(damageCostEvaluation?.contract?.id
              ? [
                  {
                    label: "Contract",
                    value: DamageCostEvaluationViewPageTabs.Contract,
                  },
                ]
              : []),
            {
              label: "Related operations",
              value: DamageCostEvaluationViewPageTabs.RelatedOperations,
            },
            { label: "History", value: DamageCostEvaluationViewPageTabs.GeneralHistory },
            { label: "Comments", value: DamageCostEvaluationViewPageTabs.Comments },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            damageCostEvaluation && (
              <>
                {activeTab === DamageCostEvaluationViewPageTabs.Overview && (
                  <OverviewTabContent
                    damageCostEvaluation={damageCostEvaluation}
                    onUpdate={(newValue) =>
                      newValue
                        ? damageCostEvaluationRequest.replaceData(newValue)
                        : damageCostEvaluationRequest.refetch()
                    }
                  />
                )}
                {activeTab === DamageCostEvaluationViewPageTabs.Vehicle && (
                  <VehicleTabContent damageCostEvaluation={damageCostEvaluation} />
                )}
                {activeTab === DamageCostEvaluationViewPageTabs.Contract && (
                  <ContractTabContent damageCostEvaluation={damageCostEvaluation} />
                )}
                {activeTab === DamageCostEvaluationViewPageTabs.RelatedOperations && (
                  <RelatedOperationsTabContent damageCostEvaluation={damageCostEvaluation} />
                )}
                {activeTab === DamageCostEvaluationViewPageTabs.GeneralHistory && (
                  <GeneralHistoryTabContent damageCostEvaluation={damageCostEvaluation} />
                )}
                {activeTab === DamageCostEvaluationViewPageTabs.Comments && (
                  <CommentsTabContent damageCostEvaluation={damageCostEvaluation} />
                )}
              </>
            )
          }
        </PageTabsV2>
      </ViewLayoutV2>
    </BaseEntityView>
  );
}
