import { Box, Grid, Stack, Typography } from "@mui/material";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedTenantElement from "@/common/components/Auth/AuthorizedTenantElement";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import PercentValue from "@/common/components/Form/Display/PercentValue";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { EntityType, RepairSpecDataDto, RepairSpecDto } from "@/core/api/generated";

import GeneralCurrencyDisplay from "../../General/Display/GeneralCurrencyDisplay";
import DamageTypeBlock from "../../ReferenceData/DamageType/DamageTypeBlock";
import VehiclePartTypeBlock from "../../ReferenceData/VehiclePartType/VehiclePartTypeBlock";
import TenantLink from "../../Tenant/TenantLink";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import RepairSpecItemDataList from "../RepairSpecItemDataList";
import RepairSpecLink from "../RepairSpecLink";

const defaultDisplayProps = {
  breadcrumbs: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps extends BaseEntityViewInheritableProps<RepairSpecDto> {
  repairSpec?: RepairSpecDataDto;
  displayProps?: Partial<typeof defaultDisplayProps>;
}

export type RepairSpecDataViewProps = OwnProps;

export default function RepairSpecDataView({ repairSpec, displayProps }: RepairSpecDataViewProps) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  if (!repairSpec) {
    return null;
  }

  return (
    <BaseEntityView
      entityType={EntityType.RepairSpec}
      entityId={undefined}
      entity={undefined}
      entityRequest={undefined}
    >
      <ViewLayout
        displayProps={displayProps}
        header={<SimpleViewPageHeader title={<Box>Repair spec data</Box>} />}
      >
        <Stack direction='column' spacing={2}>
          <Stack direction='column' spacing={1}>
            <FieldValue label='Company' isEmpty={!repairSpec.tenantId}>
              <TenantLink entityId={repairSpec.tenantId} entity={undefined} />
            </FieldValue>

            {repairSpec.sourceTenantId && (
              <FieldValue label='Source company' isEmpty={!repairSpec.sourceTenantId}>
                <TenantLink entityId={repairSpec.sourceTenantId} entity={undefined} />
              </FieldValue>
            )}

            {repairSpec.sourceTenantId && repairSpec.sourceSpecId && (
              <AuthorizedTenantElement tenantIds={[repairSpec.sourceTenantId]}>
                <FieldValue label='Source spec' isEmpty={!repairSpec.sourceSpecId}>
                  <RepairSpecLink entityId={repairSpec.sourceSpecId} entity={undefined} />
                </FieldValue>
              </AuthorizedTenantElement>
            )}

            <FieldValue label='Name'>{repairSpec.name}</FieldValue>

            <FieldValue label='Description'>{repairSpec.description}</FieldValue>

            <FieldValue label='Vehicle type'>
              <InlineApiEnumValue
                type='VehicleType'
                value={repairSpec.vehicleType}
                withDescription
              />
            </FieldValue>

            <FieldValue label='Currency'>
              <GeneralCurrencyDisplay currency={repairSpec.currency} />
            </FieldValue>

            <FieldValue label='Default spot factor'>
              <PercentValue value={repairSpec.spotFactor} />
            </FieldValue>

            <Box>
              <Grid container spacing={1}>
                <Grid item xxs={12} md={6}>
                  <VehiclePartTypeBlock
                    variant='reference'
                    vehiclePartType={repairSpec.partType}
                    withLink
                    withDetailsToggle
                    isDetailsVisible={false}
                  />
                </Grid>

                <Grid item xxs={12} md={6}>
                  <DamageTypeBlock
                    variant='reference'
                    damageType={repairSpec.damageType}
                    withLink
                    withDetailsToggle
                    isDetailsVisible={false}
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>

          {/* Items */}
          <Stack spacing={1}>
            <Typography component='div' variant='h6'>
              Items
            </Typography>

            <RepairSpecItemDataList repairSpec={repairSpec} />
          </Stack>

          {/* Summary */}
          {repairSpec.items?.length !== 0 && (
            <Box>
              <Stack direction='row' justifyContent='flex-end' sx={{ mt: 2 }}>
                <GeneralPriceSummaryDisplay
                  sx={{ minWidth: { xxs: "100%", md: "300px" } }}
                  summary={{
                    currency: repairSpec.currency,
                    subTotal: repairSpec.subTotal,
                    subTotalIncDiscount: repairSpec.subTotalIncDiscount,
                    discount: repairSpec.discount,
                    tax: repairSpec.tax,
                    total: repairSpec.total,
                  }}
                />
              </Stack>
            </Box>
          )}
        </Stack>
      </ViewLayout>
    </BaseEntityView>
  );
}
