import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Box, Theme, Typography } from "@mui/material";
import { DragEventHandler, useRef, useState } from "react";

import { FileUploadAreaProps } from "./FileUploadArea";

interface Props extends FileUploadAreaProps {
  children: (params: {
    /** Inits upload by triggering file input. */
    triggerFileInput: () => void;
  }) => React.ReactNode;
}

/** File upload area that wraps its children and is activated via drag over. */
export default function FileUploadWrappingArea({
  multiple = false,
  accept = undefined,
  maxFiles,
  disabled,
  sx = {},
  onChange,

  children,
}: Props) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isDropZoneVisible, setIsDropZoneVisible] = useState(false);

  const handleChange = (files: File[]) => {
    onChange && onChange(files);
  };

  const handleClickArea = () => {
    fileInputRef.current?.click();
  };

  const handleFileInputChange = () => {
    const fileItems = fileInputRef.current?.files;
    handleChange(Array.from(fileItems || []));
  };

  const handleDragEnter: DragEventHandler<HTMLDivElement> = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDropZoneVisible(true);
  };

  const handleDragOver: DragEventHandler<HTMLDivElement> = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDropZoneVisible(true);
  };

  const handleDragLeave: DragEventHandler<HTMLDivElement> = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDropZoneVisible(false);
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    const evt = e;
    e.preventDefault();
    e.stopPropagation();
    setIsDropZoneVisible(false);

    handleChange(Array.from(evt.dataTransfer?.files || []));
  };

  return (
    <>
      <input
        type='file'
        hidden
        disabled={disabled}
        multiple={multiple}
        accept={accept}
        ref={fileInputRef}
        onChange={handleFileInputChange}
      />

      {/* Children wrapper */}
      <Box
        sx={{
          position: "relative",
        }}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
      >
        {children({
          triggerFileInput: handleClickArea,
        })}

        {/* Drop zone */}
        {isDropZoneVisible && (
          <Box
            sx={{
              position: "absolute",
              zIndex: 1,
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              borderWidth: "1px",
              borderStyle: "dashed",
              borderColor: (theme) => theme.palette.primary.main,
              borderRadius: 0,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              p: 3,
              cursor: "pointer",
              backgroundColor: (theme) => theme.palette.background.default,
              opacity: disabled ? 0.7 : 1,
              pointerEvents: disabled ? "none" : "initial",
              ...sx,
            }}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <CloudUploadIcon sx={{ fill: (theme: Theme) => theme.palette.secondary.light }} />
            <Typography component='div' sx={{ fontWeight: 600 }}>
              Drag and drop files here
            </Typography>
            {maxFiles !== undefined && (
              <Typography component='div' variant='caption' color='text.secondary'>
                You can upload up to {maxFiles} file(s)
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </>
  );
}
