import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";

import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import useMounted from "@/common/hooks/mount/useMounted";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { BaseFormikValues } from "@/common/ts/error";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  EntityType,
  PoolItemCreateDto,
  PoolItemDto,
  PoolItemStatus,
  PoolItemType,
  PoolItemUpdateDto,
} from "@/core/api/generated";

import ApiEnumAutocomplete from "../../Enum/ApiEnumAutocomplete";
import InlineApiEnumValue from "../../Enum/InlineApiEnumValue";
import GeneralValidationError from "../../Error/GeneralValidationError";
import FormActions from "../../Form/FormActions";
import GeneralEntitySubTypeInput from "../General/Input/GeneralEntitySubTypeInput";
import { PoolAutocompleteProps } from "../Pool/PoolAutocomplete";
import PoolAutocompleteOrCreate from "../Pool/PoolAutocompleteOrCreate";
import PoolLink from "../Pool/PoolLink";
import BaseEntityCreateUpdate, {
  BaseEntityCreateUpdateInheritableProps,
} from "../components/BaseEntityCreateUpdate";
import PoolItemCandidateEntityAutocompleteOrCreate from "./PoolItemCandidateEntityAutocompleteOrCreate";

type DefaultValues = {
  poolId: string | null | undefined;
  type?: PoolItemCreateDto["type"];
  entityType?: PoolItemCreateDto["entityType"];
  entity?: PoolItemCreateDto["entity"];
};

export interface PoolItemCreateUpdateOwnProps
  extends BaseEntityCreateUpdateInheritableProps<PoolItemDto, DefaultValues> {
  poolItemId?: string;
  defaultValues: DefaultValues;
  searchFilters?: {
    pool?: PoolAutocompleteProps["searchFilters"];
  };
}

export type PoolItemCreateUpdateProps = PoolItemCreateUpdateOwnProps;

export default function PoolItemCreateUpdate({
  poolItemId,
  defaultValues,
  searchFilters,
  onCreate,
  onUpdate,
  onSave,
}: PoolItemCreateUpdateProps) {
  const mounted = useMounted();
  const { enqueueSnackbar } = useAppSnackbar();
  const isCreate = !poolItemId;
  const isEdit = !!poolItemId;
  const poolId = defaultValues.poolId;

  const poolRequest = useApiRequest(
    apiClient.poolsApi.apiV1PoolsPoolIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolId: poolId!,
    },
    {
      skip: !poolId,
    },
  );
  const pool = poolRequest.data;

  const poolItemRequest = useApiRequest(
    apiClient.poolItemsApi.apiV1PoolsPoolIdItemsPoolItemIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      poolId: poolId!,
      poolItemId: poolItemId!,
    },
    {
      skip: !poolItemId,
    },
  );
  const poolItem = poolItemRequest?.data;

  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumbs: pool &&
      poolItem && [
        {
          idValue: pool.id!,
          newTitle: pool.localNumber || "",
        },
        {
          idValue: poolItem.id!,
          newTitle: poolItem.entity?.nameComputed || "",
        },
      ],
  });

  return (
    <BaseEntityCreateUpdate
      entityType={EntityType.PoolItem}
      entityId={poolItemId}
      entity={poolItem}
      entityRequest={poolItemRequest}
    >
      <Formik<
        BaseFormikValues &
          PoolItemCreateDto &
          PoolItemUpdateDto & { poolId: string | null | undefined }
      >
        enableReinitialize
        initialValues={{
          poolId: defaultValues?.poolId || undefined,
          type:
            poolItem?.type ||
            pool?.itemType ||
            defaultValues?.type ||
            (defaultValues?.entity?.entityId ? PoolItemType.Specific : undefined) ||
            undefined,
          entityType:
            poolItem?.entityType ||
            pool?.itemEntityType ||
            defaultValues?.entityType ||
            defaultValues?.entity?.entityType ||
            undefined,
          status: poolItem?.status || PoolItemStatus.Available,
          statusReason: poolItem?.statusReason || undefined,
          entity:
            pool || poolItem || defaultValues?.entity
              ? {
                  entityType:
                    poolItem?.entityType ||
                    pool?.itemEntityType ||
                    defaultValues?.entity?.entityType ||
                    undefined,
                  entityId:
                    poolItem?.entity?.entityId || defaultValues?.entity?.entityId || undefined,
                }
              : undefined,
          settings: poolItem?.settings || undefined,
          submit: "",
        }}
        validationSchema={Yup.object().shape({
          // vehicleId: Yup.string().required("Vehicle is required"),
        })}
        onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
          try {
            if (isCreate) {
              const response = await apiClient.poolItemsApi.apiV1PoolsPoolIdItemsPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                poolId: values.poolId || "",
                poolItemCreateDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Pool item created. Data will be updated in a moment.", {
                variant: "success",
              });
              onCreate && onCreate(response.data);
              onSave && onSave(response.data);
            } else {
              const response = await apiClient.poolItemsApi.apiV1PoolsPoolIdItemsPoolItemIdPut({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                poolId: values.poolId || "",
                poolItemId,
                poolItemUpdateDto: {
                  ...values,
                },
              });
              enqueueSnackbar("Pool item updated. Data will be updated in a moment.", {
                variant: "success",
              });
              onUpdate && onUpdate(response.data);
              onSave && onSave(response.data);
            }

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err: any) {
            if (mounted.current) {
              ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);
              setStatus({ success: false });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setErrors,
          setFieldValue,
          setValues,
        }) => {
          return (
            <form noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                {(poolRequest.isLoading || poolItemRequest.isLoading) && <LinearProgress />}

                <Box>
                  {errors.type && (
                    <FormHelperText error={Boolean(errors.type)}>
                      {ValidationHelper.getErrorsAsString(errors.type)}
                    </FormHelperText>
                  )}
                </Box>

                <Box>
                  <FormControl margin='dense' fullWidth>
                    <PoolAutocompleteOrCreate
                      autocompleteProps={{
                        entityId: values.poolId,
                        isPreload: isCreate,
                        disabled: isEdit || !!defaultValues?.poolId,
                        searchFilters: {
                          itemType: defaultValues?.entity?.entityId
                            ? PoolItemType.Specific
                            : undefined,
                          ...searchFilters?.pool,
                        },
                        onChange: (newValue) => setFieldValue("poolId", newValue?.id),
                      }}
                      createFormPlacement='modal'
                      onCreate={(newValue) => setFieldValue("poolId", newValue?.id)}
                    />
                  </FormControl>

                  {pool && values.poolId && (
                    <>
                      {/* PoolItemType.Specific - select item entity */}
                      {values.type === PoolItemType.Specific && (
                        <FormControl margin='dense' fullWidth>
                          <PoolItemCandidateEntityAutocompleteOrCreate
                            pool={pool}
                            value={values.entity}
                            disabled={isEdit || !!defaultValues?.entity?.entityId}
                            onlyEntityTypes={[
                              ...((pool?.itemEntityType && [pool.itemEntityType]) || []),
                              ...(pool?.itemEntityTypes || []),
                            ]}
                            entityTypeInputProps={{
                              disabled: isEdit || !_.isNil(pool?.itemEntityType),
                            }}
                            entityAutocompleteOrCreateProps={{
                              autocompleteProps: {
                                required: true,
                                disabled: isEdit,
                                textFieldProps: {
                                  error: Boolean(errors.entity),
                                  helperText: ValidationHelper.getFormikErrorsAsString(
                                    errors.entity,
                                  ),
                                },
                              },
                            }}
                            onChange={(newValue) => {
                              setFieldValue("entity", newValue);
                              setFieldValue("entityType", newValue?.entityType);
                            }}
                          />

                          {pool?.itemEntityType && (
                            <FormHelperText>
                              This pool supports only items of type{" "}
                              <InlineApiEnumValue
                                type='PoolItemEntityType'
                                value={pool.itemEntityType}
                              />
                              .
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}

                      {/* PoolItemType.Abstract - select item entity type */}
                      {values.type === PoolItemType.Abstract && (
                        <>
                          {/* PoolItemEntityType */}
                          <FormControl margin='dense' fullWidth>
                            <ApiEnumAutocomplete
                              type='PoolItemEntityType'
                              value={values.entityType}
                              onlyEnumValues={[
                                ...((pool?.itemEntityType && [pool.itemEntityType]) || []),
                                ...(pool?.itemEntityTypes || []),
                              ]}
                              onChange={(newValue) => setFieldValue("entityType", newValue)}
                              label='Entity type'
                              required
                              disabled={isEdit || !_.isNil(pool?.itemEntityType)}
                              textFieldProps={{
                                error: Boolean(errors.entityType),
                                helperText: ValidationHelper.getErrorsAsString(errors.entityType),
                              }}
                            />
                          </FormControl>

                          {/* EntitySubType */}
                          <FormControl margin='dense' fullWidth>
                            <GeneralEntitySubTypeInput
                              entityType={values.entityType || EntityType.None}
                              value={values.entitySubType}
                              onChange={(newValue) => setFieldValue(`entitySubType`, newValue)}
                              autocompleteProps={{
                                required: true,
                                disabled: isEdit || _.isNil(values.entityType),
                                textFieldProps: {
                                  error: Boolean(errors.entitySubType),
                                  helperText: ValidationHelper.getFormikErrorsAsString(
                                    errors.entitySubType,
                                  ),
                                },
                              }}
                            />
                          </FormControl>
                        </>
                      )}

                      <FormControl margin='dense' fullWidth required error={Boolean(errors.status)}>
                        <ApiEnumAutocomplete
                          type='PoolItemStatus'
                          value={values.status}
                          onChange={(newValue) => setFieldValue("status", newValue)}
                          label='Status'
                          required
                          textFieldProps={{
                            error: Boolean(errors.status),
                            helperText:
                              errors.status && ValidationHelper.getErrorsAsString(errors.status),
                          }}
                        />
                      </FormControl>

                      <TextField
                        error={Boolean(errors.statusReason)}
                        fullWidth
                        multiline
                        minRows={2}
                        helperText={errors.statusReason}
                        label='Status reason'
                        margin='dense'
                        name='statusReason'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='text'
                        value={values.statusReason || ""}
                        variant='outlined'
                      />

                      {/* Settings */}
                      <FormControl margin='dense' fullWidth>
                        <Stack spacing={1}>
                          <FormLabel required={false}>
                            <Typography component='span' variant='subtitle1'>
                              Settings
                            </Typography>
                          </FormLabel>

                          <FormControl margin='dense'>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    values.settings?.isEnsureEntityBelongToSinglePool || false
                                  }
                                  onBlur={handleBlur}
                                  onChange={(e) =>
                                    setFieldValue(
                                      "settings.isEnsureEntityBelongToSinglePool",
                                      e.target.checked,
                                    )
                                  }
                                />
                              }
                              label='Ensure entity belongs to a single pool'
                            />
                            <FormHelperText>
                              <Box>
                                When checked, ensures that single entity, for instance, Vehicle or
                                Asset, can be added max to one pool.
                              </Box>
                              <Box>
                                NB: This setting overrides the same setting set in the pool{" "}
                                <PoolLink entity={pool}></PoolLink>.
                              </Box>
                            </FormHelperText>
                          </FormControl>
                        </Stack>
                      </FormControl>
                    </>
                  )}
                </Box>

                <GeneralValidationError sx={{ my: 1 }} errors={errors} />

                <FormActions>
                  <LoadingButton
                    color='primary'
                    loading={isSubmitting}
                    fullWidth
                    type='submit'
                    variant='contained'
                  >
                    Save
                  </LoadingButton>
                </FormActions>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </BaseEntityCreateUpdate>
  );
}
