import { Box, Button, Stack, Typography } from "@mui/material";
import _ from "lodash";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import SubscriptionPlanOptionList from "@/App/MainAppView/Products/AssetSubscriptionPlans/components/SubscriptionPlanOptionList";
import NoDataAlert from "@/common/components/AppAlerts/NoDataAlert";
import NoFilesAlert from "@/common/components/AppAlerts/NoFilesAlert";
import AppIconButton from "@/common/components/Button/AppIconButton";
import AssetSpecDisplay from "@/common/components/Entity/Asset/AssetSpecDisplay";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralPriceDisplay from "@/common/components/Entity/General/GeneralPrice/GeneralPriceDisplay";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import DurationDisplay from "@/common/components/Form/Display/DurationDisplay";
import FieldValue from "@/common/components/Form/Display/FieldValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import { BooleanStatusDisplay } from "@/common/components/StatusDisplay/BooleanStatusDisplay";
import { FileItem } from "@/common/fileItem";
import { renderIf } from "@/common/helpers/render/renderIf";
import { enumService } from "@/common/services/enum";
import { AssetSubscriptionPlanDto, EntityType } from "@/core/api/generated";

import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import GeneralCountryDisplay from "../../../General/Display/GeneralCountryDisplay";
import AssetSubscriptionPlanToggleEnabledModal from "../../AssetSubscriptionPlanToggleEnabledModal";

interface Props {
  assetSubscriptionPlan: AssetSubscriptionPlanDto;
  onUpdate: (newValue?: AssetSubscriptionPlanDto) => void;
}

export default function OverviewTabContent({ assetSubscriptionPlan, onUpdate }: Props) {
  const [isToggleEnabledModalOpen, setIsToggleEnabledModalOpen] = useState(false);
  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);

  return (
    <PageTabContent>
      <ViewLayout
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={assetSubscriptionPlan} />}
          />
        }
      >
        <Stack direction='column' spacing={2}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <FieldValue label='Name' isEmpty={!assetSubscriptionPlan?.name}>
                {assetSubscriptionPlan?.name}
              </FieldValue>

              <FieldValue label='Description' isEmpty={!assetSubscriptionPlan?.description}>
                {assetSubscriptionPlan?.description}
              </FieldValue>

              <FieldValue label='Status'>
                <Stack direction='row' spacing={2} sx={{ alignItems: "center" }}>
                  <BooleanStatusDisplay
                    value={assetSubscriptionPlan?.isEnabled}
                    statusProps={{
                      variant: "default",
                      title: assetSubscriptionPlan?.isEnabled ? "Enabled" : "Disabled",
                      size: "medium",
                    }}
                  />

                  <Button
                    variant='contained'
                    color={assetSubscriptionPlan?.isEnabled ? "error" : "success"}
                    size='small'
                    onClick={() => setIsToggleEnabledModalOpen(true)}
                  >
                    {assetSubscriptionPlan?.isEnabled ? "Disable" : "Enable"}
                  </Button>
                </Stack>
              </FieldValue>

              <FieldValue label='Country' isEmpty={!assetSubscriptionPlan?.country}>
                <GeneralCountryDisplay
                  country={assetSubscriptionPlan?.country}
                  withDetailsPopover
                />
              </FieldValue>
            </Stack>
          </Stack>

          {/* Affiliation */}
          <Stack spacing={1} sx={{ mt: 2 }}>
            <Typography variant='subtitle1'>
              Affiliation{" "}
              <AppIconButton
                tooltipProps={{ title: "Edit entity affiliation" }}
                onClick={() => setIsUpdateAffiliationModalOpened(true)}
              >
                <AppIcon of='edit' fontSize='small' />
              </AppIconButton>
            </Typography>
            <AffiliationInfoDisplay
              tenantId={assetSubscriptionPlan.tenantId}
              departmentIds={assetSubscriptionPlan.departmentIds}
              locationIds={assetSubscriptionPlan.locationIds}
              sx={{ mt: 1 }}
            />
            <UpdateEntityAffiliationCascadeModal
              open={isUpdateAffiliationModalOpened}
              onClose={() => setIsUpdateAffiliationModalOpened(false)}
              entityType={EntityType.AssetSubscriptionPlan}
              entityId={assetSubscriptionPlan.id}
            />
          </Stack>

          {/* Asset spec */}
          <Stack spacing={2}>
            <Typography component='div' variant='h4'>
              Asset spec
            </Typography>

            {!assetSubscriptionPlan?.assetSpec && <NoDataAlert />}

            <AssetSpecDisplay spec={assetSubscriptionPlan?.assetSpec} />
          </Stack>

          {/* Attachments */}
          <Stack spacing={2}>
            <Typography component='div' variant='h4'>
              Attachments
            </Typography>

            <Stack spacing={2}>
              <Typography component='div' variant='subtitle2'>
                Image
              </Typography>

              {renderIf()
                .if(!!assetSubscriptionPlan?.image)
                .then(
                  <FileListView
                    files={FileItem.createManyFrom(
                      assetSubscriptionPlan?.image ? [assetSubscriptionPlan.image] : undefined,
                    )}
                    maxVisibleFiles={10}
                    itemWidth={250}
                    itemHeight={190}
                    sx={{ mt: 2, width: "100%" }}
                  />,
                )
                .else(<NoFilesAlert title='No image' description={undefined} />)
                .render()}
            </Stack>

            <Stack spacing={2}>
              <Typography component='div' variant='subtitle2'>
                Images
              </Typography>

              {renderIf()
                .if(!!assetSubscriptionPlan?.images && assetSubscriptionPlan.images.length !== 0)
                .then(
                  <FileListView
                    files={FileItem.createManyFrom(assetSubscriptionPlan?.images)}
                    maxVisibleFiles={10}
                    itemWidth={250}
                    itemHeight={190}
                    sx={{ mt: 2, width: "100%" }}
                  />,
                )
                .else(<NoFilesAlert title='No images' description={undefined} />)
                .render()}
            </Stack>

            <Stack spacing={2}>
              <Typography component='div' variant='subtitle2'>
                Attachments
              </Typography>

              {renderIf()
                .if(
                  !!assetSubscriptionPlan?.attachments &&
                    assetSubscriptionPlan.attachments.length !== 0,
                )
                .then(
                  <FileListView
                    files={FileItem.createManyFrom(assetSubscriptionPlan?.attachments)}
                    maxVisibleFiles={10}
                    itemWidth={250}
                    itemHeight={190}
                    sx={{ mt: 2, width: "100%" }}
                  />,
                )
                .else(<NoFilesAlert title='No attachments' description={undefined} />)
                .render()}
            </Stack>
          </Stack>

          {/* Settings */}
          <Stack spacing={2}>
            <Typography component='div' variant='h4'>
              Settings
            </Typography>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: `repeat(4, 1fr)`,
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <FieldValue
                label='Min duration'
                isEmpty={!assetSubscriptionPlan?.settings?.minDuration}
              >
                <DurationDisplay value={assetSubscriptionPlan?.settings?.minDuration} />
              </FieldValue>

              <FieldValue
                label='Max duration'
                isEmpty={!assetSubscriptionPlan?.settings?.maxDuration}
              >
                <DurationDisplay value={assetSubscriptionPlan?.settings?.maxDuration} />
              </FieldValue>

              <FieldValue
                label='Exact duration'
                isEmpty={!assetSubscriptionPlan?.settings?.exactDuration}
              >
                <DurationDisplay value={assetSubscriptionPlan?.settings?.exactDuration} />
              </FieldValue>

              <FieldValue
                label='Default duration'
                isEmpty={!assetSubscriptionPlan?.settings?.defaultDuration}
              >
                <DurationDisplay value={assetSubscriptionPlan?.settings?.defaultDuration} />
              </FieldValue>
            </Box>
          </Stack>

          {/* Prices */}
          <Stack spacing={1}>
            <Typography component='div' variant='h4'>
              Prices
            </Typography>

            {_.isEmpty(assetSubscriptionPlan?.prices) && <NoDataAlert />}

            <Stack spacing={4}>
              {assetSubscriptionPlan?.prices?.map((price, i) => (
                <Stack
                  key={i}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: `repeat(2, 1fr)`,
                    gap: 4,
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: `repeat(1, 1fr)`,
                      gap: 1,
                      alignContent: "start",
                      justifyContent: "start",
                    }}
                  >
                    <FieldValue label='Type' direction='row'>
                      {price.isBase ? "Base" : "Alternative"}
                    </FieldValue>

                    <FieldValue label='Billing period' direction='row' isEmpty={!price.period}>
                      <InlineApiEnumValue type='BillingPeriod' value={price.period} />
                    </FieldValue>

                    <FieldValue
                      label={`Price per ${
                        assetSubscriptionPlan.basePrice?.period
                          ? enumService.getEnumValueName(
                              "BillingPeriod",
                              assetSubscriptionPlan.basePrice?.period,
                            )
                          : "-"
                      }`}
                      direction='row'
                      isEmpty={_.isNil(price.price)}
                    >
                      <GeneralPriceDisplay
                        price={{
                          price: price.price,
                          currency: assetSubscriptionPlan?.currency,
                        }}
                      />
                    </FieldValue>
                  </Box>

                  <GeneralPriceSummaryDisplay
                    summary={{
                      currency: assetSubscriptionPlan?.currency,
                      subTotal: price.subTotal,
                      total: price.total,
                      discount: price.discount,
                      tax: price.tax,
                      insurance: price.insurance,
                    }}
                    displayProps={{
                      insurance: true,
                      calcExplanationPopover: true,
                      calcExplanationModal: true,
                    }}
                    direction='column'
                  />
                </Stack>
              ))}
            </Stack>
          </Stack>

          {/* Durations */}
          <Stack spacing={1}>
            <Typography component='div' variant='h4'>
              Durations
            </Typography>

            {_.isEmpty(assetSubscriptionPlan?.durations) && <NoDataAlert />}

            <Stack spacing={2}>
              {assetSubscriptionPlan?.durations?.map((duration, i) => (
                <Stack key={i} spacing={1}>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: `repeat(2, 1fr)`,
                      gridTemplateRows: "auto",
                      rowGap: 1,
                      columnGap: 1,
                    }}
                  >
                    <FieldValue
                      label='Min duration'
                      direction='column'
                      isEmpty={!duration.minDuration}
                    >
                      <DurationDisplay value={duration.minDuration} />
                    </FieldValue>

                    <FieldValue
                      label='Discount'
                      direction='column'
                      isEmpty={_.isNil(duration.discount)}
                    >
                      <GeneralDiscountDisplay
                        discount={duration.discount}
                        currency={assetSubscriptionPlan?.currency}
                      />
                    </FieldValue>
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Stack>

          {/* IncludedOptions */}
          <Stack spacing={1}>
            <Typography component='div' variant='h4'>
              Included options
            </Typography>

            {_.isEmpty(assetSubscriptionPlan?.includedOptions) && <NoDataAlert />}

            {!_.isEmpty(assetSubscriptionPlan?.includedOptions) && (
              <SubscriptionPlanOptionList
                optionType='included'
                options={assetSubscriptionPlan?.includedOptions}
                currency={assetSubscriptionPlan?.currency}
              />
            )}
          </Stack>

          {/* ExtraOptions */}
          <Stack spacing={1}>
            <Typography component='div' variant='h4'>
              Extra options
            </Typography>

            {_.isEmpty(assetSubscriptionPlan?.extraOptions) && <NoDataAlert />}

            {!_.isEmpty(assetSubscriptionPlan?.extraOptions) && (
              <SubscriptionPlanOptionList
                optionType='extra'
                options={assetSubscriptionPlan?.extraOptions}
                currency={assetSubscriptionPlan?.currency}
              />
            )}
          </Stack>
        </Stack>

        {/* Toggle enabled */}
        <AssetSubscriptionPlanToggleEnabledModal
          open={isToggleEnabledModalOpen}
          onClose={() => setIsToggleEnabledModalOpen(false)}
          props={{
            assetSubscriptionPlan: assetSubscriptionPlan,
            onSave: (newValue) => {
              onUpdate(newValue);
              setIsToggleEnabledModalOpen(false);
            },
          }}
        />
      </ViewLayout>
    </PageTabContent>
  );
}
