import { Alert, Box, Stack } from "@mui/material";
import _ from "lodash";
import { useState } from "react";

import PageTabContent from "@/App/Layouts/PageBody/PageTabContent";
import PageTabHeader from "@/App/Layouts/PageBody/PageTabHeader";
import ViewContentBlock from "@/App/Layouts/ViewContentBlock";
import ViewContentBlockHeader from "@/App/Layouts/ViewContentBlockHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AppTooltip from "@/common/components/AppTooltip";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIconButton from "@/common/components/Button/AppIconButton";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import Datetime from "@/common/components/Datetime/Datetime";
import GeneralByWhoDisplay from "@/common/components/Entity/General/Display/GeneralByWhoDisplay";
import GeneralDiscountDisplay from "@/common/components/Entity/General/GeneralDiscount/GeneralDiscountDisplay";
import GeneralTaxDisplay from "@/common/components/Entity/General/GeneralTax/GeneralTaxDisplay";
import VehicleBlock from "@/common/components/Entity/Vehicle/VehicleBlock";
import VehicleDamageLink from "@/common/components/Entity/VehicleDamage/VehicleDamageLink";
import EntityCreatedByInfoDisplay from "@/common/components/EntityInfo/EntityCreatedByInfoDisplay";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import FileListView from "@/common/components/Files/FileListView";
import CurrencyValue from "@/common/components/Form/Display/CurrencyValue";
import InfoTile from "@/common/components/Form/Display/InfoTile";
import GeneralStageHistory from "@/common/components/GeneralStageHistory";
import AppIcon from "@/common/components/Icons/AppIcon";
import BaseEditNotesModal from "@/common/components/Modals/common/BaseEditNotesModal";
import GeneralPriceSummaryDisplay from "@/common/components/PriceSummary/GeneralPriceSummaryDisplay";
import AppTypography from "@/common/components/Text/AppTypography";
import { FileItem } from "@/common/fileItem";
import { useAuthorizationInfo } from "@/common/hooks/auth/useAuthorizationInfo";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  EntityType,
  RepairOperationDto,
  RepairOperationStage,
} from "@/core/api/generated";

import AffiliationInfoDisplay from "../../../EntityAffiliation/AffiliationInfoDisplay";
import UpdateEntityAffiliationCascadeModal from "../../../EntityAffiliation/UpdateEntityAffiliationCascadeModal";
import SupplierLink from "../../../Supplier/SupplierLink";

interface Props {
  repairOperation: RepairOperationDto;
  handleUpdateStage: (newStage: RepairOperationStage) => Promise<void>;
  onUpdate: (newValue?: RepairOperationDto) => void;
}

export default function OverviewTabContent({
  repairOperation,
  handleUpdateStage,
  onUpdate,
}: Props) {
  const { hasPermissions } = useAuthorizationInfo();

  const [isUpdateAffiliationModalOpened, setIsUpdateAffiliationModalOpened] = useState(false);

  return (
    <PageTabContent>
      <ViewLayoutV2
        displayProps={{ viewVariant: ViewLayoutVariant.Tab }}
        header={
          <PageTabHeader
            title={"Details"}
            subtitle2={<EntityCreatedByInfoDisplay entity={repairOperation} />}
          />
        }
      >
        <ViewContentBlock>
          {repairOperation?.stageHistory?.stage === RepairOperationStage.Draft && (
            <AuthorizedElement permissions={[AppPermission.RepairOperationManage]}>
              <Alert severity='info' icon={<AppIcon of='tip' />}>
                This operation is in{" "}
                <strong>
                  <InlineApiEnumValue
                    type='RepairOperationStage'
                    value={RepairOperationStage.Draft}
                  />
                </strong>{" "}
                stage. Please review and complete it in order for changes to take effect.
              </Alert>
            </AuthorizedElement>
          )}

          <VehicleBlock
            sx={{ maxWidth: { md: "400px" } }}
            variant='reference'
            vehicle={repairOperation.vehicle}
            withDetailsToggle
            withLink
          />

          <Stack spacing={1}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Name' iconOf='data' isEmpty={_.isNil(repairOperation?.name)}>
                {repairOperation?.name}
              </InfoTile>

              <InfoTile
                label='Description'
                iconOf='data'
                isEmpty={_.isNil(repairOperation?.description)}
              >
                {repairOperation?.name}
              </InfoTile>

              <InfoTile
                label='Supplier'
                iconOf='supplier'
                isEmpty={_.isNil(repairOperation?.supplier)}
              >
                <SupplierLink entity={repairOperation?.supplier} />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Date' iconOf='date' isEmpty={_.isNil(repairOperation?.date)}>
                <Datetime datetime={repairOperation?.date} />
              </InfoTile>

              <InfoTile
                label='Responsible user'
                iconOf='inspector'
                isEmpty={_.isNil(repairOperation?.responsibleUser)}
              >
                <GeneralByWhoDisplay
                  who={repairOperation?.responsibleUser}
                  // inlineUserProps={{ withLink: true, withAvatar: true, withPoper: true }}
                />
              </InfoTile>

              <InfoTile label='Notes' iconOf='notes'>
                {repairOperation.notes ? repairOperation.notes : "-"}{" "}
                <BaseEditNotesModal
                  initialValues={{ notes: repairOperation.notes }}
                  onConfirm={async (notes) => {
                    await apiClient.repairOperationsApi.apiV1RepairOperationsRepairOperationIdNotesPut(
                      {
                        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                        repairOperationId: repairOperation.id || "",
                        generalNotesInputDto: { notes },
                      },
                    );
                    onUpdate();
                  }}
                />
              </InfoTile>
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: {
                  xxs: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(4, 1fr)",
                },
                gridTemplateRows: "auto",
                rowGap: 1,
                columnGap: 1,
              }}
            >
              <InfoTile label='Mileage' iconOf='gauge' isEmpty={_.isNil(repairOperation?.mileage)}>
                {repairOperation?.mileage ? (
                  <AppTypography>
                    {repairOperation.mileage.mileage}{" "}
                    <InlineApiEnumValue
                      type='MileageUnitType'
                      value={repairOperation.mileage.unit}
                    />
                  </AppTypography>
                ) : (
                  "-"
                )}
              </InfoTile>

              <InfoTile
                label='Fuel level'
                iconOf='fuel'
                isEmpty={_.isNil(repairOperation?.fuelLevel)}
              >
                {repairOperation?.fuelLevel}
              </InfoTile>
            </Box>
          </Stack>

          {repairOperation.attachments!.length !== 0 && (
            <Box>
              <FileListView
                files={FileItem.createManyFrom(repairOperation.attachments)}
                maxVisibleFiles={10}
                itemWidth={45}
              />
            </Box>
          )}
        </ViewContentBlock>

        {/* Affiliation */}
        <ViewContentBlock>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <ViewContentBlockHeader>
              Affiliation{" "}
              <AppIconButton
                tooltipProps={{ title: "Edit entity affiliation" }}
                onClick={() => setIsUpdateAffiliationModalOpened(true)}
              >
                <AppIcon of='edit' fontSize='small' />
              </AppIconButton>
            </ViewContentBlockHeader>
            <AffiliationInfoDisplay
              tenantId={repairOperation.tenantId}
              departmentIds={repairOperation.departmentIds}
              locationIds={repairOperation.locationIds}
              sx={{ mt: 1 }}
            />
            <UpdateEntityAffiliationCascadeModal
              open={isUpdateAffiliationModalOpened}
              onClose={() => setIsUpdateAffiliationModalOpened(false)}
              entityType={EntityType.RepairOperation}
              entityId={repairOperation.id}
            />
          </Stack>
        </ViewContentBlock>

        {/* Stages */}
        <ViewContentBlock spacing={2}>
          <ViewContentBlockHeader>Stages</ViewContentBlockHeader>
          <GeneralStageHistory
            stageEnumType='RepairOperationStage'
            stageHistory={repairOperation.stageHistory}
            withUpdateButtons={hasPermissions([AppPermission.RepairOperationManage])}
            onUpdateStage={async (newStage) => await handleUpdateStage(newStage)}
          />

          <Alert severity='info' icon={<AppIcon of='tip' />}>
            After the operation is{" "}
            <strong>
              <InlineApiEnumValue
                type='RepairOperationStage'
                value={RepairOperationStage.Completed}
              />
            </strong>
            , the damages will be marked as repaired in the vehicle history.
          </Alert>

          <DataTabular
            // headerProps={{
            //   variant: "bordered",
            // }}
            // contentProps={{
            //   itemVariant: "bordered",
            // }}
            columns={[
              {
                field: "damage",
                title: "Damage",
                flex: 1,
                renderCell: (item) => (
                  <VehicleDamageLink
                    vehicleId={item.damage?.vehicleId}
                    entity={item.damage}
                    withIcon
                    withTooltip
                  />
                ),
              },
              {
                field: "notes",
                title: "Notes",
                flex: 1,
                renderCell: (item) =>
                  item.notes ? (
                    <AppTooltip title={item.notes || ""}>
                      <AppTypography ellipsing={{ enabled: true }}>{item.notes}</AppTypography>
                    </AppTooltip>
                  ) : (
                    "-"
                  ),
              },
              {
                field: "attachments",
                title: "Attachments",
                flex: 1,
                renderCell: (item) => (
                  <>
                    {item.attachments!.length !== 0 && (
                      <FileListView
                        files={FileItem.createManyFrom(item.attachments)}
                        maxVisibleFiles={2}
                        itemWidth={45}
                      />
                    )}
                  </>
                ),
              },
              {
                field: "cost",
                title: "Cost",
                flex: 1,
                renderCell: (item) => (
                  <CurrencyValue value={item.cost} currency={repairOperation.currency} />
                ),
              },
              {
                field: "discount",
                title: "Discount",
                flex: 1,
                renderCell: (item) => (
                  <GeneralDiscountDisplay
                    discount={item.discount || undefined}
                    currency={repairOperation.currency}
                    detailsPlacement='tooltip'
                  />
                ),
              },
              {
                field: "tax",
                title: "Tax",
                flex: 1,
                renderCell: (item) => (
                  <GeneralTaxDisplay
                    tax={item.tax || undefined}
                    currency={repairOperation.currency}
                  />
                ),
              },
              {
                field: "total",
                title: "Total",
                flex: 1,
                renderCell: (item) => (
                  <CurrencyValue value={item.total} currency={repairOperation.currency} />
                ),
              },
            ]}
            rows={repairOperation.items!}
            getRowId={(item) => item.id!}
            borderVariant='bordered'
            isColumnSelectorEnabled={false}
            rowHeight={65}
            isColumnMenuEnabled={false}
            isPaginationEnabled={false}
            // rowHighlightPropsGetter={(item) =>
            //   (item.id === highlightProps?.itemId && {
            //     isHighlighted: true,
            //     durationMs: 5000,
            //     withAutoScroll: true,
            //   }) ||
            //   {}
            // }
          />

          {/* Summary */}
          <Stack direction='row' justifyContent='flex-end'>
            <GeneralPriceSummaryDisplay
              sx={{ minWidth: { xxs: "100%", md: "300px" } }}
              summary={{
                currency: repairOperation.currency,
                subTotal: repairOperation.subTotal,
                subTotalIncDiscount: repairOperation.subTotalIncDiscount,
                discount: repairOperation.discount,
                tax: repairOperation.tax,
                total: repairOperation.total,
              }}
            />
          </Stack>
        </ViewContentBlock>
      </ViewLayoutV2>
    </PageTabContent>
  );
}
