import { LoadingButton } from "@mui/lab";
import { Alert, Box, Stack, TextField, Typography } from "@mui/material";
import { Formik, getIn } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import GeneralValidationError from "@/common/components/Error/GeneralValidationError";
import PhoneInput from "@/common/components/Form/Input/PhoneInput";
import { UrlHelper } from "@/common/helpers/url";
import useMounted from "@/common/hooks/mount/useMounted";
import { useAppThunkDispatch } from "@/common/hooks/redux";
import { useQueryParams } from "@/common/hooks/useQueryParams";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { GeneralQueryParams } from "@/common/ts/GeneralQueryParams";
import { ValidationHelper } from "@/common/validation";
import * as profileSlice from "@/store/profile/slice";

// const StyledForm = styled("form")(({ theme }) => ({
//   backgroundColor: theme.palette.background.default,
//   boxShadow: (theme.shadows as string[])[1],
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   padding: theme.spacing(3),
//   borderRadius: theme.shape.borderRadius,
//   [theme.breakpoints.up("sm")]: {
//     width: "60%",
//     height: "fit-content",
//     borderRadius: theme.shape.borderRadius,
//   },
//   [theme.breakpoints.down("sm")]: {
//     width: "100%",
//     height: "100%",
//   },
// }));

export type CompleteInfoQueryParams = GeneralQueryParams;

/** User enters missing profile info. */
function CompleteInfo() {
  const { ...generalQueryParams } = useQueryParams<CompleteInfoQueryParams>();
  const history = useHistory();
  const mounted = useMounted();
  const thunkDispatch = useAppThunkDispatch();
  const profile = useUserProfile();

  return (
    <Formik
      enableReinitialize
      initialValues={{
        personName: {
          firstName: profile?.personName?.firstName || null,
          lastName: profile?.personName?.lastName || null,
        },
        phoneNumber: profile?.phoneNumber || null,
        submit: "",
      }}
      validationSchema={Yup.object().shape({
        // firstName: Yup.string().required("First name is required"),
        // lastName: Yup.string().required("Last name is required"),
      })}
      onSubmit={async (values, { setFieldError, setStatus, setSubmitting }) => {
        try {
          await thunkDispatch(
            profileSlice.updateProfile({
              nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
              profilePersonalInfoUpdateDto: {
                personName: values.personName
                  ? {
                      ...values.personName,
                      firstName: values.personName?.firstName || undefined,
                      lastName: values.personName?.lastName || undefined,
                    }
                  : undefined,
                phoneNumber: values.phoneNumber || undefined,
              },
            }),
          );

          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }

          if (generalQueryParams.redirectUrl) {
            UrlHelper.redirectToUrl(generalQueryParams.redirectUrl);
            return;
          }
          history.push(`/`);
        } catch (err: any) {
          ValidationHelper.handleApiErrorResponseFormik(err, setFieldError);

          if (mounted.current) {
            setStatus({ success: false });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setErrors,
        setFieldValue,
        setValues,
      }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <Typography
                component='div'
                variant='h4'
                sx={{ display: "flex", alignItems: "center" }}
              >
                Complete your personal info
              </Typography>

              <Alert severity='info'>
                We detected that personal info in your profile is incomplete. Please fill out
                missing info.
              </Alert>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  width: "100%",
                  minHeight: 0,
                }}
              >
                <TextField
                  required
                  error={Boolean(getIn(errors, "personName.firstName"))}
                  fullWidth
                  helperText={getIn(errors, "personName.firstName")}
                  label='First name'
                  margin='dense'
                  name='personName.firstName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.personName.firstName}
                  variant='outlined'
                />

                <TextField
                  error={Boolean(getIn(errors, "personName.lastName"))}
                  fullWidth
                  required
                  helperText={getIn(errors, "personName.lastName")}
                  label='Last name'
                  margin='dense'
                  name='personName.lastName'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type='text'
                  value={values.personName.lastName}
                  variant='outlined'
                />

                <PhoneInput
                  error={Boolean(errors.phoneNumber)}
                  helperText={errors.phoneNumber}
                  fullWidth
                  required
                  label='Phone number'
                  margin='dense'
                  name='phoneNumber'
                  value={values.phoneNumber || ""}
                  variant='outlined'
                  color='primary'
                  placeholder='Phone number'
                  onBlur={handleBlur}
                  onChange={(e, ph, iso) => {
                    setFieldValue(`phoneNumber`, ph);
                  }}
                />
              </Box>

              <GeneralValidationError sx={{ my: 1 }} errors={errors} />

              <LoadingButton
                sx={{ mt: { xs: "auto", md: 2 } }}
                color='primary'
                loading={isSubmitting}
                disabled={!values.personName.firstName || !values.personName.lastName}
                fullWidth
                type='submit'
                variant='contained'
              >
                Submit
              </LoadingButton>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
}

export default CompleteInfo;
