import { Button, TypographyProps } from "@mui/material";

import PageTabsV2 from "@/App/Layouts/PageBody/PageTabsV2";
import DetailedViewPageHeader from "@/App/Layouts/PageHeader/DetailedViewPageHeader";
import { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import ViewLayoutV2 from "@/App/Layouts/ViewLayoutV2";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppIcon from "@/common/components/Icons/AppIcon";
import AppLink from "@/common/components/Link/AppLink";
import { PersistenceKey } from "@/common/constants/persistenceKey";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useBreadcrumbReplacements } from "@/common/contexts/breadcrumbs";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonViewParams } from "@/common/hooks/layout/useCommonViewParams";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import { useAppHistory } from "@/common/hooks/useAppHistory";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  AssetDto,
  DataUpdatesHubClientMethodName,
  EntityType,
  TagEntityType,
} from "@/core/api/generated";

import GeneralAttachedTagsDisplay from "../../General/GeneralTag/GeneralAttachedTagsDisplay";
import BaseEntityView, { BaseEntityViewInheritableProps } from "../../components/BaseEntityView";
import AssetMenu from "../AssetMenu";
import GeneralHistoryTabContent from "./Tabs/GeneralHistoryTabContent";
import OverviewTabContent from "./Tabs/OverviewTabContent";
import PoolsTabContent from "./Tabs/PoolsTabContent";
import SpecTabContent from "./Tabs/SpecTabContent";

export enum AssetViewPageTabs {
  Overview = "Overview",
  Spec = "Spec",
  Pools = "Pools",
  GeneralHistory = "GeneralHistory",
}

const defaultHeaderProps = {
  withLink: false,
  typographyProps: undefined as Partial<TypographyProps> | undefined,
};

const defaultDisplayProps = {
  breadcrumbs: true,
  header: true,
  actions: true,
  viewVariant: ViewLayoutVariant.Page,
};

interface OwnProps {
  assetId: string | null | undefined;
  asset: AssetDto | null | undefined;
  displayProps?: Partial<typeof defaultDisplayProps>;
  headerProps?: Partial<typeof defaultHeaderProps>;
}

type Props = OwnProps & BaseEntityViewInheritableProps<AssetDto>;

export default function AssetView({
  assetId,
  asset,
  displayProps = defaultDisplayProps,
  headerProps = defaultHeaderProps,
}: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };
  headerProps = {
    ...defaultHeaderProps,
    ...headerProps,
  };

  const history = useAppHistory();
  const commonViewParams = useCommonViewParams({
    statePersistence: {
      persistenceKey: PersistenceKey.forEntityView(EntityType.Asset),
      viewVariant: displayProps.viewVariant,
    },
  });

  const assetRequest = useApiRequest(
    apiClient.assetsApi.apiV1AssetsAssetIdGet,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      assetId: assetId!,
    },
    {
      deps: [assetId],
      skip: !assetId || !!asset,
    },
  );
  asset = asset || assetRequest?.data;

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entity(undefined, EntityType.Asset, asset?.id || "")],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      assetRequest.handleEntityChanged(data);
    },
  });
  useBreadcrumbReplacements({
    waitTimeout: 10_000,
    idBreadcrumb:
      (displayProps?.breadcrumbs &&
        asset && {
          idValue: asset.id!,
          newTitle: asset.localNumber || "",
        }) ||
      undefined,
  });

  return (
    <BaseEntityView
      entityType={EntityType.Asset}
      entityId={assetId}
      entity={asset}
      entityRequest={assetRequest}
    >
      <ViewLayoutV2
        displayProps={displayProps}
        header={
          displayProps.header && (
            <DetailedViewPageHeader
              image={undefined}
              title={`Asset ${asset?.name || ""} (${asset?.localNumber})`}
              titleProps={{
                to: headerProps?.withLink ? ROUTE_PATH.ASSET_VIEW(asset?.id) : undefined,
                typographyProps: headerProps?.typographyProps,
              }}
              subtitle={
                <AuthorizedElement permissions={[AppPermission.FleetAppAccess]}>
                  <GeneralAttachedTagsDisplay
                    tags={asset?.tags}
                    defaultIsFolded={false}
                    edit={{
                      entityType: TagEntityType.Asset,
                      entityId: asset?.id,
                      onSaved: (newValue) => {
                        assetRequest.updateData((x) => {
                          x.tags = newValue || undefined;
                        });
                      },
                    }}
                  />
                </AuthorizedElement>
              }
              primaryActions={
                displayProps.actions && (
                  <AuthorizedElement permissions={[AppPermission.AssetManage]}>
                    <Button
                      component={AppLink}
                      to={ROUTE_PATH.ASSET_EDIT(asset?.id)}
                      variant='outlined'
                      color='text'
                      size='medium'
                      startIcon={<AppIcon of='edit' />}
                    >
                      Edit
                    </Button>
                  </AuthorizedElement>
                )
              }
              secondaryActions={
                displayProps.actions &&
                asset && (
                  <AssetMenu
                    onDelete={() => history.goBack()}
                    onUpdate={(newValue) =>
                      newValue ? assetRequest.replaceData(newValue) : assetRequest.refetch()
                    }
                    entity={asset}
                    displayProps={{ actions: { edit: false, delete: true, addToPool: true } }}
                  />
                )
              }
            />
          )
        }
      >
        <PageTabsV2
          tabIdsDefinition={AssetViewPageTabs}
          defaultTabId={AssetViewPageTabs.Overview}
          viewVariant={displayProps?.viewVariant}
          commonViewParams={commonViewParams}
          tabs={[
            { label: "Overview", value: AssetViewPageTabs.Overview, isHideable: false },
            { label: "Spec", value: AssetViewPageTabs.Spec },
            { label: "Pools", value: AssetViewPageTabs.Pools },
            { label: "History", value: AssetViewPageTabs.GeneralHistory },
          ]}
        >
          {({ activeTabId: activeTab }) =>
            asset && (
              <>
                {activeTab === AssetViewPageTabs.Overview && <OverviewTabContent asset={asset} />}
                {activeTab === AssetViewPageTabs.Spec && <SpecTabContent asset={asset} />}
                {activeTab === AssetViewPageTabs.Pools && <PoolsTabContent asset={asset} />}
                {activeTab === AssetViewPageTabs.GeneralHistory && (
                  <GeneralHistoryTabContent asset={asset} />
                )}
              </>
            )
          }
        </PageTabsV2>
      </ViewLayoutV2>
    </BaseEntityView>
  );
}
