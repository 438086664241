import {
  Box,
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { boolean } from "yup";

import SimpleViewPageHeader from "@/App/Layouts/PageHeader/SimpleViewPageHeader";
import ViewLayout, { ViewLayoutVariant } from "@/App/Layouts/ViewLayout";
import AuthorizedElement from "@/common/components/Auth/AuthorizedElement";
import AppAvatar from "@/common/components/Avatar/AppAvatar";
import DataTabular from "@/common/components/DataTabular/DataTabular";
import SuspendUserModal from "@/common/components/Entity/User/SuspendUserModal";
import UnsuspendUserModal from "@/common/components/Entity/User/UnsuspendUserModal";
import InlineApiEnumValue from "@/common/components/Enum/InlineApiEnumValue";
import AppIcon from "@/common/components/Icons/AppIcon";
import MenuWithTrigger from "@/common/components/Menu/MenuWithTrigger";
import ConfirmationModal from "@/common/components/Modals/ConfirmationModal";
import { ROUTE_PATH } from "@/common/constants/routing";
import { useApiRequest } from "@/common/hooks/api/useApiRequest";
import { useCommonRequestParams } from "@/common/hooks/api/useCommonRequestParams";
import { useCurrentTenant } from "@/common/hooks/entity/tenant/useCurrentTenant";
import { useRealtimeDataUpdates } from "@/common/hooks/realtime/useRealtimeDataUpdates";
import useAppSnackbar from "@/common/hooks/useAppSnackbar";
import { useUserProfile } from "@/common/hooks/useUserProfile";
import { DataUpdatesChannelName } from "@/common/realtime/dataUpdatesChannelName";
import { ValidationHelper } from "@/common/validation";
import { apiClient } from "@/core/api/ApiClient";
import {
  AppPermission,
  DataUpdatesHubClientMethodName,
  EntityType,
  UserDto,
  UserGetPaginatedDto,
  UserStatus,
} from "@/core/api/generated";

const defaultDisplayProps = {
  viewVariant: ViewLayoutVariant.Page,
  invite: boolean,
};
interface Props {
  displayProps?: Partial<typeof defaultDisplayProps>;
}

function Users({ displayProps }: Props) {
  displayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const profile = useUserProfile();
  const currentTenant = useCurrentTenant();
  const { enqueueSnackbar } = useAppSnackbar();
  const commonRequestParams = useCommonRequestParams<UserGetPaginatedDto>({
    statePersistence: {
      persistenceKey: EntityType.User,
    },
  });

  const [isSuspendModalOpen, setIsSuspendModalOpen] = useState(false);
  const [isUnsuspendModalOpen, setIsUnsuspendModalOpen] = useState(false);
  const [userToSuspendOrUnsuspend, setUserToSuspendOrUnsuspend] = useState<UserDto | undefined>(
    undefined,
  );
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<UserDto | undefined>(undefined);

  const paginatedUsersRequest = useApiRequest(
    apiClient.usersApi.apiV1UsersGetPost,
    {
      nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
      userGetPaginatedDto: {
        ...commonRequestParams.params,
        offset: commonRequestParams.offset,
        limit: commonRequestParams.limit,
        search: commonRequestParams.search,
        sortDefinition: commonRequestParams.sortDefinitionDto,
        filterDefinition: commonRequestParams.filterDefinitionDto,
      },
    },
    {
      deps: [...commonRequestParams.deps],
      debouncedDeps: {
        deps: [...commonRequestParams.debouncedDeps],
        wait: 500,
        options: { leading: false, trailing: true },
      },
      commonRequestParams: commonRequestParams,
    },
  );

  const paginatedUsers = paginatedUsersRequest.data;

  useRealtimeDataUpdates({
    channelNames: [DataUpdatesChannelName.Entities(currentTenant?.id, EntityType.User)],
    methodNames: [DataUpdatesHubClientMethodName.EntityChanged],
    handler: undefined,
    entityChangedHandler: (methodName, data) => {
      paginatedUsersRequest.handleEntityChanged(data);
    },
  });

  const handleDeleteConfirmed = async () => {
    if (!userToDelete) {
      return;
    }
    try {
      await apiClient.usersApi.apiV1UsersUserIdDelete({
        nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
        userId: userToDelete.id!,
      });
      enqueueSnackbar("User was successfully deleted.", { variant: "success" });
      setIsConfirmDeleteModalOpen(false);
      paginatedUsersRequest.refetch();
    } catch (err) {
      const validation2 = ValidationHelper.handleApiErrorResponse(err);
      validation2.hasErrors &&
        enqueueSnackbar(validation2.getErrorsAsString(), { variant: "error" });
    }
  };

  return (
    <Box>
      <ViewLayout
        displayProps={displayProps}
        header={
          <SimpleViewPageHeader
            title='Users'
            primaryActions={
              displayProps?.invite && (
                <AuthorizedElement permissions={[AppPermission.TenantUserManage]}>
                  <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AppIcon of='add' />}
                    component={RouterLink}
                    to={ROUTE_PATH.MANAGEMENT_INVITES}
                  >
                    Invite
                  </Button>
                </AuthorizedElement>
              )
            }
          />
        }
      >
        <Stack spacing={1}>
          <DataTabular
            columns={[
              {
                field: "personName.name",
                title: "Name",
                flex: 1,
                renderCell: (item) => (
                  <Stack direction='row' sx={{ alignItems: "center" }} spacing={1}>
                    <AppAvatar user={item} size='small' />
                    <Stack>
                      <Box>
                        {item.personName?.name}{" "}
                        {profile?.id === item.id && (
                          <Chip
                            size='small'
                            color='primary'
                            variant='filled'
                            label='You'
                            sx={{ ml: 1 }}
                          />
                        )}
                      </Box>
                      <Typography component='div' variant='body2'>
                        {item.email}
                      </Typography>
                    </Stack>
                  </Stack>
                ),
              },
              {
                field: "status",
                title: "Status",
                flex: 1,
                renderCell: (item) => <InlineApiEnumValue type='UserStatus' value={item.status} />,
              },
              {
                field: "phoneNumber",
                title: "Phone",
                flex: 1,
                renderCell: (item) => item.phoneNumber,
              },
              {
                field: "roles",
                title: "Roles",
                flex: 1,
                renderCell: (item) => item.roles?.map((x) => x.role!.name)?.join(", "),
              },
            ]}
            rows={paginatedUsers?.items}
            getRowId={(item) => item.id!}
            rowTo={(item) => ROUTE_PATH.TENANT_USER_VIEW(item.id)}
            renderRowAction={({ item }) => (
              <MenuWithTrigger
                trigger={
                  <IconButton>
                    <AppIcon of='moreVert' />
                  </IconButton>
                }
              >
                {({ handleClose }) => [
                  <AuthorizedElement key={0} permissions={[AppPermission.TenantUserRead]}>
                    <MenuItem component={RouterLink} to={ROUTE_PATH.TENANT_USER_VIEW(item.id)}>
                      <ListItemIcon>
                        <AppIcon of='view' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>View</ListItemText>
                    </MenuItem>
                  </AuthorizedElement>,
                  <AuthorizedElement key={1} permissions={[AppPermission.TenantUserManage]}>
                    <MenuItem component={RouterLink} to={ROUTE_PATH.TENANT_USER_EDIT(item.id)}>
                      <ListItemIcon>
                        <AppIcon of='edit' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Edit</ListItemText>
                    </MenuItem>
                  </AuthorizedElement>,
                  <AuthorizedElement key={2} permissions={[AppPermission.RoleAssignmentManage]}>
                    <MenuItem
                      component={RouterLink}
                      to={ROUTE_PATH.TENANT_USER_ROLES_EDIT(item.id)}
                    >
                      <ListItemIcon>
                        <AppIcon of='edit' fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>Edit roles</ListItemText>
                    </MenuItem>
                  </AuthorizedElement>,
                  <>
                    {item.id !== profile?.id && (
                      <AuthorizedElement key={3} permissions={[AppPermission.TenantUserManage]}>
                        {item.status !== UserStatus.Suspended && (
                          <MenuItem
                            onClick={() => {
                              setIsSuspendModalOpen(true);
                              setUserToSuspendOrUnsuspend(item);
                              handleClose();
                            }}
                          >
                            <ListItemIcon>
                              <AppIcon of='suspend' fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Suspend</ListItemText>
                          </MenuItem>
                        )}
                        {item.status === UserStatus.Suspended && (
                          <MenuItem
                            onClick={() => {
                              setIsUnsuspendModalOpen(true);
                              setUserToSuspendOrUnsuspend(item);
                              handleClose();
                            }}
                          >
                            <ListItemIcon>
                              <AppIcon of='unsuspend' fontSize='small' />
                            </ListItemIcon>
                            <ListItemText>Resume (unsuspend)</ListItemText>
                          </MenuItem>
                        )}
                      </AuthorizedElement>
                    )}
                  </>,
                  <>
                    {item.id !== profile?.id && (
                      <AuthorizedElement key={4} permissions={[AppPermission.TenantUserManage]}>
                        <MenuItem
                          onClick={() => {
                            setIsConfirmDeleteModalOpen(true);
                            setUserToDelete(item);
                          }}
                        >
                          <ListItemIcon>
                            <AppIcon of='removePerson' fontSize='small' />
                          </ListItemIcon>
                          <ListItemText>Remove</ListItemText>
                        </MenuItem>
                      </AuthorizedElement>
                    )}
                  </>,
                ]}
              </MenuWithTrigger>
            )}
            isLoading={paginatedUsersRequest.isLoading}
            statePersistence={commonRequestParams.dataTabularProps.statePersistence}
            pagination={commonRequestParams.dataTabularProps.pagination}
            sort={commonRequestParams.dataTabularProps.sort}
            quickFilter={commonRequestParams.dataTabularProps.quickFilter}
            filters={commonRequestParams.dataTabularProps.filters}
            refetch={commonRequestParams.dataTabularProps.refetch}
          />
        </Stack>

        {/* Suspend */}
        {userToSuspendOrUnsuspend && (
          <SuspendUserModal
            open={isSuspendModalOpen}
            onClose={() => {
              setIsSuspendModalOpen(false);
              setUserToSuspendOrUnsuspend(undefined);
            }}
            user={userToSuspendOrUnsuspend}
            suspendFunc={async (params) => {
              await apiClient.usersApi.apiV1UsersTargetUserIdSuspendPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                targetUserId: params.targetUserId,
                userSuspendDto: {
                  ...params,
                },
              });
              await paginatedUsersRequest.refetch();
            }}
          />
        )}

        {/* Unsuspend */}
        {userToSuspendOrUnsuspend && (
          <UnsuspendUserModal
            open={isUnsuspendModalOpen}
            onClose={() => {
              setIsUnsuspendModalOpen(false);
              setUserToSuspendOrUnsuspend(undefined);
            }}
            user={userToSuspendOrUnsuspend}
            unsuspendFunc={async (params) => {
              await apiClient.usersApi.apiV1UsersTargetUserIdUnsuspendPost({
                nexusOpsTenant: EMPTY_TENANT_IDENTIFIER,
                targetUserId: params.targetUserId,
                userUnsuspendDto: {
                  ...params,
                },
              });
              await paginatedUsersRequest.refetch();
            }}
          />
        )}

        {/* Delete confirmation */}
        {userToDelete && (
          <ConfirmationModal
            title='Remove the user?'
            body={
              <>
                {`You're going to remove the user`}{" "}
                <strong>{userToDelete.personName?.name || userToDelete.email}</strong> from{" "}
                <strong>{currentTenant?.name}</strong>. You can invite the user again later if{" "}
                needed.
              </>
            }
            open={isConfirmDeleteModalOpen}
            onClose={() => setIsConfirmDeleteModalOpen(false)}
            onCancel={() => setIsConfirmDeleteModalOpen(false)}
            onConfirm={handleDeleteConfirmed}
          />
        )}
      </ViewLayout>
    </Box>
  );
}

export default Users;
